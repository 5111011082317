<script>
  export let fontClass = "";
  export let value = 0;
  export let color = "orange";
  const allStar = new Array(10);
  $: activeStar = Math.floor(value);
</script>

{#each allStar as star, index}
  {#if index + 1 <= activeStar}
    <i class={`fa fa-star ${color}-text ${fontClass}`} />
  {:else}
    <i class={`fa fa-star-o ${color}-text ${fontClass}`} />
  {/if}
{/each}
