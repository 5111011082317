<script>
  import {
    loading as loadingMyCompany,
    data as dataMyCompany
  } from "../stores/company/myCompany.js";
  import {
    loading as loadingMyPerson,
    data as dataMyPerson,
    doneAllInitData
  } from "../stores/person/myPerson.js";

  import SetPersonName from "../pages/auth/SetPersonName.svelte";
  import SetCompanyId from "../pages/auth/SetCompanyId.svelte";
  import SetPersonNo from "../pages/auth/SetPersonNo.svelte";

  import MainLayout from "./MainLayout.svelte";
</script>

{#if !$doneAllInitData}
  <div class="root-container section no-pad-bot">
    <div class="container">
      {#if $loadingMyCompany}
        <div class="loader-block" />
      {:else if !$dataMyCompany || !$dataMyCompany.id}
        <SetCompanyId />
      {:else if $loadingMyPerson}
        <div class="loader-block" />
      {:else if !$dataMyPerson || !$dataMyPerson.id}
        <SetPersonNo />
      {/if}

    </div>
  </div>
{/if}

{#if $doneAllInitData && $dataMyCompany && $dataMyCompany.id && $dataMyPerson && $dataMyPerson.id}
  <MainLayout />
{/if}
