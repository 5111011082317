<script>
  import { Img } from "../../../components/common";

  export let data;
</script>

<style>
  .org-person {
    display: flex;
  }
  .org-person-desc {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>

<div class="font-12 black-text">
  <div>{data.orgName ? data.orgName : '[Tidak ada organisasi]'}</div>
  <div>{data.jobName ? data.jobName : '[Tidak ada jabatan]'}</div>
  <div class="org-person">
    <div>
      <Img
        className="img-circle"
        personId={data.ownerId}
        alt={data.ownerName ? data.ownerName : '[Tidak ada pegawai]'} />

    </div>
    <div class="org-person-desc">
      <span>{data.ownerName ? data.ownerName : '[Tidak ada pegawai]'}</span>
      <div>{data.ownerNo ? data.ownerNo : '[Tidak ada NIP]'}</div>
    </div>

  </div>
</div>
