import { tick } from "svelte";
import { writable, get } from "svelte/store";
import { navigate } from "svelte-routing";

import {
  restGetRatingPersonDetail,
  restPostRatingPersonUpdate
} from "../../api/ratingPerson";

import { successNotif, errorNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");
export let showModal = writable(false);

export let data = writable({});
export let selectedPerson = writable({});
export let questionCategories = writable([]);
export let questionListData = writable({});
export let questionPercentageData = writable({});

export async function getRatingPersonDetail({ ratingPersonId, next }) {
  error.set("");
  loading.set(true);
  data.set([]);
  try {
    const resp = await restGetRatingPersonDetail({
      ratingPersonId: ratingPersonId
    });

    transformAndSetData(resp, next);
  } catch (err) {
    console.warn(err);
    error.set(err);
    errorNotif(err);
    loading.set(false);
  }
}

function transformAndSetData(resp, next) {
  const newQuestionCategories = [];

  const newQuestionPercentageData =
    resp.data && resp.data.percentages ? resp.data.percentages : {};

  Object.keys(newQuestionPercentageData).forEach(c => {
    newQuestionCategories.push(c);
  });

  const newQuestionListData = resp.data && resp.data.data ? resp.data.data : {};

  let newSelectedPerson = {};
  if (resp.data.personId) {
    newSelectedPerson = {
      closed: resp.data.closed,
      personId: resp.data.personId,
      personName: resp.data.personName,
      personJobName: resp.data.personJobName,
      raterPersonName: resp.data.raterPersonName,
      month: resp.data.month,
      raterType: resp.data.raterType,
      totalPercentage: calculateTotalPercentageLocal(resp.data.percentages)
    };
  }

  questionCategories.set(newQuestionCategories);
  questionPercentageData.set(newQuestionPercentageData);
  questionListData.set(newQuestionListData);
  selectedPerson.set(newSelectedPerson);
  data.set(resp.data);

  loading.set(false);
  if (next) {
    next();
  }
}

function calculateTotalPercentageLocal(percentages) {
  let totalCategory = 0;
  let countCategory = 0;
  let totalPercentages = 0;
  Object.keys(percentages).forEach(categoryId => {
    totalCategory += percentages[categoryId];
    countCategory++;
  });
  if (countCategory > 0) {
    totalPercentages = Math.ceil(totalCategory / countCategory);
  }
  return totalPercentages;
}

export async function saveAnswer({
  isNext,
  ratingPersonId,
  categoryId,
  questionForm,
  selectedIndex,
  selectedCategory,
  nextFunc
}) {
  const formData = {
    data: {}
  };
  formData.data[categoryId] = {
    questions: questionForm
  };
  loading.set(true);
  try {
    await restPostRatingPersonUpdate({ ratingPersonId, formData });
    loading.set(false);

    if (nextFunc) {
      // getDetail();
      nextFunc();
    }
    successNotif(`Berhasil simpan rating ${selectedCategory.title}`);
    if (
      isNext === true &&
      selectedIndex + 1 !== get(questionCategories).length
    ) {
      navigate(
        `/person-rating/categories/${ratingPersonId}/questions-next/${
          get(questionCategories)[selectedIndex + 1]
        }`
      );
    } else {
      navigate(`/person-rating/categories/${ratingPersonId}`);
    }
  } catch (err) {
    loading.set(false);
    console.warn(err);
    errorNotif(err);
  }
}

export async function saveSummary({ ratingPersonId }) {
  if (get(loading) === true) {
    return;
  }
  loading.set(true);
  const formData = {
    closed: true,
    data: {}
  };
  try {
    await restPostRatingPersonUpdate({ ratingPersonId, formData });
    loading.set(false);
    toNext();
  } catch (err) {
    loading.set(false);
    console.warn(err);
    errorNotif(err);
  }
}

async function toNext() {
  const personName = get(selectedPerson).personName;
  successNotif(`Berhasil menilai ${personName}`);
  showModal.set(false);
  await tick();
  navigate(`/person-rating`);
}
