<script>
  import { onMount } from "svelte";

  import {
    loading,
    data,
    getActivityPersonDetail
  } from "../../stores/mySubActivity/activityDetail";

  import { HeaderNav } from "../../components/common";
  import { CurrentDateTop, DetailHead } from "./components";

  export let location;
  location;

  export let personId;
  export let activityId;

  function getData() {
    getActivityPersonDetail({
      activityId
    });
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }

  .top-date-fixed {
    z-index: 10;
    position: sticky;
    top: 56px;
    min-height: 40px;
    width: 100%;
  }
  .top-fixed {
    z-index: 10;
    position: sticky;
    top: 98px;
    min-height: 110px;
    width: 100%;
    background-color: #fff !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Verifikasi Aktivitas"
  backURL={`/my-sub-activity/person/${personId}`}
  onRefresh={() => {
    getData();
  }} />

<div class="top-date-fixed">
  <div class="p-h-14 p-t-14">
    {#if !$loading && $data && $data.id}
      <CurrentDateTop data={$data} />
    {/if}
  </div>
</div>

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="top-fixed">
      {#if !$loading && $data && $data.id}
        <DetailHead data={$data} />
      {/if}
    </div>
  </div>
</div>
