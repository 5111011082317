<script>
  import { Link } from "svelte-routing";

  export let date;
  export let filterName;

  import { dataStatus } from "../../../stores/myActivity/myActivityList.js";
</script>

<style>
  .status-box {
    display: flex;
    justify-content: space-between;
  }
</style>

<div class="p-h-14 p-v-14">
  <div class="status-box m-t-14">
    <div class="center-align">
      <Link to={`/my-activity/by-date/${date}/filter/all`}>
        <div class={`${filterName === 'all' ? 'teal-text' : 'grey-text'}`}>
          <div class="font-16">
            {$dataStatus['total']}
            <i class="fa fa-list" />
          </div>
          <div class="font-10">Semua</div>
        </div>
      </Link>
    </div>
    <div class="center-align">
      <Link to={`/my-activity/by-date/${date}/filter/need-verify`}>
        <div
          class={`${filterName === 'need-verify' ? 'teal-text' : 'grey-text'}`}>
          <div class="font-16">
            {$dataStatus['20']}
            <i class="fa fa-question-circle" />
          </div>
          <div class="font-10">
            Belum
            <br />
            Acc
          </div>
        </div>
      </Link>
    </div>
    <div class="center-align">
      <Link to={`/my-activity/by-date/${date}/filter/accept`}>
        <div class={`${filterName === 'accept' ? 'teal-text' : 'grey-text'}`}>
          <div class="font-16">
            {$dataStatus['50']}
            <i class="fa fa-check-circle" />
          </div>
          <div class="font-10">Diterima</div>
        </div>
      </Link>
    </div>
    <div class="center-align">
      <Link to={`/my-activity/by-date/${date}/filter/reject`}>
        <div class={`${filterName === 'reject' ? 'teal-text' : 'grey-text'}`}>
          <div class="font-16">
            {$dataStatus['40']}
            <i class="fa fa-times-circle" />
          </div>
          <div class="font-10">Ditolak</div>
        </div>
      </Link>
    </div>
  </div>
</div>
