<script>
  import { Router, Route } from "svelte-routing";

  import Page404 from "../common/Page404.svelte";
  import PersonRatingTab1 from "./PersonRatingTab1.svelte";
  import RouterByCategory from "./RouterByCategory.svelte";
  import PersonRatingTab2 from "./PersonRatingTab2.svelte";
  import RouterHistoryByCategory from "./RouterHistoryByCategory.svelte";
</script>

<Router>
  <Route path="/" component={PersonRatingTab1} />
  <Route
    path="/categories/:ratingPersonId/*wildcardName"
    component={RouterByCategory} />
  <!-- HISTORY -->
  <Route path="/history" component={PersonRatingTab2} />
  <Route
    path="/history/categories/:ratingPersonId/*wildcardName"
    component={RouterHistoryByCategory} />
  <Route component={Page404} />
</Router>
