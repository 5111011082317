<script>
  import { user, logout } from "../../stores/auth/myUser.js";

  import {
    loading,
    inputCompanyId,
    error,
    changeMyCompany
  } from "../../stores/company/setMyCompanyForm.js";
</script>

<style>
  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div class="center-container">
  <h3 class="center-align">
    <span class="font-30">Kode Undangan</span>
  </h3>
  <div class="login-box">
    <div class="row">
      <form on:submit|preventDefault={changeMyCompany} class="col s12">
        <div>Dapatkan kode undangan dari admin kepegawaian di kantor anda</div>
        <div class="row">
          <div class="input-field col s12">
            <input
              value={$user.mobile ? $user.mobile.replace('+628', '08') : ''}
              autocomplete="off"
              id="mobile"
              type="tel"
              class="validate"
              disabled={true} />
            <label class="active" for="mobile">No HP</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input
              bind:value={$inputCompanyId}
              autocomplete="off"
              id="inputCompanyId"
              type="text"
              class="validate" />
            <label class="active" for="inputCompanyId">Kode Undangan</label>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="red-text m-b-4">{$error}</div>
            <button type="submit" class="btn red darken-4 btn-block">
              Kirim Kode Undangan
            </button>
          </div>

        </div>

        <div class="row">
          <div class="col s12">
            <span
              on:click={logout}
              class="right red-text text-darken-4 cursor-pointer">
              Kembali ke Login
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
