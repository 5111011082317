<script>
  import { onMount } from "svelte";
  import {
    loading,
    filteredListData,
    inputSearchKeyword,
    searchPerson,
    getTaskAssignmentListPersonBySupervisor
  } from "../../stores/taskAssignmentSub/subordinateList";

  import { HeaderNav } from "../../components/common";
  import { SubordinateItem } from "./components";

  function getData() {
    getTaskAssignmentListPersonBySupervisor();
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .action-box {
    display: flex;
    align-content: flex-start;
  }
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Penugasan Ke Bawahan"
  backURL={`/home`}
  onRefresh={() => {
    getData();
  }} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="card-panel p-h-14 p-v-14 m-t-0">
      <form
        class="action-box"
        on:submit|preventDefault={() => {
          searchPerson();
        }}>
        <div class="input-field full-width">
          <input
            bind:value={$inputSearchKeyword}
            id="activity"
            type="text"
            autocomplete="true"
            class="validate cursor-pointer" />
          <label class="active" for="activity">Cari Pegawai</label>
        </div>
        <div class="m-l-14">
          <button type="submit" class="btn full-width m-t-20">
            <i class="fa fa-search" />
          </button>
        </div>
      </form>
    </div>
    <ul class="collection p-0 m-t-0">
      {#if !$loading && $filteredListData && $filteredListData.length > 0}
        {#each $filteredListData as data}
          {#if data.supervisorPersonId !== window.myPersonId}
            <SubordinateItem {data} />
          {/if}
        {/each}
      {:else if !$loading && $filteredListData && $filteredListData.length < 1}
        <li class="collection-item p-h-14 p-v-14">
          <div class="font-16 center-align">Tidak ada penugasan</div>
        </li>
      {/if}
    </ul>
  </div>
</div>
