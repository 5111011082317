<script>
  import { Img } from "../../../components/common";

  export let data = {};
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
</style>

<li class="collection-item p-v-4 p-h-14">
  <div class="person-box">
    <div>
      <Img
        personId={data.ownerId}
        alt={data.ownerName ? data.ownerName : '[Tidak ada pegawai]'}
        className="img-width circle" />
    </div>
    <div class="m-l-14">
      <div class="cursor-pointer">
        <a
          href={`tel://${data.ownerMobile ? data.ownerMobile : ''}`}
          alt="telepon"
          class="red-text text-darken-4">
          <i class="fa fa-mobile" />
          {data.ownerMobile ? data.ownerMobile.replace('+628', '08') : ''}
        </a>
      </div>
      <div class="font-12">
        <div>
          <i class="fa fa-user" />
          {data.ownerName ? data.ownerName : '[Tidak ada pegawai]'}
        </div>
        <div>
          <i class="fa fa-address-card-o" />
          {data.ownerNo ? data.ownerNo : '[Tidak ada no Induk]'}
        </div>
      </div>
    </div>
  </div>
</li>
