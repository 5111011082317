<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  import { user } from "../../stores/auth/myUser";
  import {
    loading,
    error,
    inputPassword,
    inputRePassword,
    changePassword
  } from "../../stores/auth/changePasswordForm";

  import { HeaderNav, Footer } from "../../components/common";

  onMount(() => {
    inputPassword.set("");
    inputRePassword.set("");
  });
</script>

<style>
  .root-container {
    padding-top: 0px;
  }
  .container {
    margin: 0px;
    width: 100% !important;
  }
  .card-panel {
    margin-top: 0px;
  }
  .login-box {
    min-width: 300px;
  }
  .btn-block {
    width: 100%;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}
<HeaderNav headerSize="s" headerName="Ganti Password" backURL={'/user'} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="card-panel p-t-10">
      <div class="login-box">
        <form on:submit|preventDefault={changePassword}>
          <div class="row">
            <div class="input-field col s12">
              <input
                value={$user && $user.mobile ? $user.mobile.replace('+628', '08') : ''}
                autocomplete="off"
                id="mobile"
                type="tel"
                class="validate"
                disabled={true} />
              <label class="active" for="mobile">No HP</label>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <input
                bind:value={$inputPassword}
                autocomplete="off"
                id="password"
                type="password"
                class="validate" />
              <label class="active" for="password">Password Baru</label>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <input
                bind:value={$inputRePassword}
                autocomplete="off"
                id="rePassword"
                type="password"
                class="validate" />
              <label class="active" for="rePassword">
                Ulangi Password Baru
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col s12">
              <div class="red-text m-b-4">{$error}</div>
              <button type="submit" class="btn btn-block">
                update password
              </button>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col s12">
              <Link to="/" getProps={() => ({ class: 'right' })}>Kembali</Link>
            </div>
          </div> -->
        </form>

      </div>
    </div>
  </div>

</div>
