<script>
  import { Link } from "svelte-routing";
  import { logout, user as myUserData } from "../../stores/auth/myUser.js";
  import { data as myPersonData } from "../../stores/person/myPerson";

  import { formatMobile } from "../../helpers/number";
  import { HeaderNav, Footer, Img } from "../../components/common";
</script>

<style>
  .root-container {
    padding-top: 0px;
  }
  .container {
    margin: 0px;
    width: 100% !important;
  }
  .card-panel {
    margin-top: 0px;
  }
  .collection .collection-item.avatar {
    min-height: 0px;
    padding-left: 20px !important;
  }
  .picture-item {
    min-height: 86px !important;
  }
</style>

<HeaderNav headerName="Profil" backURL={`/home`} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="card-panel p-0">
      <ul class="collection m-t-0 p-0">
        <li class="collection-item avatar picture-item">
          <!-- <Link to={`/user/change-photo`}>
            <button class="btn btn-small right m-t-12">Edit</button>
          </Link> -->
          <div class="font-12">Foto</div>
          <div>
            <Img
              className="responsive-img circle"
              personId={$myPersonData.id}
              alt={$myPersonData.name} />
          </div>

        </li>
        <li class="collection-item avatar">
          <!-- <Link to={`/user/change-name`}>
            <button class="btn btn-small right">Edit</button>
          </Link> -->
          <div class="font-12">Nama</div>
          <div>{$myPersonData.name}</div>

        </li>
        <li class="collection-item avatar">
          <!-- <Link to={`/user/change-password`}>
            <button class="btn btn-small right">Edit</button>
          </Link> -->
          <div class="font-12">No HP</div>
          <div>{formatMobile($myPersonData.mobile)}</div>
        </li>
        <li class="collection-item avatar">
          <Link to={`/user/change-password`}>
            <button class="btn btn-small full-width m-b-10">
              Ganti Password
              <i class="fa fa-lock" />
            </button>
          </Link>
          <button on:click={logout} class="btn btn-small full-width grey">
            Logout
            <i class="fa fa-sign-out" />
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
