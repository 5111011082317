<script>
  import { onMount, tick } from "svelte";

  import {
    error,
    loading,
    inputComment,
    inputProgress,
    isProgressInput,
    postTaskAssignmentComment
  } from "../../../stores/myTaskAssignment/commentForm";
  import { CircleInput } from "../../../components/common";

  export let taskId;
  export let detailData;

  onMount(() => {
    isProgressInput.set(true);
    inputComment.set("");
    inputProgress.set(0);
    if (detailData.progress > -1) {
      inputProgress.set(detailData.progress);
    }
    const commentActionButton = document.querySelector(
      "#comment-action-button"
    );
    if (commentActionButton) {
      commentActionButton.focus();
    }
  });
</script>

<style>
  .card-panel {
    min-height: 103px;
  }
  .action-box {
    display: flex;
    align-content: flex-start;
  }
  .red-text {
    margin-top: -20px;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div class="card-panel p-0 p-h-14">
  <form
    class="action-box"
    on:submit|preventDefault={() => {
      if (detailData.closed) {
        return;
      }
      postTaskAssignmentComment({ taskAssignmentId: taskId });
    }}>
    <div class="input-field full-width">
      {#if $isProgressInput}
        <CircleInput
          fontClass="font-30 m-r-18 m-t-18"
          value={$inputProgress}
          onClickCircle={v => {
            inputProgress.set(v);
            isProgressInput.set(false);
            tick().then(() => {
              const inputComment = document.querySelector('#comment');
              if (inputComment) {
                inputComment.focus();
              }
            });
          }} />
        {$inputProgress ? $inputProgress : 0}%
        <label class={`active font-22`}>
          <span class={`teal-text`} for="progress">
            Progress {$inputProgress ? $inputProgress : 0}%
          </span>
          <span
            on:click={() => {
              isProgressInput.set(false);
              tick().then(() => {
                const inputComment = document.querySelector('#comment');
                if (inputComment) {
                  inputComment.focus();
                }
              });
            }}
            class={`cursor-pointer`}>
            | Komentar
          </span>
        </label>
      {:else}
        <input
          value={$inputComment}
          on:change={e => {
            inputComment.set(e.target.value);
          }}
          id="comment"
          min="0"
          max="100"
          class="materialize-textarea" />
        <label class={`active teal-text font-22`} for="comment">
          <span
            on:click={() => ($isProgressInput = true)}
            class={`grey-text cursor-pointer`}
            for="progress">
            Progress {$inputProgress ? $inputProgress : 0}% |
          </span>
          Komentar
        </label>
      {/if}
    </div>
    <div class="m-l-14">
      <button
        type="submit"
        id="comment-action-button"
        class="btn teal white-text full-width m-t-30">
        <i class="fa fa-commenting" />
      </button>
    </div>
  </form>
  <div class="red-text">{$error}</div>
</div>
