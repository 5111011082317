<script>
  import { Router, Route } from "svelte-routing";

  import { Footer } from "../components/common";

  import Login from "../pages/auth/Login.svelte";
  import RouteOtp from "../pages/otp/RouteOtp.svelte";

  import Page404 from "../pages/common/Page404.svelte";
</script>

<Router>
  <div class="root-container section no-pad-bot">
    <div class="container">
      <Route path={`/`} component={Login} />
      <Route path={`/login`} component={Login} />
      <Route path={`/otp`} component={RouteOtp} />
      <Route component={Page404} />
    </div>
  </div>
  <Footer />
</Router>
