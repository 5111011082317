<script>
  import { onMount } from "svelte";

  import {
    loading,
    data,
    error,
    getMyActivityDetail
  } from "../../stores/myActivity/myActivityDetail.js";

  import { HeaderNav, ErrorBox } from "../../components/common";
  import { DateBarDetail, DetailHead } from "./components";

  export let location;
  location;

  export let date;
  export let filterName;
  export let activityId;

  function getData() {
    getMyActivityDetail({
      activityId
    });
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
  .top-date-fixed {
    z-index: 10;
    position: sticky;
    top: 56px;
    min-height: 40px;
    width: 100%;
  }
  .top-fixed {
    z-index: 10;
    position: sticky;
    top: 98px;
    min-height: 110px;
    width: 100%;
    background-color: #fff !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="My Detail Aktivitas"
  backURL={`/my-activity/by-date/${date}/filter/${filterName}`}
  onRefresh={() => {
    getData();
  }} />
{#if $error !== '' && !$loading}
  <ErrorBox
    desc={$error}
    onRefresh={() => {
      getMyActivityDetail(activityId);
    }} />
{/if}
<div class="top-date-fixed">
  <DateBarDetail startDate={date} />
</div>
<div class="root-container section no-pad-bot">
  <div class="container">
    {#if $data && $data.id}
      <div class="top-fixed">
        <DetailHead data={$data} {date} {filterName} />
      </div>
    {/if}
  </div>
</div>
