<script>
  import { onMount, tick } from "svelte";
  import { fly } from "svelte/transition";

  export let onClose;
  export let onOk;
  export let isBackdrop = false;
  export let value;
  let hour;
  let minute;

  onMount(() => {
    liHeight = document.querySelector(".liSelect").offsetHeight;
    if (!window.moment(value, "HH:mm").isValid()) {
      clickClose();
    }
    tick().then(() => {
      initTimePicker();
    });
  });
  function initTimePicker() {
    hour = window.moment(value, "HH:mm").format("HH");
    minute = window.moment(value, "HH:mm").format("mm");
    if (hour.length < 2) {
      hour = `0${hour}`;
    }
    if (minute.length < 2) {
      minute = `0${vaminutelue}`;
    }
    singleClick("hourSlide", Number(hour), 0);
    singleClick("minuteSlide", Number(minute), 1);
  }
  function clickClose() {
    onClose();
  }
  function clickOk() {
    var tempValue = "";
    hour = getValue(0);
    minute = getValue(1);
    onOk(`${hour.trim()}:${minute.trim()}`);
  }

  const hours = new Array(24);
  const minutes = new Array(60);

  let wheelsData = ["hourSlide", "minuteSlide"];
  let startY = 0;
  let moveEndY;
  let moveY;
  let oldMoveY;
  let offset = 0;
  let offsetSum = 0;
  let oversizeBorder;
  let curDistance = [];
  let clickStatus = false;

  let event = window.event;

  let sliderPos = {
    hourSlide: 0,
    minuteSlide: 0
  };
  let liHeight = 40;

  function singleClick(theSlider, index, sliderIndex) {
    curDistance[sliderIndex] = (2 - index) * liHeight;
    movePosition(theSlider, curDistance[sliderIndex]);
  }

  function movePosition(theSlider, distance) {
    sliderPos[theSlider] = distance;
  }

  function updateCurDistance(theSlider, indexWheel) {
    curDistance[indexWheel] = sliderPos[theSlider];
  }
  function fixPosition(distance) {
    return -(getIndex(distance) - 2) * liHeight;
  }
  function getIndex(distance) {
    return Math.round((2 * liHeight - distance) / liHeight);
  }
  function getValue(sliderIndex) {
    var index = getIndex(curDistance[sliderIndex]);
    const selectedWheel = document.querySelectorAll(".wheel")[sliderIndex];
    return selectedWheel.getElementsByTagName("li")[index].innerHTML;
  }

  function touchEvent(e, theSlider, index, total) {
    event = window.event;
    switch (event.type) {
      case "touchstart":
        startY = event.touches[0].clientY;
        oldMoveY = startY;
        break;

      case "touchend":
        moveEndY = event.changedTouches[0].clientY;
        offsetSum = moveEndY - startY;

        updateCurDistance(theSlider, index);
        curDistance[index] = fixPosition(curDistance[index]);
        movePosition(theSlider, curDistance[index]);
        oversizeBorder = -(total - 3) * liHeight;

        if (curDistance[index] + offsetSum > 2 * liHeight) {
          curDistance[index] = 2 * liHeight;
          setTimeout(function() {
            movePosition(theSlider, curDistance[index]);
          }, 100);
        } else if (curDistance[index] + offsetSum < oversizeBorder) {
          curDistance[index] = oversizeBorder;
          setTimeout(function() {
            movePosition(theSlider, curDistance[index]);
          }, 100);
        }
        break;

      case "touchmove":
        event.preventDefault();
        moveY = event.touches[0].clientY;
        offset = moveY - oldMoveY;

        updateCurDistance(theSlider, index);
        curDistance[index] = curDistance[index] + offset;
        movePosition(theSlider, curDistance[index]);
        oldMoveY = moveY;
        break;
    }
  }
  function dragClickEvent(e, theSlider, index, total) {
    event = window.event;
    switch (event.type) {
      case "mousedown":
        startY = event.clientY;
        oldMoveY = startY;
        clickStatus = true;
        break;

      case "mouseup":
        moveEndY = event.clientY;
        offsetSum = moveEndY - startY;

        updateCurDistance(theSlider, index);
        curDistance[index] = fixPosition(curDistance[index]);
        movePosition(theSlider, curDistance[index]);
        oversizeBorder = -(total - 3) * liHeight;

        if (curDistance[index] + offsetSum > 2 * liHeight) {
          curDistance[index] = 2 * liHeight;
          setTimeout(function() {
            movePosition(theSlider, curDistance[index]);
          }, 100);
        } else if (curDistance[index] + offsetSum < oversizeBorder) {
          curDistance[index] = oversizeBorder;
          setTimeout(function() {
            movePosition(theSlider, curDistance[index]);
          }, 100);
        }

        clickStatus = false;
        break;

      case "mousemove":
        event.preventDefault();
        if (clickStatus) {
          moveY = event.clientY;
          offset = moveY - oldMoveY;

          updateCurDistance(theSlider, index);
          curDistance[index] = curDistance[index] + offset;
          movePosition(theSlider, curDistance[index]);
          oldMoveY = moveY;
        }
        break;
    }
  }
</script>

<style>
  .modal-container-timepicker > .content {
    width: 97%;
    display: block;
    position: fixed;
    z-index: 889;
    color: black;
    margin-top: 19em;
    /* bottom: 0px; */
    background-color: #fafafa !important;
  }

  .btnBar {
    border-bottom: 1px solid #dcdcdc;
    font-size: 15px;
    height: 45px;
    position: relative;
    text-align: center;
    line-height: 45px;
  }

  .fixWidth {
    width: 90%;
    margin: 0 auto;
    position: relative;
  }
  .cancel {
    left: 0;
    color: #666;

    height: 45px;
    width: 55px;
    cursor: pointer;
    position: absolute;
    top: 0;
  }

  .title {
    font-size: 15px;
  }
  .ensure {
    right: 0;
    color: #1e83d3;

    height: 45px;
    width: 55px;
    cursor: pointer;
    position: absolute;
    top: 0;
  }
  .wheels {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  .wheel {
    position: relative;
    z-index: 0;
    float: left;
    width: 50%;
    height: 200px;
    overflow: hidden;
    transition: width 0.3s ease;
  }
  .selectContainer {
    display: block;
    text-align: center;
    transition: transform 0.18s ease-out;

    padding-left: 0;
    margin: 0;
    list-style-type: none;
  }

  .selectContainer > li {
    font-size: 15px;
    display: block;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selectLine {
    height: 40px;
    width: 100%;
    position: absolute;
    top: 80px;
    pointer-events: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
  }
  .shadowMask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(
      to bottom,
      #ffffff,
      rgba(255, 255, 255, 0),
      #ffffff
    );
    opacity: 0.9;
    pointer-events: none;
  }
</style>

<div
  class="modal-container-timepicker modal-frame"
  on:click={e => {
    if (e.target.classList.contains('modal-frame')) {
      if (isBackdrop) {
        onClose();
      }
    }
  }}>
  <!-- <div class="grayLayer" /> -->
  <div transition:fly={{ y: 700 }} class="content">
    <div class="btnBar">
      <div on:click={clickClose} class="cancel">Cancel</div>
      <div class="title">Pilih Jam</div>
      <div on:click={clickOk} class="ensure">Pilih</div>
    </div>
    <div class="panel">
      <div class="fixWidth">
        <div class="wheels">
          <div
            on:touchstart={e => {
              touchEvent(e, 'hourSlide', 0, 24);
            }}
            on:touchend={e => {
              touchEvent(e, 'hourSlide', 0, 24);
            }}
            on:touchmove={e => {
              touchEvent(e, 'hourSlide', 0, 24);
            }}
            on:mousedown={e => {
              dragClickEvent(e, 'hourSlide', 0, 24);
            }}
            on:mousemove={e => {
              dragClickEvent(e, 'hourSlide', 0, 24);
            }}
            on:mouseup={e => {
              dragClickEvent(e, 'hourSlide', 0, 24);
            }}
            class="wheel"
            style="width: 50%;">
            <ul
              class="selectContainer"
              style={`transform: translate3d(0px, ${sliderPos['hourSlide']}px, 0px); webkitTransform:translate3d(0px, ${sliderPos['hourSlide']}px, 0px);`}>
              {#each hours as hour, index}
                <li
                  on:click={e => {
                    singleClick('hourSlide', index, 0);
                  }}
                  class={`liSelect`}>
                  {index < 10 ? `0${index}` : index}
                </li>
              {/each}
            </ul>
          </div>
          <div
            on:touchstart={e => {
              touchEvent(e, 'minuteSlide', 1, 59);
            }}
            on:touchend={e => {
              touchEvent(e, 'minuteSlide', 1, 59);
            }}
            on:touchmove={e => {
              touchEvent(e, 'minuteSlide', 1, 59);
            }}
            on:mousedown={e => {
              dragClickEvent(e, 'minuteSlide', 1, 59);
            }}
            on:mousemove={e => {
              dragClickEvent(e, 'minuteSlide', 1, 59);
            }}
            on:mouseup={e => {
              dragClickEvent(e, 'minuteSlide', 1, 59);
            }}
            class="wheel"
            style="width: 50%;">
            <ul
              class="selectContainer"
              style={`transform: translate3d(0px, ${sliderPos['minuteSlide']}px, 0px); webkitTransform:translate3d(0px, ${sliderPos['minuteSlide']}px, 0px);`}>
              {#each minutes as minute, index}
                <li
                  on:click={e => {
                    singleClick('minuteSlide', index, 1);
                  }}
                  class={`liSelect`}>
                  {index < 10 ? `0${index}` : index}
                </li>
              {/each}
            </ul>
          </div>
        </div>
        <div class="selectLine" />
        <div class="shadowMask" />
      </div>
    </div>
  </div>
</div>
