import fetchApi from "./fetchApi";

// GET
export function restGetCurrentPointPersonCache({ companyId, personId }) {
  const path = `/point/currentPointPersonCache/${companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
