<script>
  import OrgItem from "./OrgItem.svelte";

  export let children;

  const childrenKeys = Object.keys(children);
</script>

<ul>
  {#each childrenKeys as key}
    <OrgItem data={children[key]} />
  {/each}
</ul>
