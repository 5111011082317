<script>
  import { isReqOtpCode } from "../../stores/auth/otpForm";

  import OtpRequest from "./OtpRequest.svelte";
  import OtpVerify from "./OtpVerify.svelte";
</script>

{#if $isReqOtpCode}
  <OtpVerify />
{:else}
  <OtpRequest />
{/if}
