<script>
  import {
    ACTIVITY_STATUS,
    JOB_ASSIGNMENT_STATUS
  } from "../../helpers/constants";
  export let type;
  export let status;
  export let className = "";
</script>

<style>
  span {
    font-size: 10px;
    line-height: 14px;
  }
</style>

{#if type === 'activity'}
  {#if status === ACTIVITY_STATUS.NEED_VERIFY}
    <span class={`cyan-text ${className}`}>Belum Acc</span>
  {:else if status === ACTIVITY_STATUS.REJECT}
    <span class={`red-text ${className}`}>Ditolak</span>
  {:else if status === ACTIVITY_STATUS.ACCEPT}
    <span class={`blue-text ${className}`}>Diterima</span>
  {:else if status === ACTIVITY_STATUS.UPDATE_ACTIVITY}
    <span class={`teal-text ${className}`}>Merubah</span>
  {:else}
    <span class={`grey-text text-darken-4 ${className}`}>Loading</span>
  {/if}
{/if}

{#if type === 'jobAssignment'}
  {#if status === JOB_ASSIGNMENT_STATUS.OPEN}
    <span class={`grey-text text-darken-2 ${className}`}>Belum Mulai</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.NEED_REVISION}
    <span class={`orange-text ${className}`}>Meminta Revisi</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.ON_PROGRESS}
    <span class={`grey-text ${className}`}>In Progress</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.REJECT}
    <span class={`red-text ${className}`}>Ditolak</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.NEED_VERIFY}
    <span class={`cyan-text ${className}`}>Meminta Verifikasi</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.ACCEPT}
    <span class={`blue-text ${className}`}>Diterima</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.UPDATE_TASK}
    <span class={`teal-text ${className}`}>Merubah</span>
  {:else}
    <span class={`grey-text text-darken-4 ${className}`}>Loading</span>
  {/if}
{/if}
