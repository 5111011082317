import { writable } from "svelte/store";

import { restGetActivityPersonNeedVerifySubordinateGroupCache } from "../../api/activityPerson";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

export let listData = writable([]);

export async function getActivityPersonNeedVerifySubordinateGroupCache() {
  error.set("");
  loading.set(true);
  listData.set([]);

  // SET DATES
  try {
    const resp = await restGetActivityPersonNeedVerifySubordinateGroupCache({
      personId: window.myPersonId
    });
    if (resp && resp.data) {
      listData.set(resp.data);
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }

  loading.set(false);
}
