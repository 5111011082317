import { tick } from "svelte";
import _ from "lodash";
import { writable, get } from "svelte/store";
import { navigate } from "svelte-routing";

import { ACTIVITY_STATUS } from "../../helpers/constants";
import { restPostActivityPersonCreate } from "../../api/activityPerson";
import { listData as myActivityListData } from "./myActivityList";

import { errorNotif, successNotif } from "../../helpers/notification";

const MY_ACTIVITY_MAX_DAILY = 4320;

export let loading = writable(false);
export let error = writable("");

export let selectedSupervisor = writable({});
export let selectedActivityType = writable({});
export let showModal = writable(null);
// FORMDATA
export let inputActivityTypeMultiplier = writable(1);
export let inputDesc = writable("");
export let inputStartTime = writable("08:00");
export let inputEndTime = writable("");

export function initialState() {
  loading.set(false);
  error.set("");

  selectedSupervisor.set({});
  selectedActivityType.set({});
  showModal.set(null);
  // FORMDATA
  inputActivityTypeMultiplier.set(1);
  inputDesc.set("");
  inputStartTime.set("08:00");
  inputEndTime.set("");
}

export function validateAndCreateActivity({ date, filterName, getData }) {
  error.set("");
  const curSupervisor = get(selectedSupervisor);
  const curActivityType = get(selectedActivityType);

  const activityMultiplier = get(inputActivityTypeMultiplier);
  const startTime = get(inputStartTime);

  if (!startTime) {
    error.set("Waktu mulai harus dipilih");
    return;
  }

  if (!curActivityType || !curActivityType.id) {
    error.set("Aktivitas Harus dipilih");
    return;
  }

  if (
    !curSupervisor ||
    !curSupervisor.id ||
    !curSupervisor.ownerId ||
    !curSupervisor.ownerName
  ) {
    error.set("Atasan langsung tidak ditemukan");
    return;
  }

  if (curSupervisor.ownerId === window.myPersonId) {
    error.set("Tidak boleh melapor ke diri sendiri");
    return;
  }

  const totalPoint = curActivityType.point * activityMultiplier;
  const errorReachedMax = isPointReachedMax(totalPoint);

  if (errorReachedMax) {
    error.set(errorReachedMax);
    return;
  }

  const currentDateTimeString = `${date}${startTime}`;
  const currentDateTimeMoment = window.moment(
    currentDateTimeString,
    "YYYYMMDDHH:mm"
  );
  const startDateTime = currentDateTimeMoment.format("YYYYMMDDHHmm");
  const endDateTime = currentDateTimeMoment
    .add(totalPoint, "minutes")
    .format("YYYYMMDDHHmm");
  const startDay = Number(
    window.moment(startDateTime, "YYYYMMDDHHmm").format("YYYYMMDD")
  );
  const endDay = Number(
    window.moment(endDateTime, "YYYYMMDDHHmm").format("YYYYMMDD")
  );

  if (endDay > startDay) {
    error.set(
      "Aktivitas hari ini sudah melewati jam 23:59, silahkan mengisi aktivitas pada hari berikut"
    );
    return;
  }

  const errorTimeCheck = validateActivityTime(startDateTime, endDateTime);
  if (errorTimeCheck) {
    error.set(errorTimeCheck);
    return;
  }

  const formData = {
    startDate: Number(startDateTime),
    endDate: Number(endDateTime),
    activityTypeId: curActivityType.id,
    activityTypeMultiplier: activityMultiplier,
    desc: get(inputDesc),
    supervisorJobId: curSupervisor.id,
  };

  // CALL CREATE API
  postActivityPersonCreate({ formData, date, filterName, getData });

  function isPointReachedMax(newPoint) {
    const myActivities = get(myActivityListData);
    const allowedMaxDaily = MY_ACTIVITY_MAX_DAILY;
    let sumTotalPoint = 0;
    myActivities.forEach((obj) => {
      if (obj.status !== ACTIVITY_STATUS.REJECT && obj.activityTypePointTotal) {
        sumTotalPoint += obj.activityTypePointTotal;
      }
    });
    sumTotalPoint += newPoint;
    if (sumTotalPoint > allowedMaxDaily) {
      return `Aktivitas total hari ini tidak boleh melewati point maksimum harian (${allowedMaxDaily} poin)`;
    }
    return "";
  }

  function parseMilliseconds(input) {
    return window.moment(input, "YYYYMMDDHHmm")._d.valueOf();
  }

  function validateActivityTime(startDateTime, endDateTime) {
    const myActivities = get(myActivityListData);
    let conflictObjStart = {};
    let conflictObjEnd = {};
    myActivities.forEach((obj) => {
      if (obj.status !== ACTIVITY_STATUS.REJECT) {
        const start = parseMilliseconds(startDateTime);
        const end = parseMilliseconds(endDateTime);
        const compareStart = parseMilliseconds(obj.startDate);
        const compareEnd = parseMilliseconds(obj.endDate);
        if (
          !conflictObjStart.id &&
          start > compareStart &&
          start < compareEnd
        ) {
          conflictObjStart = obj;
        }
        if (!conflictObjEnd.id && end > compareStart && end < compareEnd) {
          conflictObjEnd = obj;
        }
        if (
          !conflictObjStart.id &&
          compareStart > start &&
          compareStart < end
        ) {
          conflictObjStart = obj;
        }
        if (!conflictObjEnd.id && compareEnd > start && compareEnd < end) {
          conflictObjEnd = obj;
        }
        if (compareStart === start && compareEnd === end) {
          conflictObjStart = obj;
        }
      }
    });
    if (conflictObjStart && conflictObjStart.id) {
      return `Konflik jam mulai dengan ${
        conflictObjStart.activityTypeName
      } (${window
        .moment(conflictObjStart.startDate, "YYYYMMDDHHmmSS")
        .format("HH:mm")}-${window
        .moment(conflictObjStart.endDate, "YYYYMMDDHHmmSS")
        .format("HH:mm")})`;
    }
    if (conflictObjEnd && conflictObjEnd.id) {
      return `Konflik jam selesai dengan "${
        conflictObjEnd.activityTypeName
      }" (${window
        .moment(conflictObjEnd.startDate, "YYYYMMDDHHmmSS")
        .format("HH:mm")}-${window
        .moment(conflictObjEnd.endDate, "YYYYMMDDHHmmSS")
        .format("HH:mm")})`;
    }
    return "";
  }
}

export async function postActivityPersonCreate({
  formData,
  date,
  filterName,
  getData,
}) {
  error.set("");
  loading.set(true);

  try {
    await restPostActivityPersonCreate({
      formData,
    });
    successNotif(`Berhasil tambah aktivitas`);
    getData();
    await tick();
    loading.set(false);
    navigate(`/my-activity/by-date/${date}/filter/${filterName}`);
    inputActivityTypeMultiplier.set(1);
    selectedActivityType.set({});
    inputDesc.set("");
    inputStartTime.set("08:00");
    inputEndTime.set("");
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}

export function setInitialStartTime() {
  const myActivities = get(myActivityListData);
  let startTime = get(inputStartTime);
  //find start time

  if (myActivities.length < 1) {
    startTime = "08:00";
  } else {
    const sortResult = _.orderBy(myActivities, ["endDate"], ["desc"]);

    const nextDateTimeMoment = window.moment(
      `${sortResult[0].endDate}`,
      "YYYYMMDDHHmm"
    );

    startTime = nextDateTimeMoment.format("HH:mm");
  }

  inputStartTime.set(startTime);
}

export function setEndTime(currentDate) {
  const curActivityType = get(selectedActivityType);
  const activityMultiplier = get(inputActivityTypeMultiplier);

  let endDateTime = "";
  let endTime = "";

  if (curActivityType && curActivityType.point) {
    const currentDateTimeString = `${currentDate}${get(inputStartTime)}`;
    const currentDateTimeMoment = window.moment(
      currentDateTimeString,
      "YYYYMMDDHH:mm"
    );
    const totalPoint = curActivityType.point * activityMultiplier;

    endDateTime = currentDateTimeMoment
      .add(totalPoint, "minutes")
      .format("YYYYMMDDHHmm");
    endTime = window.moment(endDateTime, "YYYYMMDDHHmm").format("HH:mm");
  } else {
    endTime = "";
  }

  inputEndTime.set(endTime);
}
