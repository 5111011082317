<script>
  import { Link } from "svelte-routing";
  import { StarInput } from "../../../components/common";

  export let data = {};
  export let onChange;
</script>

<li class="collection-item p-l-10 p-r-10">

  <div>{data.text}</div>
  <div>
    <span
      on:click={() => {
        data.value = 0;
        onChange(data);
      }}
      class="red-text cursor-pointer">
      <i class="fa fa-times fa-2x" />
    </span>
    <StarInput
      value={Math.ceil(data.value / 10)}
      onClickStar={v => {
        data.value = v * 10;
        onChange(data);
      }}
      fontClass="font-28" />
    <span class="font-20">{Math.ceil(data.value)} %</span>
  </div>

</li>
