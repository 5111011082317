<script>
  import { user, logout } from "../../stores/auth/myUser.js";
  import { data as dataMyCompany } from "../../stores/company/myCompany.js";

  import {
    loading,
    error,
    inputPersonNo,
    changeMyPerson
  } from "../../stores/person/setMyPersonNoForm.js";
</script>

<style>
  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div class="center-container">
  <h3 class="center-align">
    <span class="font-30">No Identitas</span>
  </h3>
  <div class="login-box">
    <div class="row">
      <form on:submit|preventDefault={changeMyPerson} class="col s12">
        <div>Masukan NIP (No Induk Pegawai)</div>
        <div class="row">
          <div class="input-field col s12">
            <input
              value={$dataMyCompany.name}
              autocomplete="off"
              id="companyName"
              type="text"
              class="validate"
              disabled={true} />
            <label class="active" for="companyName">Nama Organisasi</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input
              value={$user.mobile ? $user.mobile.replace('+628', '08') : ''}
              autocomplete="off"
              id="mobile"
              type="tel"
              class="validate"
              disabled={true} />
            <label class="active" for="mobile">No HP</label>
          </div>
        </div>

        <div class="row">
          <div class="input-field col s12">
            <input
              bind:value={$inputPersonNo}
              autocomplete="off"
              id="personNo"
              type="tel"
              class="validate" />

            <label class="active" for="personNo">No Induk Pegawai</label>

          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="red-text m-b-4">{$error}</div>
            <button type="submit" class="btn red darken-4 btn-block">
              Update Data
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <span
              on:click={logout}
              class="right red-text text-darken-4 cursor-pointer">
              Kembali ke Login
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
