<script>
  import {
    loading,
    showModal,
    postActivityPersonDelete
  } from "../../../stores/myActivity/deleteForm";

  import { TipsBox, StatusLabel, Img } from "../../../components/common";
  import { ModalConfirm } from "../../../components/modals";

  export let data = {};
  export let date;
  export let filterName;

  const startDateMoment = window.moment(data.startDate, "YYYYMMDDHHmm");
  const endDateMoment = window.moment(data.endDate, "YYYYMMDDHHmm");

  const startTimeStr = startDateMoment.format("HH:mm");
  const endTimeStr = endDateMoment.format("HH:mm");
  const durationStr = endDateMoment.from(startDateMoment, true);
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
  .badge-box {
    height: 22px;
  }
  .badge-custom {
    float: left;
    margin-left: -14px;
  }
  .icon-label-text {
    width: 10px;
  }

  .supervisor-name {
    color: #009688;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

{#if $showModal}
  <ModalConfirm
    onClose={() => showModal.set(false)}
    onSubmit={() => {
      postActivityPersonDelete({ data, date, filterName });
    }}
    title="Konfirmasi"
    msg={`Yakin hapus aktivitas "${data.activityTypeName}"?`} />
{/if}

<div class="card-panel p-0 m-t-0">
  <div class="person-box p-h-14 p-v-14">
    <div>
      <div class="">
        <Img
          className="circle img-width"
          alt={`Melapor ke ${data.supervisorPersonName}`}
          personId={data.supervisorPersonId} />
      </div>
    </div>
    <div class="full-width m-l-14 ">
      <div class="font-10 grey-text">
        <div>
          <i class="fa fa-clock-o icon-label-text" />
          {startTimeStr}-{endTimeStr} (
          <span>{durationStr}</span>
          )
        </div>
      </div>
      <div class="font-10 grey-text">
        <i class="fa fa-user icon-label-text" />
        {data.personName}
      </div>
      <div class="font-10 supervisor-name">
        <i class="fa fa-sitemap icon-label-text" />
        {`Melapor ke ${data.supervisorPersonName}`}
      </div>
      <div class="font-10 grey-text">
        <i class="fa fa-circle icon-label-text" />
        {data.activityTypePoint} x {data.activityTypeMultiplier}
        <span class="grey-text">
          {data.activityTypeUnit ? `(${data.activityTypeUnit})` : ''}
        </span>
        = {data.activityTypePointTotal} poin
      </div>
      <div class="font-14">{data.activityTypeName}</div>
      <div class="font-12 grey-text">{data.desc}</div>
      {#if data.status === 40 && data.closedReason}
        <div class="font-14 red-text">
          <b>{data.closedReason}</b>
        </div>
      {/if}
    </div>
    <div>
      {#if !data.closed}
        <div
          on:click|preventDefault={() => {
            showModal.set(true);
          }}
          class="red-text right-align ">
          <i class="fa fa-times cursor-pointer" />
        </div>
      {/if}
      <div class="badge-box">
        <div class="badge-custom">
          <StatusLabel type="activity" status={data.status} />
        </div>
      </div>
    </div>
  </div>
</div>
