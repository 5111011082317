<script>
  import { Link } from "svelte-routing";

  import { JOB_ASSIGNMENT_STATUS } from "../../../helpers/constants";
  import { Img } from "../../../components/common";

  export let data;
</script>

<style>
  .collection-item {
    min-height: 108px !important;
  }
  .img-width {
    max-width: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  .person-box {
    display: flex;
    align-content: flex-start;
  }
  .status-box {
    display: flex;
    justify-content: space-between;
  }
</style>

<li class="collection-item p-v-4 p-h-14">
  <Link
    to={`/my-task-assignment/supervisor/${data.supervisorPersonId}/task/all`}
    getProps={() => ({ class: 'cursor-pointer' })}>
    <div class="person-box">
      <div>
        <Img
          personId={data.supervisorPersonId}
          alt={data.supervisorPersonName}
          className="img-width circle" />
      </div>
      <div class="m-l-14">
        <span class="title red-text text-darken-4">
          <i class="fa fa-user" />
          {data.supervisorPersonName}
        </span>
        <div class="font-12">
          <div class="black-text">
            <i class="fa fa-address-card-o" />
            {data.supervisorPersonNo}
          </div>
          <slot name="middle" />
          <slot />
        </div>
      </div>
    </div>
    <div class="status-box">
      <div class="center-align">
        <div class={`grey-text`}>
          <div class="font-16">
            {data.counts[JOB_ASSIGNMENT_STATUS.NEED_VERIFY] ? data.counts[JOB_ASSIGNMENT_STATUS.NEED_VERIFY] : 0}
            <i class="fa fa-question-circle" />
          </div>
          <div class="font-10">
            Menunggu
            <br />
            Verifikasi
          </div>
        </div>
      </div>
      <div class="center-align">
        <div class={`grey-text`}>
          <div class="font-16">
            {data.counts[JOB_ASSIGNMENT_STATUS.OPEN] ? data.counts[JOB_ASSIGNMENT_STATUS.OPEN] : 0}
            <i class="fa fa fa-envelope" />
          </div>
          <div class="font-10">Belum Mulai</div>
        </div>
      </div>
      <div class="center-align">
        <div class={`grey-text`}>
          <div class="font-16">
            {data.counts[JOB_ASSIGNMENT_STATUS.ON_PROGRESS] ? data.counts[JOB_ASSIGNMENT_STATUS.ON_PROGRESS] : 0}
            <i class="fa fa fa-hourglass-half" />
          </div>
          <div class="font-10">
            Sedang
            <br />
            Berlangsung
          </div>
        </div>
      </div>
      <div class="center-align">
        <div class={`grey-text`}>
          <div class="font-16">
            {data.counts[JOB_ASSIGNMENT_STATUS.NEED_REVISION] ? data.counts[JOB_ASSIGNMENT_STATUS.NEED_REVISION] : 0}
            <i class="fa fa fa-thumbs-down" />
          </div>
          <div class="font-10">
            Perlu
            <br />
            Direvisi
          </div>
        </div>
      </div>
    </div>
  </Link>
</li>
