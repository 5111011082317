<script>
  import { onMount } from "svelte";

  import {
    loading,
    listData,
    getListThreeMonth
  } from "../../stores/personMonthlyHistory/listThreeMonth";

  import { HeaderNav, Footer } from "../../components/common";
  import { HistoryItem } from "./components";

  onMount(() => {
    getListThreeMonth();
  });
</script>

<style>
  .point-list {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
  }

  .root-container {
    padding-top: 0px;
  }
  .container {
    margin: 0px;
    width: 100% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Histori Pencapaian"
  backURL={`/home`}
  onRefresh={getListThreeMonth} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="point-list">
      {#each $listData as data}
        <HistoryItem {data} />
      {/each}
    </div>
  </div>
</div>
