<script>
  export let startDate;

  const startDateUnix = window
    .moment(startDate, "YYYYMMDDHHmm")
    .toDate()
    .getTime();

  function isSunday(date) {
    return window.moment(date).weekday() === 6;
  }

  function isHolidayClass(isHoliday) {
    if (!isHoliday) {
      return "";
    }
    return "red white-text";
  }
</script>

<style>
  .dates-box {
    display: flex;
    justify-content: space-between;
  }
  .date-item {
    width: 100%;
    height: 100%;
    text-align: center;
  }
</style>

<div class="card-panel dates-box p-0 m-t-0 m-b-2">
  <div class={`date-item ${isHolidayClass(isSunday(startDateUnix))}`}>
    {window.moment(startDateUnix).format('dddd')}
    {#if isSunday(startDateUnix)}
      <i class="fa fa-info-circle" title="Hari minggu" />
    {/if}
    <br />
    {window.moment(startDateUnix).format('DD/MMM/YYYY')}
  </div>
</div>
