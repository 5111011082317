import { writable, get } from "svelte/store";

export const serverTime = writable(0);
export const currentDate = writable(0);
export const maxPrevDate = writable(20200501);
export const datesNav = writable({
  prev: {
    disable: false,
    date: window.moment().add(-1, "days").toDate().getTime(),
  },
  current: {
    disable: false,
    date: window.moment().toDate().getTime(),
    warningMsg: "Aktivitas hanya dapat diisi maksimal 4 hari sebelumnya.",
    disableMsg:
      "Aktivitas hanya bisa diisi minimal 4 hari sebelumnya dan maksimal hari ini.",
  },
  next: {
    disable: false,
    date: window.moment().add(1, "days").toDate().getTime(),
  },
});

export function setDatesNav() {
  const maxPrevDateLocal = get(maxPrevDate);
  // mesti create new moment instantce utk bs -4
  const currentDateInt = get(currentDate);
  const currentDateMoment = window.moment(`${currentDateInt}`, "YYYYMMDD");
  const prevDateMoment = window
    .moment(`${currentDateInt}`, "YYYYMMDD")
    .add(-1, "days");
  const nextDateMoment = window
    .moment(`${currentDateInt}`, "YYYYMMDD")
    .add(1, "days");

  const currentServerTime = get(serverTime);

  const nextDatesNav = Object.assign({}, get(datesNav));

  nextDatesNav.current.date = currentDateMoment.toDate().getTime();
  nextDatesNav.current.disable = false;
  delete nextDatesNav.current.warning;
  nextDatesNav.prev.date = prevDateMoment.toDate().getTime();
  nextDatesNav.prev.disable = false;
  nextDatesNav.next.date = nextDateMoment.toDate().getTime();
  nextDatesNav.next.disable = false;

  if (currentServerTime > 0) {
    if (nextDatesNav.next.date > currentServerTime) {
      nextDatesNav.next.disable = true;
    } else {
      nextDatesNav.next.disable = false;
    }

    if (nextDatesNav.current.date > currentServerTime) {
      nextDatesNav.current.disable = true;
      nextDatesNav.prev.disable = false;
    } else {
      nextDatesNav.current.disable = false;
    }

    //blockir default
    // const prevDateWarningInt = parseInt(
    //   window.moment(currentServerTime).add(-4, "days").format("YYYYMMDD"),
    //   10
    // );

    // buka last month
    // const prevDateWarningInt = parseInt(
    //   window.moment().add(-1, "months").startOf("month").format("YYYYMMDD"),
    //   10
    // );

    //buka current month
    // const prevDateWarningInt = parseInt(
    //   window.moment(currentServerTime).add(-4, "days").format("YYYYMMDD"),
    //   10
    // );

    //buka current month
    const prevDateWarningInt = parseInt(
      window.moment().add(-1, "months").startOf("month").format("YYYYMMDD"),
      10
    );
    // const prevDateWarningInt = parseInt(
    //   window.moment().startOf("month").format("YYYYMMDD"),
    //   10
    // );

    if (currentDateInt < prevDateWarningInt) {
      nextDatesNav.current.warning = true;
    }
    // if (currentDateInt < maxPrevDateLocal) {
    //   nextDatesNav.current.warning = true;
    // }
  }

  if (currentDateInt < maxPrevDateLocal) {
    nextDatesNav.current.disable = true;
    nextDatesNav.next.disable = false;
  }

  const prevDateInt = parseInt(
    window.moment(nextDatesNav.prev.date).format("YYYYMMDD"),
    10
  );
  if (prevDateInt < maxPrevDateLocal) {
    nextDatesNav.prev.disable = true;
    nextDatesNav.next.disable = false;
  }

  datesNav.set(nextDatesNav);
}
