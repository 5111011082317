import { writable } from "svelte/store";

import { restGetActivityPersonDetail } from "../../api/activityPerson";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(true);
export let error = writable("");

export let data = writable({});

export async function getMyActivityDetail({ activityId }) {
  error.set("");
  loading.set(true);
  data.set({});
  try {
    const resp = await restGetActivityPersonDetail({
      activityId
    });
    loading.set(false);

    data.set(resp.data);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
