<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  import { Star, Img } from "../../../components/common";

  export let data;
  export let detailURL = "/";

  let monthString = "";

  onMount(() => {
    monthString = window.moment(data.month, "YYYYMM").format("MMMM YYYY");
  });
</script>

<style>
  .collection-item {
    min-height: 70px !important;
  }
  .img-width {
    max-width: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  .person-box {
    display: flex;
    align-content: flex-start;
  }
</style>

<li class="collection-item p-v-4 p-h-14">

  <Link to={detailURL} getProps={() => ({ class: 'cursor-pointer' })}>
    <div class="person-box">
      <div>
        <Img
          personId={data.personId}
          alt={data.personName ? data.personName : '[Tidak ada pegawai]'}
          className="img-width circle" />
      </div>
      <div class="m-l-14">
        <span class="title red-text text-darken-4">
          <i class="fa fa-user" />
          {data.personName ? data.personName : '[Tidak ada pegawai]'}
        </span>
        <div class="font-12">
          <div>
            <Star
              value={Math.ceil(data.totalPercentage / 10) || 0}
              fontClass="font-20" />
          </div>
        </div>
        {#if data.totalPercentage}
          <div class={`font-16 green-text`}>
            Total Persentasi: {data.totalPercentage}
            <i class="fa fa-percent" />
          </div>
        {/if}
        {#if data.personNo}
          <div class="m-t-2 ">
            <i class="fa fa-address-card-o" />
            {data.personNo}
          </div>
        {/if}
        <div class={`font-16 black-text`}>
          <i class="fa fa-calendar" />
          {monthString}
        </div>
        {#if data.raterType === 'supervisor'}
          <div class={`font-16 red-text`}>
            <i class="fa fa-question-circle" />
            Bawahan menunggu penilaian
          </div>
        {/if}

      </div>
    </div>
  </Link>
</li>
