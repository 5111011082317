<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";
  import { user } from "../../stores/auth/myUser.js";
  import {
    loading,
    error,
    setNewPassword,
    inputPassword,
    inputRePassword
  } from "../../stores/auth/setNewPasswordForm";
  import { Footer } from "../../components/common";

  onMount(() => {
    inputPassword.set("");
    inputRePassword.set("");
  });
</script>

<style>
  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div class="center-container">
  <h3 class="center-align">
    <span class="font-30">Password Baru</span>
  </h3>
  <div class="login-box">
    <div class="row">
      <form on:submit|preventDefault={setNewPassword} class="col s12">
        <div class="row">
          <div class="input-field col s12">
            <input
              value={$user && $user.mobile ? $user.mobile.replace('+628', '08') : ''}
              autocomplete="off"
              id="mobile"
              type="tel"
              class="validate"
              disabled={true} />
            <label class="active" for="mobile">No HP</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input
              bind:value={$inputPassword}
              autocomplete="off"
              id="password"
              type="password"
              class="validate" />
            <label class="active" for="password">Password Baru</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input
              bind:value={$inputRePassword}
              autocomplete="off"
              id="rePassword"
              type="password"
              class="validate" />
            <label class="active" for="rePassword">Ulangi Password Baru</label>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="red-text m-b-4">{$error}</div>
            <button type="submit" class="btn red darken-4 btn-block">
              Update Password
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<Footer />
