<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  export let onClose;
  export let onOk;
  export let isBackdrop = true;

  let error = "";
  let val = "";

  function clickClose() {
    error = "";
    onClose();
  }
  function clickOk() {
    error = "";
    onOk(val, onError);
  }
  function onError(err) {
    error = err;
  }

  onMount(() => {
    const descInput = document.querySelector("#descInput");
    if (descInput) {
      descInput.focus();
    }
  });
</script>

<style>
  .btn-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
</style>

<div
  class="modal-frame"
  on:click={e => {
    if (e.target.classList.contains('modal-frame')) {
      if (isBackdrop) {
        onClose();
      }
    }
  }}>
  <div transition:fly={{ y: 200 }} class="modal-container">
    <div class="card">
      <form on:submit|preventDefault={clickOk}>
        <div class="card-content">
          <span class="card-title">Terima</span>
          <p>Yakin terima laporan aktivitas?</p>

          <div class="red-text">{error}</div>
        </div>
        <div class="card-action">
          <div class="btn-flex">
            <button
              on:click={clickClose}
              type="button"
              class="btn grey white-text">
              Cancel
              <i class="fa fa-times" />
            </button>
            <button type="submit" class="btn blue white-text">
              Terima
              <i class="fa fa-floppy-o" />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
