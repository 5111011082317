<script>
  import {
    showModal as showModalDelete,
    loading as loadingDelete,
    postTaskAssignmentDelete
  } from "../../../stores/taskAssignmentSub/deleteForm";
  import { showModal as showModalEdit } from "../../../stores/taskAssignmentSub/editForm";

  import { JOB_ASSIGNMENT_STATUS } from "../../../helpers/constants";

  import { StatusLabel, Img } from "../../../components/common";
  import { ModalConfirm } from "../../../components/modals";
  import { ModalEdit } from "../modals";

  export let detailData;
  export let subordinateId;
  export let taskId;
  export let filterName;

  const currentDate = moment().format("YYYYMMDD");
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
  .badge-box {
    height: 22px;
  }
  .badge-custom {
    float: left;
    margin-left: -14px;
  }
</style>

{#if $showModalDelete}
  {#if $loadingDelete}
    <div class="loader-block" />
  {/if}
  <ModalConfirm
    onClose={() => {
      showModalDelete.set(false);
    }}
    onSubmit={() => {
      postTaskAssignmentDelete({
        taskAssignmentId: taskId,
        detailData,
        filterName
      });
    }}
    title="Hapus"
    msg={`Yakin hapus ${detailData.desc} ?`} />
{/if}

{#if $showModalEdit}
  <ModalEdit {detailData} />
{/if}

<div class="card-panel p-0 m-t-0">
  <div class="person-box p-v-14 p-h-14">
    <div>
      <div class="">
        <Img className="circle img-width" alt="dari" personId={subordinateId} />
      </div>
    </div>
    <div class="full-width m-l-14">
      {#if detailData.updatedAt || detailData.createdAt}
        <div class="font-10 grey-text">
          <i class="fa fa-clock-o" />
          {window.moment(detailData.createdAt).format('DD MM YYYY HH:mm')}
          {#if detailData.updatedAt}
            - {window.moment(detailData.updatedAt).format('DD MM YYYY HH:mm')}
          {/if}
        </div>
      {/if}
      {#if detailData.deadline}
        <div class="font-10 red-text text-darken-4">
          <i class="fa fa-calendar" />
          {window
            .moment(detailData.deadline, 'YYYYMMDDHHmm')
            .format('D MMMM YY')}
        </div>
        <div class="font-10 red-text text-darken-4">
          {#if Number(currentDate) <= Number(detailData.deadline)}
            Tenggat waktu {window.moment(detailData.deadline, 'YYYYMMDD').fromNow()}
          {:else}Sudah melewati tenggat waktu{/if}
        </div>
      {/if}
      <div class="font-10 grey-text">
        Progress: {detailData.progress ? detailData.progress : 0}%
      </div>
      <div class="badge-box">
        <span class="badge-custom">
          <StatusLabel type="jobAssignment" status={detailData.status} />
        </span>
      </div>
      <div class="font-12">
        {detailData.desc ? detailData.desc : ''}
        {#if !detailData.closed}
          <span
            on:click={() => {
              showModalEdit.set(true);
            }}
            class="red-text text-darken-4 cursor-pointer">
            <i class="fa fa-edit" />
          </span>
        {/if}
      </div>
    </div>
    <div>
      {#if detailData.status !== JOB_ASSIGNMENT_STATUS.ACCEPT && detailData.status !== JOB_ASSIGNMENT_STATUS.REJECT}
        <div
          on:click={() => {
            showModalDelete.set(true);
          }}
          class="red-text right-align ">
          <i class="fa fa-times cursor-pointer" />
        </div>
      {/if}
    </div>
  </div>
  <slot />
</div>
