import fetchApi from "./fetchApi";

// GET
export function restGetCurrentEabsen({ personId }) {
  const path = `/eabsen/byPerson/${window.companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET",
  });
}
export function restGetEabsenPersonByDate({ personId, date }) {
  const path = `/eabsen/byPersonDate/${window.companyId}/${personId}/${date}`;
  return fetchApi(path, {
    method: "GET",
  });
}

export function restGetEabsenHistoryListCache({ personId }) {
  const path = `/eabsen/historyListByPersonCache/${window.companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET",
  });
}

// export function restGetCreateCheckIn() {
//   const path = `/eabsen/checkIn`;
//   return fetchApi(path, {
//     method: "GET",
//   });
// }

// export function restGetCreateCheckOut() {
//   const path = `/eabsen/checkOut`;
//   return fetchApi(path, {
//     method: "GET",
//   });
// }
// POST

export function restPostCreateCheckIn(formData) {
  const path = `/eabsen/checkIn`;
  return fetchApi(path, {
    method: "POST",
    body: formData,
  });
}

export function restPostCreateCheckOut(formData) {
  const path = `/eabsen/checkOut`;
  return fetchApi(path, {
    method: "POST",
    body: formData,
  });
}
