<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  import { data as subordinatesData } from "../../../stores/org/mySubordinates";
  import { data as myPersonData } from "../../../stores/person/myPerson";
  import { user } from "../../../stores/auth/myUser";

  import {
    IS_VALID_ROOT_MOBILE,
    IS_VALID_USER_ADMIN
  } from "../../../helpers/constants";

  const emptyMenu = {
    url: "",
    icon: "fa fa-battery-empty",
    name: "empty"
  };
  let listMenu = [];
  let listMenuUi = [];
  // SEKPROV
  let listMenuSekprov = [    
    {
      url: "https://apps-bkd.sulutprov.com/app_dashboard/profil/index.php",
      special: true,
      type: "link",
      icon: "fa fa-user-circle-o",
      name: "Biodata"
      
    },

    {
      url: "/person-rating",
      icon: "fa fa-star-o",
      name: "Penilaian Perilaku"
    },
    {
      url: "/task-assignment",
      icon: "fa fa-sort-amount-desc",
      name: "Penugasan Ke Bawahan"
    },
    {
      url: "/org",
      icon: "fa fa-sitemap ",
      name: "Organisasi"
    },
    // {
    //   url: "/eabsen",
    //   icon: "fa fa-clock-o",
    //   name: "eAbsensi Online"
    // },
    {
      url: "https://apps-bkd.sulutprov.com/presensi_nakes/auth/login",
      special: true,
      type: "link",
      icon: "fa fa-clock-o",
      name: "eAbsensi Online"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Aktivitas/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa-building-o",
      name: "Aktivitas Khusus"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Absensi/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa fa-calendar-times-o",
      name: "Lapor Izin/Sakit"
    },
    {
      url: "/person-monthly-history",
      icon: "fa fa-bar-chart",
      name: "Histori Pencapaian"
    },
    {
      url: "/phone-book",
      icon: "fa fa-address-book",
      name: "Daftar Kontak"
    },
    {
      url: "/user",
      icon: "fa fa-lock",
      name: "Profil"
    }
  ];

  // COMMON 3 ESELON II
  let listMenuEselonII = [
    {
      url: "https://apps-bkd.sulutprov.com/app_dashboard/profil/index.php",
      special: true,
      type: "link",
      icon: "fa fa-user-circle-o",
      name: "Biodata"
      
    },
    {
      url: "/person-rating",
      icon: "fa fa-star-o",
      name: "Penilaian Perilaku"
    },
    {
      url: "/my-task-assignment",
      icon: "fa fa-sort-amount-asc",
      name: "Penugasan Dari Pimpinan"
    },
    {
      url: "/org",
      icon: "fa fa-sitemap ",
      name: "Organisasi"
    },
    // {
    //   url: "/eabsen",
    //   icon: "fa fa-clock-o",
    //   name: "eAbsensi Online"
    // },
    {
      url: "https://apps-bkd.sulutprov.com/presensi_nakes/auth/login",
      special: true,
      type: "link",
      icon: "fa fa-clock-o",
      name: "eAbsensi Online"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Aktivitas/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa-building-o",
      name: "Aktivitas Khusus"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Absensi/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa fa-calendar-times-o",
      name: "Lapor Izin/Sakit"
    },
    {
      url: "/person-monthly-history",
      icon: "fa fa-bar-chart",
      name: "Histori Pencapaian"
    },
    {
      url: "/phone-book",
      icon: "fa fa-address-book",
      name: "Daftar Kontak"
    },
    {
      url: "/user",
      icon: "fa fa-lock",
      name: "Profil"
    }
  ];

  // COMMON 2
  const listMenuEselon = [
    {
      url: "https://apps-bkd.sulutprov.com/app_dashboard/profil/index.php",
      special: true,
      type: "link",
      icon: "fa fa-user-circle-o",
      name: "Biodata"
      
    },
    {
      url: "/my-activity",
      icon: "fa fa-bar-chart-o",
      name: "My Aktivitas"
    },
    {
      url: "/my-sub-activity",
      icon: "fa fa-align-left",
      name: "Aktivitas Bawahan"
    },
    // {
    //   type: "link",,
    //   url: "https://sulutprov.web.app/",
    //   icon: "fa fa-star-o",
    //   name: "Penilaian Perilaku"
    // },
    {
      url: "/person-rating",
      icon: "fa fa-star-o",
      name: "Penilaian Perilaku"
    },
    {
      url: "/org",
      icon: "fa fa-sitemap ",
      name: "Organisasi"
    },
    // {
    //   url: "/eabsen",
    //   icon: "fa fa-clock-o",
    //   name: "eAbsensi Online"
    // },
    {
      url: "https://apps-bkd.sulutprov.com/presensi_nakes/auth/login",
      special: true,
      type: "link",
      icon: "fa fa-clock-o",
      name: "eAbsensi Online"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Aktivitas/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa-building-o",
      name: "Aktivitas Khusus"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Absensi/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa fa-calendar-times-o",
      name: "Lapor Izin/Sakit"
    },
    {
      url: "/person-monthly-history",
      icon: "fa fa-bar-chart",
      name: "Histori Pencapaian"
    },
    {
      url: "/phone-book",
      icon: "fa fa-address-book",
      name: "Daftar Kontak"
    },
    {
      url: "/user",
      icon: "fa fa-lock",
      name: "Profil"
    }
  ];

  // MOST COMMON
  const listMenuStaf = [
    {
      url: "https://apps-bkd.sulutprov.com/app_dashboard/profil/index.php",
      special: true,
      type: "link",
      icon: "fa fa-user-circle-o",
      name: "Biodata"
      
    },
    {
      url: "/my-activity",
      icon: "fa fa-bar-chart-o",
      name: "My Aktivitas"
    },
    // {
    //   type: "link",,
    //   url: "https://sulutprov.web.app/",
    //   icon: "fa fa-star-o",
    //   name: "Penilaian Perilaku"
    // },
    {
      url: "/person-rating",
      icon: "fa fa-star-o",
      name: "Penilaian Perilaku"
    },
    {
      url: "/org",
      icon: "fa fa-sitemap ",
      name: "Organisasi"
    },
    // {
    //   url: "/eabsen",
    //   icon: "fa fa-clock-o",
    //   name: "eAbsensi Online"
    // },
    {
      url: "https://apps-bkd.sulutprov.com/presensi_nakes/auth/login",
      special: true,
      type: "link",
      icon: "fa fa-clock-o",
      name: "eAbsensi Online"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Aktivitas/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa-building-o",
      name: "Aktivitas Khusus"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Absensi/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa fa-calendar-times-o",
      name: "Lapor Izin/Sakit"
    },
    {
      url: "/person-monthly-history",
      icon: "fa fa-bar-chart",
      name: "Histori Pencapaian"
    },
    {
      url: "/phone-book",
      icon: "fa fa-address-book",
      name: "Daftar Kontak"
    },
    {
      url: "/user",
      icon: "fa fa-lock",
      name: "Profil"
    }
  ];

  const listMenuStafWithSub = [
    {
      url: "https://apps-bkd.sulutprov.com/app_dashboard/profil/index.php",
      special: true,
      type: "link",
      icon: "fa fa-user-circle-o",
      name: "Biodata"
      
    },
    {
      url: "/my-activity",
      icon: "fa fa-bar-chart-o",
      name: "My Aktivitas"
    },
    {
      url: "/my-sub-activity",
      icon: "fa fa-align-left",
      name: "Aktivitas Bawahan"
    },
    // {
    //   type: "link",,
    //   url: "https://sulutprov.web.app/",
    //   icon: "fa fa-star-o",
    //   name: "Penilaian Perilaku"
    // },
    {
      url: "/person-rating",
      icon: "fa fa-star-o",
      name: "Penilaian Perilaku"
    },
    {
      url: "/org",
      icon: "fa fa-sitemap ",
      name: "Organisasi"
    },
    // {
    //   url: "/eabsen",
    //   icon: "fa fa-clock-o",
    //   name: "eAbsensi Online"
    // },
    {
      url: "https://apps-bkd.sulutprov.com/presensi_nakes/auth/login",
      special: true,
      type: "link",
      icon: "fa fa-clock-o",
      name: "eAbsensi Online"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Aktivitas/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa-building-o",
      name: "Aktivitas Khusus"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Absensi/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa fa-calendar-times-o",
      name: "Lapor Izin/Sakit"
    },
    {
      url: "/person-monthly-history",
      icon: "fa fa-bar-chart",
      name: "Histori Pencapaian"
    },
    {
      url: "/phone-book",
      icon: "fa fa-address-book",
      name: "Daftar Kontak"
    },
    {
      url: "/user",
      icon: "fa fa-lock",
      name: "Profil"
    }
  ];

  // ALLMENU
  const listMenuAll = [
    {
      url: "https://apps-bkd.sulutprov.com/app_dashboard/profil/index.php",
      special: true,
      type: "link",
      icon: "fa fa-user-circle-o",
      name: "Biodata"
      
    },
    {
      url: "/my-activity",
      icon: "fa fa-bar-chart-o",
      name: "My Aktivitas"
    },
    {
      url: "/my-sub-activity",
      icon: "fa fa-align-left",
      name: "Aktivitas Bawahan"
    },
    // {
    //   type: "link",,
    //   url: "https://sulutprov.web.app/",
    //   icon: "fa fa-star-o",
    //   name: "Penilaian Perilaku"
    // },
    {
      url: "/person-rating",
      icon: "fa fa-star-o",
      name: "Penilaian Perilaku"
    },
    {
      url: "/org",
      icon: "fa fa-sitemap ",
      name: "Organisasi"
    },
    {
      url: "/my-task-assignment",
      icon: "fa fa-sort-amount-asc",
      name: "Penugasan Dari Pimpinan"
    },
    {
      url: "/task-assignment",
      icon: "fa fa-sort-amount-desc",
      name: "Penugasan Ke Bawahan"
    },
    // {
    //   url: "/eabsen",
    //   icon: "fa fa-clock-o",
    //   name: "eAbsensi Online"
    // },
    {
      url: "https://apps-bkd.sulutprov.com/presensi_nakes/auth/login",
      special: true,
      type: "link",
      icon: "fa fa-clock-o",
      name: "eAbsensi Online"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Aktivitas/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa-building-o",
      name: "Aktivitas Khusus"
    },
    {
      url: "https://apps-bkd.sulutprov.com/Absensi/login/login.php",
      special: true,
      type: "link",
      icon: "fa fa fa-calendar-times-o",
      name: "Lapor Izin/Sakit"
    },
    {
      url: "/person-monthly-history",
      icon: "fa fa-bar-chart",
      name: "Histori Pencapaian"
    },
    {
      url: "/phone-book",
      icon: "fa fa-address-book",
      name: "Daftar Kontak"
    },
    {
      url: "/user",
      icon: "fa fa-lock",
      name: "Profil"
    }
  ];

  function handleMenuPerCase() {
    listMenu = listMenuStaf;

    if ($subordinatesData && $subordinatesData.length > 0) {
      listMenu = listMenuStafWithSub;
    }
    // listMenu = listMenuStaf;
    if ($myPersonData.no === "196110221988031010") {
      listMenu = listMenuSekprov;
    }
    if ($myPersonData.no === "197004261990102001") {
      listMenu = listMenuEselonII;
    }
    // if (
    //   $myPersonData.no === "198303242003122003" ||
    //   $myPersonData.no === "199008192010102001" ||
    //   $myPersonData.no === "198204302010011011"
    // ) {
    //   listMenu = listMenuEselon;
    // }
    if ($myPersonData.no === "198511132011021001") {
      // Buka all menu utk bravo
      listMenu = listMenuAll;
    }
    if ($myPersonData.no === "197603232009021003") {
      // Buka all menu utk kris
      listMenu = listMenuAll;
    }
    // if (IS_VALID_USER_ADMIN($myPersonData.id)) {
    //   listMenu = listMenuAll;
    // }
    handleMenuRemainder();
  }

  function handleMenuRemainder() {
    if (IS_VALID_ROOT_MOBILE($user.mobile)) {
      listMenu = listMenuAll;
    }
    if (listMenu.length % 4 > 0) {
      // Insert empty remainder
      const remainder = 4 - (listMenu.length % 4);
      for (let idx = 0; idx < remainder; idx++) {
        listMenu.push(emptyMenu);
      }
    }

    handleMenuUi();
  }

  function handleMenuUi() {
    let newMenu = [];
    listMenu.forEach((v, idx) => {
      newMenu.push(v);
      if ((idx + 1) % 4 === 0) {
        listMenuUi.push(newMenu);
        newMenu = [];
      }
    });
    listMenuUi = listMenuUi;
  }

  onMount(() => {
    handleMenuPerCase();
  });
</script>

<style>
  .menus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .menu-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: center;
    margin-bottom: 18px;
    text-align: center;
  }
  .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 4em;
  }

  .cursor-pointer {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
  }
  .menu-icon {
    font-size: 30px !important;
  }

  .menu-label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }

  .empty-min-width {
    min-width: 60px;
  }
</style>

<div class="menus">
  {#if listMenuUi && listMenuUi.length > 0}
    {#each listMenuUi as menuFourth}
      <div class="menu-row">
        {#each menuFourth as menu}
          {#if menu.type === 'link' && menu.special}
            <a
              href={`${menu.url}?id=${$myPersonData.id}&nip=${$myPersonData.no}`}
              class={'red-text text-darken-4'}
              target="_blank">
              <div class="menu">

                <div class="button-circle-panel hoverable cursor-pointer">
                  <i class={`${menu.icon} menu-icon`} />
                </div>

                <div class="menu-label">{menu.name}</div>
              </div>
            </a>
          {:else if menu.url === 'link'}
            <a href={menu.url} class={'red-text text-darken-4'} target="_blank">
              <div class="menu">

                <div class="button-circle-panel hoverable cursor-pointer">
                  <i class={`${menu.icon} menu-icon`} />
                </div>

                <div class="menu-label">{menu.name}</div>
              </div>
            </a>
          {:else if menu.url}
            <Link
              to={menu.url}
              getProps={() => ({ class: 'red-text text-darken-4' })}>
              <div class="menu">

                <div class="button-circle-panel hoverable cursor-pointer">
                  <i class={`${menu.icon} menu-icon`} />
                </div>

                <div class="menu-label">{menu.name}</div>
              </div>
            </Link>
          {:else}
            <div class="empty-min-width" />
          {/if}
        {/each}
      </div>
    {/each}
  {/if}
</div>
