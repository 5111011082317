<script>
  export let desc = "";
  export let color = "white red-text";
  export let isShowClose = false;
  export let onRefresh = null;
  let isHideBox = false;
</script>

<style>
  .tips-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 14px;
  }
</style>

{#if !isHideBox && desc}
  <div class={`card-panel m-0 p-v-10 p-h-0 ${color}`}>
    <div class="container">
      <div class="tips-content">
        {#if isShowClose === true}
          <div class="m-r-8">

            <span
              class="cursor-pointer"
              on:click={() => {
                isHideBox = true;
              }}>
              <i class="fa fa-times" />
            </span>

          </div>
        {/if}
        <div class="full-width">{desc}</div>
        {#if onRefresh && typeof onRefresh === 'function'}
          <div
            on:click={onRefresh}
            class="white blue-text cursor-pointer m-l-8">
            <i class="fa fa-refresh fa-2x" />

          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}
