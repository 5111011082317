<script>
  import { Link } from "svelte-routing";

  import { datesNav } from "../../stores/myActivity/myActivityDates";
  import { loading } from "../../stores/myActivity/myActivityList";

  import { HeaderNav, TipsBox } from "../../components/common";
  import { DatesBar, ActivityTopFilter, MyActivityItem } from "./components";

  export let location;
  location;

  export let date;
  export let filterName;

  export let getData;
  export let filteredListData;
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

<HeaderNav
  headerSize="s"
  headerName="My Aktivitas"
  backURL={`/home`}
  onRefresh={() => {
    getData();
  }} />

<DatesBar />
{#if $datesNav.current.disable}
  <TipsBox desc={$datesNav.current.disableMsg} color="red" />
{:else if $datesNav.current.warning}
  <TipsBox desc={$datesNav.current.warningMsg} color="red" />
{/if}

<div class="root-container section no-pad-bot">
  <div class="container">
    <ActivityTopFilter {date} {filterName} />
    <ul class="collection p-0">
      {#if filteredListData && filteredListData.length > 0}
        {#each filteredListData as activity}
          <MyActivityItem data={activity} {filterName} />
        {/each}
      {:else if !$loading && filteredListData && filteredListData.length < 1}
        <li class="collection-item p-v-14 p-h-14">
          <div class="font-16 center-align">Tidak ada data</div>
        </li>
      {/if}
    </ul>
  </div>
</div>

{#if $datesNav.current.warning}
  <span />
{:else if $datesNav.current.disable}
  <span />
{:else}
  <div class="fixed-action-btn">
    <Link
      to={`/my-activity/by-date/${date}/filter/${filterName}/create`}
      getProps={() => ({ class: 'btn-floating btn-large red' })}>
      <i class="large material-icons">add</i>
    </Link>
  </div>
{/if}
