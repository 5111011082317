import { writable, get } from "svelte/store";
import Fuse from "fuse.js";

import { restGetActivityTypeAllCache } from "../../api/activityType";

// import { warningNotif } from "../../helpers/notification";

export let loading = writable(true);
export let error = writable("");

export let allListData = writable([]);
export let filteredListData = writable([]);

// FORMDATA
export let inputSearchKeyword = writable("");

let fuseClient;

function updateFuse(allData) {
  fuseClient = new Fuse(allData, {
    tokenize: true,
    matchAllTokens: true,
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["name"],
  });
}

export function initialState() {
  loading.set(true);
  error.set("");
  allListData.set([]);
  filteredListData.set([]);

  inputSearchKeyword.set("");
}

export async function getActivityTypeAllCache() {
  loading.set(true);
  allListData.set([]);
  try {
    const resp = await restGetActivityTypeAllCache();
    if (resp && resp.data) {
      console.log(resp.data);
      allListData.set(resp.data);
      updateFuse([...resp.data]);
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
  }

  loading.set(false);
}

export function searchActivityType() {
  const curAllListData = get(allListData);

  let searchKeyword = get(inputSearchKeyword);
  searchKeyword = searchKeyword.trim();

  if (searchKeyword.length < 1) {
    curAllListData.splice(0, 20);
    filteredListData.set(curAllListData);
    return;
  }

  if (!fuseClient) {
    return;
  }

  loading.set(true);
  const searchResult = fuseClient.search(searchKeyword);
  filteredListData.set(searchResult);
  loading.set(false);
}
