export function formatNumber(input) {
  if (typeof input === 'undefined' || typeof input === 'object') {
    return '';
  }
  if (typeof input === 'string') {
    input = Number(input.trim());
  }

  return new Intl.NumberFormat('id-ID').format(input);
}

export function formatCurrency(input) {
  if (!input) {
    return `Rp0,-`;
  }
  return `Rp${formatNumber(input)},-`;
}

export function formatMobile(input) {
  let formattedNumber = String(input);
  if (formattedNumber.startsWith('+628')) {
    return (formattedNumber = formattedNumber.replace('+628', '08'));
  }
  if (
    typeof input === 'undefined' ||
    typeof input === 'object' ||
    typeof input === 'number'
  ) {
    return '';
  }
  return formattedNumber;
}
