<script>
  import { Router, Route } from "svelte-routing";
  import Page404 from "../common/Page404.svelte";
  import SubordinateList from "./SubordinateList.svelte";
  import TaskList from "./TaskList.svelte";
  import TaskCreate from "./TaskCreate.svelte";
  import TaskDetail from "./TaskDetail.svelte";
</script>

<Router>
  <Route path="/" component={SubordinateList} />
  <Route
    path="/subordinate/:subordinateId/task/:filterName"
    component={TaskList} />
    <Route
    path="/subordinate/:subordinateId/task/:filterName/create"
    component={TaskCreate} />
  <Route
    path="/subordinate/:subordinateId/task/:filterName/detail/:taskId"
    component={TaskDetail} />
  <Route component={Page404} />
</Router>
