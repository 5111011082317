import fetchApi from "./fetchApi";

// GET
export function restGetRatingPersonListByRater({
  raterPersonId,
  closed,
  limit
}) {
  const path = `/rating-person/list-by-rater/${raterPersonId}/closed/${closed}/limit/${limit}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetRatingPersonDetail({ ratingPersonId }) {
  const path = `/rating-person/detail/${ratingPersonId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
export function restPostRatingPersonUpdate({ ratingPersonId, formData }) {
  const path = `/rating-person/update/${ratingPersonId}`;
  return fetchApi(path, {
    method: "POST",
    body: formData
  });
}
