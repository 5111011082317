<script>
  import { Link } from "svelte-routing";
  import { StatusLabel, Img } from "../../../components/common";

  export let data = {};

  const currentDateStr = data.startDate.toString().substring(0, 8);
  const startDateMoment = window.moment(data.startDate, "YYYYMMDDHHmm");
  const endDateMoment = window.moment(data.endDate, "YYYYMMDDHHmm");

  const startTimeStr = startDateMoment.format("HH:mm");
  const endTimeStr = endDateMoment.format("HH:mm");
  const durationStr = endDateMoment.from(startDateMoment, true);
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
</style>

{#if data && data.id}
  <li class="collection-item p-h-14 p-v-14">
    <Link
      to={`/my-sub-activity/person/${data.personId}/activity/${data.id}`}
      getProps={() => ({ class: 'cursor-pointer' })}>
      <div class="person-box">
        <div>
          <Img
            className="circle img-width"
            alt="dari"
            personId={data.personId} />
        </div>
        <div class="full-width m-l-14 ">
          <div class="font-10 grey-text">
            <div>{startDateMoment.format('dddd, DD MMMM YYYY')}</div>
            <div>
              <i class="fa fa-clock-o" />
              {startTimeStr}-{endTimeStr} (
              <span>{durationStr}</span>
              )
            </div>

          </div>
          <div class="font-10 grey-text">
            <i class="fa fa-user" />
            {data.personName}
          </div>
          <div class="font-10 grey-text">
            <i class="fa fa-circle icon-label-text" />
            {data.activityTypePoint} x {data.activityTypeMultiplier}
            <span class="grey-text">
              {data.activityTypeUnit ? `(${data.activityTypeUnit})` : ''}
            </span>
            = {data.activityTypePointTotal} poin
          </div>
          <div class="font-14 black-text">{data.activityTypeName}</div>
          <div class="font-12 grey-text">{data.desc}</div>
        </div>
        <div>
          <StatusLabel type="activity" status={data.status} />
        </div>
      </div>
    </Link>
  </li>
{/if}
