<script>
  import { Link } from "svelte-routing";

  export let data;

  import { formatCurrency } from "../../../helpers/number.js";
</script>

<style>
  .point-item {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 14px;
  }
  .point-item:last-child {
    border-bottom: none;
  }
  .point-item-flex {
    display: flex;
    justify-content: space-between;
  }
</style>

<div class="point-item">

  <Link to={`/person-monthly-history/detail/${data.month}`}>
    <div class="point-item-flex">
      <div>
        <b>
          {data.month ? window
                .moment(data.month, 'YYYYMM')
                .format('MMMM YYYY ') : ''}
        </b>
      </div>
      <div>{formatCurrency(data.benefitAmount)}</div>
    </div>
  </Link>

</div>
