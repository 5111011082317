<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  import { datesNav } from "../../stores/myActivity/myActivityDates";
  import {
    loading,
    error,
    selectedSupervisor,
    selectedActivityType,
    showModal,
    inputActivityTypeMultiplier,
    inputDesc,
    inputStartTime,
    inputEndTime,
    validateAndCreateActivity,
    setInitialStartTime,
    setEndTime
  } from "../../stores/myActivity/createForm";

  import { HeaderNav, TipsBox } from "../../components/common";
  import { ModalTimePicker } from "../../components/modals";
  import { PersonBox, DateBarDetail } from "./components";

  export let location;
  location;

  export let date;
  export let filterName;

  export let getData;

  function onSubmitCreate() {
    validateAndCreateActivity({ date, filterName, getData });
  }

  onMount(() => {
    setInitialStartTime();
    if ($selectedActivityType && $selectedActivityType.id) {
      setEndTime(date);
    }
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

{#if $showModal === 'timePicker'}
  <ModalTimePicker
    onClose={() => showModal.set(null)}
    onOk={v => {
      inputStartTime.set(v);
      showModal.set(null);
      setEndTime(date);
    }}
    value={$inputStartTime} />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Aktivitas Baru"
  backURL={`/my-activity/by-date/${date}/filter/${filterName}`} />

<div class="top-date-fixed">
  <DateBarDetail startDate={date} />
</div>

{#if $datesNav.current.disable}
  <TipsBox desc={$datesNav.current.disableMsg} color="red" />
{:else if $datesNav.current.warning}
  <TipsBox desc={$datesNav.current.warningMsg} color="red" />
{:else}
  <div class="root-container section no-pad-bot">
    <div class="container">
      <div class="card-panel p-h-10 p-v-14 m-t-0">
        <!-- FORM -->
        <form on:submit|preventDefault={onSubmitCreate}>
          <div class="row m-b-0">
            <div class="input-field col s12">
              <div class="m-t-8">
                {#if !$selectedSupervisor || !$selectedSupervisor.id}
                  <Link
                    to={`/my-activity/by-date/${date}/filter/${filterName}/create/select-supervisor`}
                    getProps={() => ({ class: 'btn btn-small m-t-8 m-b-8' })}>
                    Pilih Atasan
                  </Link>
                {:else}
                  <Link
                    to={`/my-activity/by-date/${date}/filter/${filterName}/create/select-supervisor`}>
                    <PersonBox data={$selectedSupervisor} />
                  </Link>
                {/if}
              </div>
              <label class="active" for="activity">Melapor Ke</label>
            </div>
          </div>
          <div class="row m-b-0">
            <div class="input-field col s12">
              {#if !$selectedActivityType || !$selectedActivityType.id}
                <Link
                  to={`/my-activity/by-date/${date}/filter/${filterName}/create/search-activity`}
                  getProps={() => ({ class: 'btn btn-small m-t-8 m-b-8' })}>
                  Pilih Aktivitas
                </Link>
              {:else}
                <Link
                  to={`/my-activity/by-date/${date}/filter/${filterName}/create/search-activity`}>
                  <div class="red-text text-darken-4 m-t-8 m-b-8">
                    {$selectedActivityType.name} ({$selectedActivityType.point}
                    poin/{$selectedActivityType.unit})
                  </div>
                </Link>
              {/if}
              <label class="active" for="activity">Aktivitas</label>
            </div>
          </div>
          <div class="row m-b-0">
            <div class="input-field col s12">
              <span
                on:click={() => {
                  showModal.set('timePicker');
                }}
                class="cursor-pointer red-text text-darken-4">
                {$inputStartTime}
              </span>
              <label class="active" for="startTime">Jam Mulai</label>
            </div>
          </div>
          {#if $inputEndTime}
            <div class="row m-b-0">
              <div class="input-field col s12">
                <div>{$inputEndTime}</div>
                <label class="active" for="startTime">Jam Selesai</label>
              </div>
            </div>
          {/if}
          {#if $selectedActivityType}
            <div class="row m-b-0">
              <div class="input-field col s12">
                <input
                  value={$inputActivityTypeMultiplier}
                  on:change={e => {
                    if (e.target.value) {
                      if (Number(e.target.value) > 1) {
                        inputActivityTypeMultiplier.set(Number(e.target.value));
                      } else {
                        inputActivityTypeMultiplier.set(1);
                      }
                      setEndTime(date);
                    } else {
                      inputActivityTypeMultiplier.set(1);
                      setEndTime(date);
                    }
                  }}
                  id="activityMultiplier"
                  name="activityMultiplier"
                  type="number"
                  min="1"
                  class="validate"
                  style="width: 20%;" />
                <span>
                  <button type="button" class="btn-small">
                    <i class="fa fa-calculator" />
                  </button>
                  {$selectedActivityType.point} x {$inputActivityTypeMultiplier}
                  <span class="grey-text">
                    {$selectedActivityType.unit ? `(${$selectedActivityType.unit})` : ''}
                  </span>
                  = {$selectedActivityType.point * $inputActivityTypeMultiplier}
                  poin
                </span>
                <label class="active" for="activityMultiplier">Jumlah</label>
              </div>
            </div>
          {/if}
          <div class="row m-b-0">
            <div class="input-field col s12">
              <textarea
                value={$inputDesc}
                on:change={e => {
                  inputDesc.set(e.target.value);
                }}
                id="desc"
                class="materialize-textarea" />
              <label class="active" for="desc">Keterangan</label>
            </div>
          </div>
          <div class="row m-b-0">
            <div class="col s12">
              <div class="red-text m-b-4">{$error}</div>
              <button type="submit" class="btn full-width m-b-20">
                Buat Aktivitas
              </button>
            </div>
          </div>
        </form>
        <!-- END FORM -->
      </div>
    </div>
  </div>
{/if}
