import fetchApi from "./fetchApi";

// GET
export function restGetOrgCache({ companyId, orgId }) {
  const path = `/org/cache/${companyId}/${orgId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetOrgJobPersonCache({ companyId, personId }) {
  const path = `/org/jobsPersonCache/${companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetSubordinatesPersonCache({ companyId, personId }) {
  const path = `/org/subordinatesPersonCache/${companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetSupervisorsPersonCache({ companyId, personId }) {
  const path = `/org/supervisorsPersonCache/${companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetOrgStructureCache({ companyId, orgId }) {
  const path = `/org/structureCache/${companyId}/${orgId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetOrgStructureChildListCache({ companyId, parentId }) {
  const path = `/org/structureChildListCache/${companyId}/${parentId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
