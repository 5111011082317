<script>
  import { onMount } from "svelte";
  import { Link, navigate } from "svelte-routing";
  import { user as myUserData } from "../../../stores/auth/myUser";
  import { data as myPersonData } from "../../../stores/person/myPerson";
  import { data as myMainOrgData } from "../../../stores/org/myMainOrg";
  import { data as myJobsData } from "../../../stores/org/myJobs";

  import {
    loading as myCurrentPointLoading,
    data as myCurrentPointData,
    getCurrentPointPersonCacheWithTimeout
  } from "../../../stores/personPoint/myCurrentPoint";

  import { formatCurrency, formatNumber } from "../../../helpers/number.js";
  import { Img } from "../../../components/common";
  import ModalJobs from "./ModalJobs.svelte";

  const NO_PHOTO_URL = `/images/nophoto.png`;

  let showModal;
</script>

<style>
  .basic {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    font-size: 12px;
  }
  .info {
    margin-left: 16px;
    font-size: 12px;
    width: 80%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
  }
  .photo-container {
    width: 20%;
    min-height: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  :global(.home-avatar) {
    height: 6em;
    width: 6em;
  }
  .label {
    min-width: 14px;
  }

  .basic.card-panel {
    margin-bottom: 0px;
  }

  .header.card-panel {
    margin-top: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>

{#if $myCurrentPointLoading}
  <div class="loader-block-item" />
{/if}

{#if showModal === 'jobs'}
  <ModalJobs onClose={() => (showModal = undefined)} />
{/if}

<div class="profile-sum">
  <div class="basic card-panel">
    <div class="photo-container">
      <Link to="/user">
        <Img
          className="home-avatar circle"
          personId={$myPersonData.id}
          alt={$myPersonData.name} />
      </Link>
    </div>
    <div class="info">
      <div class="field">
        <i class="label fa fa-building" />
        <span class="mainOrgName">{$myMainOrgData.orgName || ''}</span>
      </div>
      <div class="field">
        <i class="label fa fa-user" />
        <span class="name">{$myPersonData.name}</span>
      </div>
      <div class="field">
        <i class="label fa fa-id-card" />
        <span class="no">{$myPersonData.no}</span>
      </div>
      <div class="field">
        <i class="label fa fa-phone" />
        <span class="mobile">
          {$myUserData.mobile ? $myUserData.mobile.replace('+628', '08') : ''}
        </span>
      </div>
      <div class="field" title="Tunjangan Total">
        <i class="label fa fa-money" />
        <span class="no">{formatCurrency($myPersonData.benefitTotal)}</span>
      </div>
      {#if $myJobsData && $myJobsData.length && $myJobsData.length > 1}
        <div class="field">
          <i class="label fa fa-briefcase" />
          <span
            class="myJobsData red-text text-darken-4 cursor-pointer"
            on:click={() => {
              showModal = 'jobs';
            }}>
            {$myJobsData && $myJobsData.length ? `${$myJobsData.length} ` : `0 `}
            Jabatan
          </span>
        </div>
      {:else if $myJobsData && $myJobsData.length > 0}
        <div class="field">
          <i class="label fa fa-briefcase" />
          <span class="myJobsData">{$myJobsData[0].jobName}</span>
        </div>
      {/if}
    </div>
  </div>
  {#if $myCurrentPointData.lastUpdateDisplay}
    <div class="header card-panel">
      <div class="header-row">
        <div class="point">
          <div class="font-12 black-text">
            <b>
              {formatNumber($myCurrentPointData.activityPoint + $myCurrentPointData.activityPointBonus)}/{formatNumber($myCurrentPointData.activityPointTarget)}
            </b>
          </div>
          <div class="font-12 grey-text">
            Poin/Target {$myCurrentPointData.monthDisplay || ''}
          </div>
          <div class="font-12 grey-text">
            Update terakhir {$myCurrentPointData.lastUpdateDisplay || ''}
          </div>
        </div>
        <div class="refresh-point">
          <div
            on:click={getCurrentPointPersonCacheWithTimeout}
            class="blue-text cursor-pointer">
            <i class="fa fa-refresh font-20" />
          </div>

        </div>
      </div>
    </div>
  {:else}
    <div class="header card-panel">
      <div class="header-row">
        <div class="point">
          <div class="font-12 black-text">
            Belum ada data point, silahkan klik refresh
          </div>
        </div>
        <div class="refresh-point">
          <div
            on:click={getCurrentPointPersonCacheWithTimeout}
            class="blue-text cursor-pointer">
            <i class="fa fa-refresh font-20" />
          </div>

        </div>
      </div>
    </div>
  {/if}
</div>
