import { tick } from "svelte";
import { writable, get } from "svelte/store";
import Fuse from "fuse.js";

import { restGetTaskAssignmentListPersonBySupervisor } from "../../api/taskAssignment";
import { data as subordinatesData } from "../org/mySubordinates";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

export let listData = writable([]);
export let filteredListData = writable([]);

// FORMDATA
export let inputSearchKeyword = writable("");

export async function getTaskAssignmentListPersonBySupervisor() {
  error.set("");
  loading.set(true);
  listData.set([]);
  inputSearchKeyword.set("");
  try {
    const resp = await restGetTaskAssignmentListPersonBySupervisor({
      supervisorId: window.myPersonId
    });
    transformSetData(resp);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}

function transformSetData(resp) {
  const newListData = [];
  get(subordinatesData).forEach(s => {
    const newData = {
      personId: s.personId,
      personName: s.personName,
      personNo: s.personNo,
      counts: {}
    };
    if (resp.data && resp.data[s.personId]) {
      newData.counts = resp.data[s.personId].counts;
    }
    newListData.push(newData);
  });
  // kalo sudah pindah kantor, bawahan lama tetap muncul
  Object.keys(resp.data).forEach(personId => {
    if (!newListData.find(n => n.personId === personId)) {
      const selectedPerson = subordinatesData.find(
        s => s.personId === personId
      );
      const newData = {
        personId: selectedPerson.personId,
        personName: selectedPerson.personName,
        personNo: selectedPerson.personNo,

        counts: resp.data[personId].counts
      };
      newListData.push(newData);
    }
  });

  loading.set(false);
  listData.set(newListData || []);
  filteredListData.set(newListData || []);
  updateFuse([...newListData]);
}

let fuseClient;

function updateFuse(allData) {
  fuseClient = new Fuse(allData, {
    tokenize: true,
    matchAllTokens: true,
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["personName"]
  });
}

export async function searchPerson() {
  const allListData = get(listData);
  let searchKeyword = get(inputSearchKeyword);
  searchKeyword = searchKeyword.trim();

  if (searchKeyword.length < 1) {
    loading.set(true);
    filteredListData.set([]);
    await tick();
    filteredListData.set(allListData);
    loading.set(false);
    return;
  }

  if (!fuseClient) {
    return;
  }
  filteredListData.set([]);
  loading.set(true);
  await tick();
  const searchResult = fuseClient.search(searchKeyword);
  filteredListData.set(searchResult);
  loading.set(false);
}
