import fetchApi from "./fetchApi";

// GET
export function restGetCompanyDetailCache({ companyId }) {
  const path = `/company/detail/cache/${companyId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
