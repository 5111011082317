<script>
  import { Link } from "svelte-routing";
  import {
    loading,
    error,
    login,
    inputMobile,
    inputPassword
  } from "../../stores/auth/loginForm";

  const PEMPROV_LOGO_URL = `/images/pemprov.png`;
</script>

<style>
  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logo-dimension {
    height: 1em;
    margin-right: 4px;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div class="center-container">
  <h3 class="center-align">
    <img
      class="logo-dimension"
      src={PEMPROV_LOGO_URL}
      alt="pemerintah provinsi sulawesi Utara" />
    e-Kinerja
    <br />
    <span class="font-18">Pemerintah Provinsi Sulawesi Utara</span>
  </h3>

  <div class="login-box">
    <div class="row">
      <form on:submit|preventDefault={login} class="col s12">
        <div class="row">
          <div class="input-field col s12">
            <input
              value={$inputMobile}
              on:change={e => {
                inputMobile.set(e.target.value);
              }}
              id="mobile"
              name="mobile"
              type="tel"
              autocomplete="true"
              class="validate" />
            <label class="active" for="mobile">No Handphone</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input
              value={$inputPassword}
              on:change={e => {
                inputPassword.set(e.target.value);
              }}
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              class="validate" />
            <label class="active" for="password">Password</label>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="red-text m-b-4">{$error}</div>
            <button type="submit" class="btn red darken-4 m-b-20">Login</button>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <Link to={`/otp`}>
              <span class="right red-text text-darken-4">Lupa Password?</span>
            </Link>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col s12">
            <Link to={`/otp`}>
              <span class="right red-text text-darken-4">Daftar Baru</span>
            </Link>
          </div>
        </div> -->
      </form>
    </div>
  </div>
</div>
