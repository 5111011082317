<script>
  import { Router, Route } from "svelte-routing";
  import Page404 from "../common/Page404.svelte";

  import Home from "./Home.svelte";
  import ChangePassword from "./ChangePassword.svelte";
  // import ChangePhoto from "./ChangePhoto.svelte";
  // import ChangeName from "./ChangeName.svelte";
</script>

<Router>
  <Route path="/" component={Home} />
  <Route path="/change-password" component={ChangePassword} />
  <!-- <Route path="/change-photo" component={ChangePhoto} />
  <Route path="/change-name" component={ChangeName} /> -->
  <Route component={Page404} />
</Router>
