<script>
  import { onMount } from "svelte";
  import {
    loading,
    isDoneInitAuth,
    initCheckAuth,
    user
  } from "./stores/auth/myUser";
  import { isOtpSentConfirmed } from "./stores/auth/otpForm";

  import { data as myCompanyData } from "./stores/company/myCompany";

  import GlobalStyle from "./GlobalStyle.svelte";

  import UnauthLayout from "./layouts/UnauthLayout.svelte";
  import AuthLayout from "./layouts/AuthLayout.svelte";

  import NewPassword from "./pages/otp/NewPassword.svelte";

  onMount(() => {
    initCheckAuth();
  });
</script>

<GlobalStyle />

{#if $loading}
  <div class="loader-block" />
{/if}

{#if !$isDoneInitAuth}
  <div class="loader-block" />
{:else if $isOtpSentConfirmed}
  <div class="root-container section no-pad-bot">
    <div class="container">
      <NewPassword />
    </div>
  </div>
{:else if $user && $user.email}
  <AuthLayout />
{:else}
  <UnauthLayout />
{/if}
