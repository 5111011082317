<script>
  import { HeaderNav, ErrorBox } from "../../components/common";
  import { ProfileSum, HomeMenu, CarouselList } from "./components";

  export let location;

  location;
</script>

<style>
  .profile-card {
    margin-bottom: 15px;
  }
</style>

<HeaderNav headerSize="s" headerName={'e-Kinerja'} backURL={''} isHome />

<div class="root-container section no-pad-bot">
  <div class="container container-home">
    <div class="profile-card">
      <ProfileSum />
    </div>
    <CarouselList />
    <HomeMenu />
  </div>
</div>
