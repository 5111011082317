<script>
  export let fontClass = "";
  export let value = 0;
  export let onClickCircle = value => {
    console.log("onClickCircle", value);
  };
  const allCircle = new Array(5);
  let activeCircle = 0;

  function handleClick(input) {
    value = input;
    onClickCircle(input * 25);
  }
  $: {
    if (value) {
      const divide = Number(value) > 0 ? value / 25 : 0;
      activeCircle = divide;
    } else {
      activeCircle = 0;
    }
  }
</script>

{#each allCircle as circle, index}
  {#if index <= activeCircle}
    <i
      class={`fa fa-circle orange-text cursor-pointer ${fontClass}`}
      on:click={() => {
        handleClick(index);
      }} />
  {:else}
    <i
      class={`fa fa-circle-o orange-text cursor-pointer ${fontClass}`}
      on:click={() => {
        handleClick(index);
      }} />
  {/if}
{/each}
