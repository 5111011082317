<script>
  export let desc = "";

  export let color = "blue";
  export let isShowClose = true;
  export let onRefresh = null;
  let isHideBox = false;
</script>

<style>
  .tips-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>

{#if !isHideBox && desc}
  <div class={`card-panel white-text m-0 p-v-10 p-h-0 ${color}`}>
    <div class="container">
      <div class="tips-content">
        {#if isShowClose === true}
          <div class="m-r-8">

            <span
              class="cursor-pointer"
              on:click={() => {
                isHideBox = true;
              }}>
              <i class="fa fa-times" />
            </span>

          </div>
        {/if}
        <div class="full-width">{desc}</div>
        {#if onRefresh && typeof onRefresh === 'function'}
          <div class="m-l-8">

            <span class="cursor-pointer" on:click={onRefresh}>
              <i class="fa fa-refresh" />
            </span>

          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}
