<script>
  import { Link } from "svelte-routing";
  import TipsBox from "./TipsBox.svelte";
  export let headerName;
  export let headerSize = "l";
  export let backURL;
  export let subtitle = "";
  export let subtitleColor = "teal";
  export let isHome = false;
  export let onRefresh = null;

  const PEMPROV_LOGO_URL = `/images/pemprov.png`;
</script>

<style>
  .navbar-fixed {
    position: sticky;
    top: 0px;
    height: 56px;
    z-index: 10;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
  .navbar-flex {
    height: 56px;
    margin: 0 auto;
    height: 56px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
  }
  .fa-arrow-left {
    height: 16px;
  }
  /* .header-size-l {
    font-size: 2.1rem;
  }
  .header-size-m {
    font-size: 1.6rem;
  } */
  .header-size-l {
    font-size: 1.2rem;
  }
  .header-size-m {
    font-size: 1.2rem;
  }
  .header-size-s {
    font-size: 1.2rem;
  }
  .header-size-xs {
    font-size: 0.8rem;
  }
  .title-flex {
    display: flex;
    align-items: center;
    margin-left: -1.5em;
  }
  .brand-logo {
    color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .logo-dimension {
    height: 3em;
    margin-right: 4px;
  }
  :global(.back-button) {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 45px;
  }
  .refresh-box {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 45px;
  }
</style>

<div class="navbar-fixed">
  <div class="navbar-flex red darken-4">
    {#if backURL === 'BACK'}
      <div
        on:click={() => {
          return window.history.back();
        }}
        class="cursor-pointer back-button">

        <i class="fa fa-arrow-left white-text" />

      </div>
    {:else if backURL}
      <Link
        to={backURL}
        getProps={() => ({ class: 'cursor-pointer back-button' })}>

        <i class="fa fa-arrow-left white-text" />

      </Link>
    {:else}
      <div />
    {/if}
    <div class="title-flex">

      <img
        class="logo-dimension"
        src={PEMPROV_LOGO_URL}
        alt="pemerintah provinsi sulawesi Utara" />
      <span class={`brand-logo center header-size-${headerSize}`}>
        {headerName}
        {#if isHome}
          <br />
          <span class="header-size-xs">Pemerintah Provinsi Sulawesi Utara</span>
        {/if}
      </span>

    </div>

    {#if onRefresh && typeof onRefresh === 'function'}
      <div class="refresh-box cursor-pointer" on:click={onRefresh}>
        <i class="fa fa-refresh white-text" />
      </div>
    {:else}
      <div />
    {/if}
  </div>
</div>
<TipsBox desc={subtitle} color={subtitleColor} />
