import { writable } from "svelte/store";

import { restGetPersonDetailCache } from "../../api/person";

// import { warningNotif } from "../../helpers/notification";

export let loading = writable(true);
export let error = writable("");
export let doneAllInitData = writable(false);

export let data = writable({});

export function initialState() {
  loading.set(true);
  error.set("");
  doneAllInitData.set(false);
  data.set({});
}

export async function getPersonCache() {
  loading.set(true);
  try {
    const resp = await restGetPersonDetailCache({
      companyId: window.companyId,
      personId: window.myPersonId
    });
    if (resp && resp.data) {
      resp.data.displayMobile = "";
      if (resp.data && resp.data.mobile) {
        resp.data.displayMobile = resp.data.mobile.replace("+628", "08");
      }
      data.set(resp.data);
    }
    doneAllInitData.set(true);
  } catch (err) {
    console.warn(err);
    error.set(err);
    // warningNotif("Pegawai tidak tidak ditemukan");
    doneAllInitData.set(false);
  }

  loading.set(false);
}
