<script>
  import { data as mySupervisorsData } from "../../stores/org/mySupervisors";
  import { data as mySubordinatesData } from "../../stores/org/mySubordinates";
  import { data as myPersonData } from "../../stores/person/myPerson";

  import { HeaderNav } from "../../components/common";
  import { PhoneBookItem } from "./components";
</script>

<style>
  .root-container {
    padding-top: 0px;
  }
  .container {
    margin: 0px;
    width: 100% !important;
  }
  .collection {
    margin-top: -1px;
  }
</style>

<HeaderNav headerSize="s" headerName="Daftar Kontak" backURL={`/home`} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <ul class="collection p-0">
      {#if $mySupervisorsData && $mySupervisorsData.length > 0}
        {#each $mySupervisorsData as data}
          {#if data.ownerMobile && $myPersonData.id !== data.ownerId}
            <PhoneBookItem {data} />
          {/if}
        {/each}
      {/if}
      {#if $mySubordinatesData && $mySubordinatesData.length > 0}
        {#each $mySubordinatesData as data}
          {#if data.ownerMobile && $myPersonData.id !== data.ownerId}
            <PhoneBookItem {data} />
          {/if}
        {/each}
      {/if}
    </ul>
  </div>
</div>
