<script>
  import { Router, Route } from "svelte-routing";
  import Page404 from "../common/Page404.svelte";

  import HistoryList from "./HistoryList.svelte";
  import HistoryDetail from "./HistoryDetail.svelte";
</script>

<Router>
  <Route path="/" component={HistoryList} />
  <Route path="/detail/:selectedMonth" component={HistoryDetail} />
  <Route component={Page404} />
</Router>
