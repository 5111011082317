<script>
  import { childExpand, childHide } from "../../../stores/org/myOrgStructure";

  import OrgChildList from './OrgChildList.svelte';
  import OrgPerson from "./OrgPerson.svelte";

  export let data = {};
</script>

{#if data && data.id}
  <li>
    {#if data.hasChild}
      <!-- CLICKABLE AREA -->
      <span
        on:click={() => {
          if (data.childExpand) {
            return childHide(data.clientPath, data.id);
          }
          if (!data.childExpand) {
            return childExpand(data.clientPath, data.id);
          }
        }}
        class="cursor-pointer button-expand button-parent">
        &nbsp;&nbsp;
      </span>
      <!-- CLICKABLE AREA -->
    {/if}

    <div
      class={`${data.hasChild && data.childExpand ? 'tree-icon active' : 'tree-icon'} ${!data.hasChild ? 'no-icon' : ''}`} />
    <div class="tree-icon " />
    <label class="tree_label ">
      <OrgPerson {data} />
    </label>
    {#if data.children && Object.keys(data.children).length > 0}
      <OrgChildList children={data.children} />
    {/if}
  </li>
{/if}
