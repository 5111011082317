<script>
  import { ModalAccept, ModalReject, ModalRevision } from "../modals";

  import { JOB_ASSIGNMENT_STATUS } from "../../../helpers/constants";

  import { showModal as showModalAccept } from "../../../stores/taskAssignmentSub/acceptForm";
  import { showModal as showModalReject } from "../../../stores/taskAssignmentSub/rejectForm";
  import { showModal as showModalRevision } from "../../../stores/taskAssignmentSub/revisionForm";

  export let detailData;
  export let filterName;
</script>

<style>
  .action-box {
    display: flex;
    justify-content: space-between;
  }
</style>

{#if $showModalAccept}
  <ModalAccept {detailData} {filterName} />
{/if}
{#if $showModalRevision}
  <ModalRevision {detailData} {filterName} />
{/if}
{#if $showModalReject}
  <ModalReject {detailData} {filterName} />
{/if}

{#if detailData.status === JOB_ASSIGNMENT_STATUS.NEED_VERIFY && detailData.progress > 99}
  <div class="action-box p-b-14 p-h-14">
    <div
      on:click={() => {
        showModalReject.set(true);
      }}
      class="btn btn-small red white-text">
      Tolak
    </div>

    <div
      on:click={() => {
        showModalRevision.set(true);
      }}
      class="btn btn-small orange white-text">
      Revisi
    </div>

    <div
      on:click={() => {
        showModalAccept.set(true);
      }}
      class="btn btn-small blue white-text">
      Terima
    </div>

  </div>
{/if}
