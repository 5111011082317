<script>
  import { Router, Route } from "svelte-routing";
  import Page404 from "../common/Page404.svelte";
  import SupervisorList from "./SupervisorList.svelte";
  import TaskList from "./TaskList.svelte";
  import TaskDetail from "./TaskDetail.svelte";
</script>

<Router>
  <Route path="/" component={SupervisorList} />
  <Route
    path="/supervisor/:supervisorId/task/:filterName"
    component={TaskList} />
  <Route
    path="/supervisor/:supervisorId/task/:filterName/detail/:taskId"
    component={TaskDetail} />
  <Route component={Page404} />
</Router>
