<script>
  import { onMount } from "svelte";
  import { Link, navigate } from "svelte-routing";

  import { loading, data } from "../../stores/org/mySupervisors";
  import { selectedSupervisor } from "../../stores/myActivity/createForm";

  import { HeaderNav } from "../../components/common";
  import { PersonBox } from "./components";

  export let location;
  location;

  export let date;
  export let filterName;

  let backUrl = `/my-activity/by-date/${date}/filter/${filterName}/create`;

  function onSelect(selectedData) {
    selectedSupervisor.set(selectedData);
    navigate(backUrl);
  }
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
  .collection-item {
    min-height: 70px !important;
  }
</style>

<HeaderNav headerSize="s" headerName="Pilih Atasan" backURL={backUrl} />

{#if $loading}
  <div class="loader-block" />
{:else}
  <div class="root-container section no-pad-bot">
    <div class="container">
      <ul class="collection p-0 m-t-0">
        {#if $data && $data.length > 0}
          {#each $data as sup}
            <li
              class="collection-item p-v-4 p-h-1 cursor-pointer"
              on:click|preventDefault={() => {
                onSelect(sup);
              }}>
              <PersonBox data={sup} />
            </li>
          {/each}
        {:else}
          <li class="collection-item p-v-14 p-h-14">
            <div class="font-16 center-align">Tidak ada supervisor</div>
          </li>
        {/if}
      </ul>

    </div>
  </div>
{/if}
