<script>
  import { onMount } from "svelte";

  import { Img, Star } from "../../../components/common";

  export let data = {};
  let monthString = "";

  onMount(() => {
    monthString = window.moment(data.month, "YYYYMM").format("MMMM YYYY");
  });
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
</style>

<div class="p-v-4 p-h-14 p-v-14">
  <div class="person-box">
    <div>
      <Img
        className="circle img-width"
        personId={data.personId}
        alt={data.personName} />
    </div>
    <div class="full-width m-l-14">
      <div class="m-t-2 ">
        <i class="fa fa-user" />
        {data.personName ? data.personName : ''}
      </div>
      {#if data.personJobName}
        <div class="m-t-2 ">
          <i class="fa fa-briefcase" />
          {data.personJobName}
        </div>
      {/if}
      <div class={`font-16`}>
        <i class="fa fa-calendar" />
        {monthString}
      </div>
      {#if data.raterType === 'supervisor' && data.closed === false}
        <div class={`font-16 red-text`}>
          <i class="fa fa-question-circle" />
          Bawahan menunggu penilaian
        </div>
      {/if}

      <div class="font-16">
        Hasil Akhir
        <span class="font-20">{Math.ceil(data.totalPercentage)} %</span>
      </div>

      {#if data.closed}
        <div class="font-16">

          <b>SUDAH DINILAI OLEH</b>
          <br />
          {data.raterPersonName}
        </div>
      {/if}
    </div>

  </div>
  <slot />
</div>
