<script>
  import { Link } from "svelte-routing";

  import { Img } from "../../../components/common";

  export let data;
</script>

<style>
  .collection-item {
    min-height: 70px !important;
  }
  .person-box {
    display: flex;
    align-content: flex-start;
  }
</style>

<li class="collection-item p-v-4 p-h-14">
  <Link
    to={`/my-sub-activity/person/${data.personId}`}
    getProps={() => ({ class: 'cursor-pointer' })}>
    <div class="person-box">
      <div>
        <Img
          personId={data.personId}
          alt={data.personName ? data.personName : '[Tidak ada pegawai]'}
          className="img-width circle" />
      </div>
      <div class="m-l-14">
        <span class="title red-text text-darken-4">
          <i class="fa fa-user" />
          {data.personName ? data.personName : '[Tidak ada pegawai]'}
        </span>
        <div class="font-12 black-text">
          <div>
            <i class="fa fa-address-card-o" />
            {data.personNo ? data.personNo : '[Tidak ada pegawai]'}
          </div>
          <slot />
        </div>

        <div class={`font-16 red-text`}>
          <i class="fa fa-question-circle" />
          {data.needVerifyCount ? data.needVerifyCount : 0} Menunggu Verifikasi
        </div>

      </div>
    </div>
  </Link>
</li>
