import fetchApi from "./fetchApi";

// GET
export function restGetActivityPersonByDateListCache({ personId, date }) {
  let path = `/activityPerson/list/byPersonDateCache`;
  path += `/${window.companyId}/${personId}/${date}`;

  return fetchApi(path, {
    method: "GET"
  });
}
export function restGetActivityPersonDetail({ activityId }) {
  const path = `/activityPerson/detail/${activityId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetActivityPersonNeedVerifySubordinateGroupCache({
  personId
}) {
  const path = `/activityPerson/needVerifySubordinateGroupCache/${window.companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetActivityPersonNeedVerifyListByPersonCache({
  supervisorPersonId,
  personId
}) {
  const path = `/activityPerson/needVerifyListByPersonCache/${window.companyId}/${supervisorPersonId}/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
export function restPostActivityPersonCreate({ formData }) {
  const path = `/activityPerson/create`;
  return fetchApi(path, {
    method: "POST",
    body: formData
  });
}

export function restPostActivityPersonDelete({ activityId }) {
  const path = `/activityPerson/delete`;
  return fetchApi(path, {
    method: "POST",
    body: {
      activityId
    }
  });
}

export function restPostActivityPersonUpdateStatus({ formData }) {
  const path = `/activityPerson/updateStatus`;
  return fetchApi(path, {
    method: "POST",
    body: formData
  });
}
