import moment from "moment";
import { MOMENT_LOCALE_ID } from "./momentLocale";
const currentDate = moment().format("YYYYMMDD");
export const minValidDate = moment(currentDate, "YYYYMMDD")
  .subtract(4, "d")
  .format("YYYYMMDD");
export const maxValidDate = moment(currentDate, "YYYYMMDD")
  .add(0, "d")
  .format("YYYYMMDD");
let mInstance;

if (!mInstance) {
  moment.locale("id", MOMENT_LOCALE_ID);
  mInstance = moment;
  window.moment = mInstance;
}

function momentValidateYYYYMMDDHHmm(input) {
  input = Number(input);
  if (!mInstance(input, "YYYYMMDDHHmmSS").isValid()) {
    return { error: "Format moment harus YYYYMMDDHHmm", instance: undefined };
  }
  return { error: null, instance: mInstance(input, "YYYYMMDDHHmmSS") };
}

export function momentDay(input) {
  const { error, instance } = momentValidateYYYYMMDDHHmm(input);
  if (error) {
    return error;
  }
  return instance.format("ddd");
}

export function momentDayFull(input) {
  const { error, instance } = momentValidateYYYYMMDDHHmm(input);
  if (error) {
    return error;
  }
  return instance.format("dddd");
}

export function momentDateMonth(input) {
  const { error, instance } = momentValidateYYYYMMDDHHmm(input);
  if (error) {
    return error;
  }
  return instance.format("D MMMM YY");
}

export function momentDateMonthYear(input) {
  const { error, instance } = momentValidateYYYYMMDDHHmm(input);
  if (error) {
    return error;
  }
  return instance.format("D MMMM YYYY");
}

export function momentDayDateMonthYear(input) {
  const { error, instance } = momentValidateYYYYMMDDHHmm(input);
  if (error) {
    return error;
  }
  return instance.format("dddd DD MMMM YYYY");
}

export function momentHourMinute(input) {
  const { error, instance } = momentValidateYYYYMMDDHHmm(input);
  if (error) {
    return error;
  }
  return instance.format("HH:mm");
}

export function momentCountDuration(start, end) {
  const iStart = momentValidateYYYYMMDDHHmm(start);
  const iEnd = momentValidateYYYYMMDDHHmm(end);
  if (iStart.error) {
    return iStart.error;
  }
  if (iEnd.error) {
    return iEnd.error;
  }
  const durationMinutes = iEnd.instance.diff(iStart.instance, "minutes");
  let displayText = "";
  let hours = 0;
  let minutes = durationMinutes;
  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }
  if (hours > 0) {
    displayText += `${hours} jam `;
  }
  if (minutes > 0) {
    displayText += `${minutes} menit`;
  }

  return displayText;
}

export function momentIsNextDay(start, end) {
  const iStart = momentValidateYYYYMMDDHHmm(start);
  const iEnd = momentValidateYYYYMMDDHHmm(end);
  if (iStart.error) {
    return false;
  }
  if (iEnd.error) {
    return false;
  }
  const durationDay =
    Number(iEnd.instance.format("YYYYMMDD")) -
    Number(iStart.instance.format("YYYYMMDD"));
  if (durationDay < 1) {
    return false;
  }
  return true;
}

export function momentCreatedAt(createdAt) {
  if (createdAt && createdAt.toDate) {
    return moment(createdAt.toDate()).format("DD MMM YYYY HH:mm");
  }
  return "";
}

export function momentUpdatedAt(updatedAt, createdAt) {
  if (updatedAt && updatedAt.toDate) {
    return moment(updatedAt.toDate()).format("DD MMM YYYY HH:mm");
  }
  if (createdAt) {
    return momentCreatedAt(createdAt);
  }
  return "";
}

export function isPrevInvalidDay(input) {
  if (Number(input) < Number(minValidDate)) {
    return true;
  }
  return false;
}

export function isNextInvalidDay(input) {
  // Penambahan ini biar bisa real time
  const curDate = moment().format("YYYYMMDD");
  const maxVDate = moment(curDate, "YYYYMMDD")
    .add(0, "d")
    .format("YYYYMMDD");
  if (Number(maxVDate) < Number(input)) {
    return true;
  }
  return false;
}

export function isMonthBeforeNovember(input) {
  if (Number("20191031") >= Number(input)) {
    return true;
  }
  return false;
}

export default mInstance;
