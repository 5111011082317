<script>
  import {
    loading as loadingAccept,
    showModal as showModalAccept,
    postAccept
  } from "../../../stores/mySubActivity/acceptForm";
  import {
    loading as loadingReject,
    showModal as showModalReject,
    postReject
  } from "../../../stores/mySubActivity/rejectForm";
  import { ACTIVITY_STATUS } from "../../../helpers/constants";

  import { StatusLabel, Img } from "../../../components/common";
  import { ModalAccept, ModalReject } from "../modals";

  export let data;

  const startDateMoment = window.moment(data.startDate, "YYYYMMDDHHmm");
  const endDateMoment = window.moment(data.endDate, "YYYYMMDDHHmm");

  const startTimeStr = startDateMoment.format("HH:mm");
  const endTimeStr = endDateMoment.format("HH:mm");
  const durationStr = endDateMoment.from(startDateMoment, true);

  function clickOkAccept(val, onError) {
    postAccept({
      personId: data.personId,
      activityId: data.id,
      reason: val,
      onError,
    });
  }
  function clickOkReject(val, onError) {
    postReject({ 
      personId: data.personId,
      activityId: data.id,
      reason: val,
      onError,
      });
  }
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
  .action-box {
    display: flex;
    justify-content: space-between;
  }
  .badge-box {
    height: 22px;
  }
  .badge-custom {
    float: left;
    margin-left: -14px;
  }
</style>

{#if $loadingAccept || $loadingReject}
  <div class="loader-block" />
{/if}

{#if $showModalAccept}
  <ModalAccept
    onClose={() => {
      showModalAccept.set(false);
    }}
    onOk={clickOkAccept} />
{/if}
{#if $showModalReject}
  <ModalReject
    onClose={() => {
      showModalReject.set(false);
    }}
    onOk={clickOkReject} />
{/if}

<div class="card-panel p-0">
  <div class="person-box p-h-14 p-v-14">
    <div>
      <div class="">
        <Img className="circle img-width" alt="dari" personId={data.personId} />
      </div>
    </div>
    <div class="full-width m-l-14 ">
      <div class="font-10 grey-text">
        <div>
          <i class="fa fa-clock-o" />
          {startTimeStr}-{endTimeStr} (
          <span>{durationStr}</span>
          )
        </div>
      </div>
      <div class="font-10 grey-text">
        <i class="fa fa-user" />
        {data.personName}
      </div>
      <div class="font-10 grey-text">
        <i class="fa fa-circle icon-label-text" />
        {data.activityTypePoint} x {data.activityTypeMultiplier}
        <span class="grey-text">
          {data.activityTypeUnit ? `(${data.activityTypeUnit})` : ''}
        </span>
        = {data.activityTypePointTotal} poin
      </div>
      <div class="badge-box">
        <div class="badge-custom">
          <StatusLabel type="activity" status={data.status} />
        </div>
      </div>
      <div class="font-14">{data.activityTypeName}</div>
      <div class="font-12 grey-text">{data.desc}</div>
    </div>
  </div>
  {#if data.status === ACTIVITY_STATUS.NEED_VERIFY}
    <div class="action-box p-b-14 p-h-14">
      <div>
        <button
          on:click|preventDefault={() => {
            showModalReject.set(true);
          }}
          type="button"
          class="btn btn-small full-width red white-text">
          Tolak
        </button>
      </div>
      <div>
        <button
          on:click|preventDefault={() => {
            showModalAccept.set(true);
          }}
          type="button"
          class="btn btn-small full-width blue white-text">
          Terima
        </button>
      </div>
    </div>
  {/if}
</div>
