import { writable } from "svelte/store";

import { restGetSupervisorsPersonCache } from "../../api/org";

// import { warningNotif } from "../../helpers/notification";

export let loading = writable(true);
export let error = writable("");

export let data = writable([]);

export function initialState() {
  loading.set(true);
  error.set("");
  data.set([]);
}

export async function getSupervisorsPersonCache() {
  loading.set(true);
  try {
    const resp = await restGetSupervisorsPersonCache({
      companyId: window.companyId,
      personId: window.myPersonId
    });
    if (resp && resp.data && resp.data.supervisors) {
      data.set(resp.data.supervisors);
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
  }

  loading.set(false);
}
