<script>
  import { navigate } from "svelte-routing";

  import {
    error,
    loading,
    filteredListData,
    inputSearchKeyword,
    searchActivityType
  } from "../../stores/activityType/activityTypeAll";
  import {
    selectedActivityType,
    setEndTime
  } from "../../stores/myActivity/createForm";

  import { HeaderNav } from "../../components/common";

  export let location;
  location;

  export let date;
  export let filterName;

  let backUrl = `/my-activity/by-date/${date}/filter/${filterName}/create`;

  function select(selectedData) {
    selectedActivityType.set(selectedData);
    setEndTime(date);
    navigate(backUrl);
  }
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
  .action-box {
    display: flex;
    align-content: flex-start;
  }
</style>

<HeaderNav headerSize="s" headerName="Pilih Aktivitas" backURL={backUrl} />

{#if $loading}
  <div class="loader-block" />
{/if}

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="card-panel p-h-14 p-v-14 m-t-0">
      <form
        class="action-box"
        on:submit|preventDefault={() => {
          searchActivityType();
        }}>
        <div class="input-field full-width">
          <input
            bind:value={$inputSearchKeyword}
            id="activity"
            type="text"
            autocomplete="true"
            class="validate cursor-pointer" />
          <label class="active" for="activity">Cari Aktivitas</label>
          <span class="red-text">{$error}</span>
        </div>
        <div class="m-l-14">
          <button type="submit" class="btn full-width m-t-20">
            <i class="fa fa-search" />
          </button>
        </div>
      </form>
    </div>
    {#if $filteredListData && $filteredListData.length > 0}
      <ul class="collection p-0 m-t-0">
        {#each $filteredListData as activity}
          <li class="collection-item grey lighten-5 p-h-14 p-v-10">
            <span
              on:click|preventDefault={() => {
                select(activity);
              }}
              class="teal-text cursor-pointer">
              {activity.name}
              <small>
                <b>
                  ({activity.point} poin/{activity.unit ? `${activity.unit}` : ''})
                </b>
              </small>
            </span>
          </li>
        {/each}
      </ul>
    {/if}
  </div>
</div>
