import "whatwg-fetch";
import { fbAuth } from "./firebase";
// const baseApi = 'https://io.mypegawai.id/api';
const baseApi = `https://eabsen-cloud.appspot.com/api`;

const fetchApiReq = function (pathUrl, options, resolve, reject) {
  let baseApiUrl = baseApi;

  // baseApiUrl = "http://localhost:5002/api";
  // if (process.env.HENDRA === 'development') {
  //   baseApiUrl = 'http://localhost:5005/api/v1';
  //   // baseApiUrl = 'https://my.epegawai.com/api/v3';
  // }
  let httpStatus;
  window
    .fetch(`${baseApiUrl}${pathUrl}`, options)
    .then((resp) => {
      httpStatus = resp.status;

      if (httpStatus === 401) {
        fbAuth.signOut();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      if (httpStatus === 403) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }

      if (httpStatus < 200 || httpStatus > 299) {
        return resp.text();
      }

      return resp.json();
    })
    .then((resp) => {
      if (httpStatus < 200 || httpStatus > 299) {
        return reject(resp);
      }

      return resolve(resp);
    })
    .catch((err) => {
      return reject(`Internet offline, mohon periksa internet anda`);
    });
};

const fetchApi = function (pathUrl, options) {
  let parseOptions = options;
  if (!parseOptions) {
    parseOptions = {};
  }

  parseOptions.credentials = "include";

  if (!parseOptions.headers) {
    parseOptions.headers = {};
  }

  if (parseOptions.method === "POST") {
    if (!parseOptions.multipart) {
      parseOptions.headers["Content-Type"] = "application/json";
      if (!options.body) {
        parseOptions.body = JSON.stringify({});
      } else {
        parseOptions.body = JSON.stringify(options.body);
      }
    } else {
      delete parseOptions.multipart;
    }
  }

  return new Promise((resolve, reject) => {
    if (!parseOptions.headers.Authorization) {
      const authToken = window.authToken;
      if (authToken) {
        parseOptions.headers.Authorization = `${authToken}`;
        return fetchApiReq(pathUrl, parseOptions, resolve, reject);
      }
    }
    return fetchApiReq(pathUrl, parseOptions, resolve, reject);

    // fbApp.INTERNAL.getToken()
    //   .then(token => {
    //     if (token && token.accessToken) {
    //       options.headers['Authorization'] = `Bearer ${token.accessToken}`;
    //     }
    //     _fetchApi(pathUrl, options, resolve, reject);
    //   })
    //   .catch(() => {
    //     _fetchApi(pathUrl, options, resolve, reject);
    //   });
  });
};

export default fetchApi;
