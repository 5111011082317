<script>
  import { onMount, onDestroy } from "svelte";
  import { loading, data } from "../../../stores/news/newsFeed";

  import CarouselItem from "./CarouselItem.svelte";
  import ModalView from "./ModalView.svelte";

  let activeIndex = 0;
  let interval;
  let showModal;

  function clickActive(idx) {
    if (activeIndex === idx) {
      return;
    }
    activeIndex = idx;
    if (interval) {
      window.clearInterval(interval);
      startInterval();
    }
  }
  function setModal(v) {
    showModal = v;
  }

  function startInterval() {
    interval = setInterval(() => {
      rotation();
    }, 5000);
  }
  function rotation() {
    if (activeIndex + 1 <= 1) {
      activeIndex += 1;
    } else {
      activeIndex = 0;
    }
  }
  onMount(() => {
    startInterval();
  });
  onDestroy(() => {
    if (interval) {
      window.clearInterval(interval);
    }
  });
</script>

<style>
  .carousel-slider {
    height: 120px;
    border-radius: 5px;
  }
  .indicators {
    z-index: 1;
  }
  .indicator-item {
    margin: 4px 4px !important;
    z-index: 5;
  }
</style>

{#if showModal === 'view'}
  <ModalView onClose={() => (showModal = undefined)} />
{/if}

<div class="m-b-20">
  <div class="font-18 font-500">Berita Terbaru</div>
  <div class={`carousel carousel-slider`}>
    {#if $loading}
      <div class="spinner-layer spinner-blue">
        <div class="circle-clipper left">
          <div class="circle" />
        </div>
        <div class="gap-patch">
          <div class="circle" />
        </div>
        <div class="circle-clipper right">
          <div class="circle" />
        </div>
      </div>
    {:else}
      {#each $data as v, k}
        <CarouselItem data={v} {activeIndex} idx={k} {setModal} />
      {/each}
      <ul class="indicators">
        {#each $data as v, k}
          <li
            class={`indicator-item ${activeIndex === k ? 'active' : ''}`}
            on:click={() => {
              clickActive(k);
            }} />
        {/each}
      </ul>
    {/if}
  </div>
</div>
