import { writable, get } from "svelte/store";

import { navigate } from "svelte-routing";
import { restPostTaskAssignmentCreate } from "../../api/taskAssignment";

import { errorNotif, successNotif } from "../../helpers/notification";

export let error = writable("");
export let loading = writable(false);
export let data = writable({});

// Formdata
const currentDate = window
  .moment()
  .add(1, "day")
  .format("YYYYMMDD");

export let inputDeadline = writable(currentDate);
export let inputDesc = writable("");

export async function postTaskAssignmentCreate({
  supervisorId,
  subordinateId
}) {
  const formData = {
    supervisorId,
    personId: subordinateId,
    deadline: get(inputDeadline),
    desc: get(inputDesc)
  };

  error.set("");
  loading.set(true);
  try {
    const resp = await restPostTaskAssignmentCreate({
      formData
    });
    loading.set(false);
    successNotif(`Berhasil membuat tugas ${resp.data.desc}`);
    navigate(`/task-assignment/subordinate/${subordinateId}/task/all`);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
