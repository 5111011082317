import fetchApi from "./fetchApi";

// GET
export function restGetPersonCache({ companyId, personId }) {
  const path = `/person/cache/${companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetPersonDetailCache({ companyId, personId }) {
  const path = `/person/detail/cache/${companyId}/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
