<script>
  import { onMount } from "svelte";
  import { Router, Route } from "svelte-routing";

  import { getActivityTypeAllCache } from "../../stores/activityType/activityTypeAll";
  import { initialState } from "../../stores/myActivity/createForm";

  import Page404 from "../common/Page404.svelte";

  import MyActivityRedirect from "./MyActivityRedirect.svelte";
  import RouteMyActivity from "./RouteMyActivity.svelte";

  onMount(() => {
    getActivityTypeAllCache();
    initialState();
  });
</script>

<Router>
  <Route
    path="/by-date/:date/filter/:filterName/*wildcardName"
    component={RouteMyActivity} />
  <Route path="/" component={MyActivityRedirect} />
  <Route component={Page404} />
</Router>
