<script>
  import {onMount} from 'svelte';
  import { Router, Route } from "svelte-routing";

  import { loading, getRatingPersonDetail } from "../../stores/personRating/historyRatingDetail";

  import HistoryByCategory from "./HistoryByCategory.svelte";
  import HistoryByQuestion from "./HistoryByQuestion.svelte";

  import Page404 from "../common/Page404.svelte";

  export let location;
  export let wildcardName;

  location;
  wildcardName;

  export let ratingPersonId;

    function getData() {
    getRatingPersonDetail({
      ratingPersonId: ratingPersonId
    });
  }

  onMount(() => {
    getData();
  });
</script>

{#if $loading}
  <div class="loader-block" />
{:else}
  <Router>
    <Route path="/" component={HistoryByCategory} {ratingPersonId} {getData} />
    <Route
      path="/questions/:categoryId"
      component={HistoryByQuestion}
      {ratingPersonId} {getData} />
    <Route component={Page404} />
  </Router>
{/if}
