<script>
  import { onMount } from "svelte";
  import { selectedData } from "../../../stores/news/newsFeed";
  // Props
  export let data = {};
  export let activeIndex;
  export let idx;
  export let setModal;
  let carouselColor = "red white-text";

  onMount(() => {
    if ((idx + 1) % 5 === 0) {
      return (carouselColor = "indigo white-text");
    }
    if ((idx + 1) % 4 === 0) {
      return (carouselColor = "green white-text");
    }
    if ((idx + 1) % 3 === 0) {
      return (carouselColor = "amber darken-4 white-text");
    }
    if ((idx + 1) % 2 === 0) {
      return (carouselColor = "blue white-text");
    }
    if ((idx + 1) % 1 === 0) {
      return (carouselColor = "teal white-text");
    }
  });
</script>

<style>
  .cursor-pointer {
    z-index: 20;
  }
</style>

<div
  on:click={() => {
    selectedData.set(data);
    setModal('view');
  }}
  class={`cursor-pointer carousel-item ${carouselColor} ${activeIndex === idx ? 'active' : ''} p-8`}
  style={`z-index:${activeIndex === idx ? '1' : '-1'}; opacity: 1; visibility: visible; `}>
  <h2 class={`m-v-0 font-20`}>{data.title}</h2>
  <!-- <div class="font-10">
    <i>{window.moment(data.createdAt, 'YYYYMMDDHHmm').format('DD MMM YYYY HH:mm')}</i>
  </div> -->
  <p class={`m-b-0 m-t-4 white-text`}>
    {data.shortDesc}
    <u>selengkapnya...</u>
  </p>
</div>
