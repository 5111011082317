import { writable } from "svelte/store";

import { restGetRatingPersonListByRater } from "../../api/ratingPerson";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

export let listData = writable([]);

export async function getRatingPersonListByRater() {
  error.set("");
  loading.set(true);
  listData.set([]);
  try {
    const resp = await restGetRatingPersonListByRater({
      raterPersonId: window.myPersonId,
      closed: true,
      limit: 100
    });
    loading.set(false);
    listData.set(resp.data || []);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
