import fetchApi from "./fetchApi";
import { restGetConfigDetail } from "./config";

// GET
export function restGetNewsListCache(query) {
  async function getHandler(resolve, reject) {
    try {
      const config = await restGetConfigDetail("newsListCache");
      const defaultQuery = {
        limit: 10,
        page: 1,
        sort: "name",
        sortDir: "asc",
        search: ""
      };
      const newQuery = { ...defaultQuery, ...query };
      let url = `/news/list/${window.companyId}/${config.data.updatedAt}`;
      url += `/limit/${newQuery.limit}`;
      url += `/page/${newQuery.page}`;
      url += `/sort/${newQuery.sort}`;
      url += `/sortDir/${newQuery.sortDir}`;
      url += `/search/${newQuery.search}`;
      const resp = await fetchApi(url, {
        method: "GET"
      });
      resolve(resp);
    } catch (err) {
      reject(err);
    }
  }
  return new Promise(getHandler);
}

// POST

export function restPostNewsUpdate(newsId, formData) {
  const path = `/news/update/${newsId}`;
  return fetchApi(path, {
    method: "POST",
    body: formData
  });
}
