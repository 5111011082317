<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";
  import {
    loading,
    error,
    inputMobile,
    requestOtp
  } from "../../stores/auth/otpForm.js";

  onMount(() => {
    inputMobile.set("");
  });
</script>

<style>
  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div class="center-container">
  <h3 class="center-align">
    <span class="font-30">Nomor Handphone</span>
  </h3>

  <div class="login-box">
    <div class="row">
      <form on:submit|preventDefault={requestOtp} class="col s12">
        <div>Pastikan no handphone sedang aktif</div>
        <div class="row">
          <div class="input-field col s12">
            <input
              bind:value={$inputMobile}
              autocomplete="true"
              id="mobile"
              type="tel"
              class="validate" />
            <label class="active" for="mobile">masukkan nomor hp</label>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="red-text m-b-4">{$error}</div>
            <button type="submit" class="btn red darken-4 btn-block">
              Kirim Kode SMS
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <Link
              to={`/login`}
              getProps={() => ({ class: 'right red-text text-darken-4' })}>
              Kembali ke Login
            </Link>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
