<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";

  export let location;
  location;

  export let ratingPersonId;
  export let categoryId;

  onMount(() => {
    navigate(
      `/person-rating/categories/${ratingPersonId}/questions/${categoryId}`
    );
  });
</script>

<div class="loader-block" />
