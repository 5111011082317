export const NO_PHOTO_URL = `/images/nophoto.png`;
export function LOAD_IMAGE_HANDLER(src) {
  const objImg = new Image();
  objImg.src = src;
  return new Promise((resolve) => {
    if (!src) {
      resolve(NO_PHOTO_URL);
    }
    objImg.onload = function () {
      resolve(src);
    };
    objImg.onerror = function () {
      resolve(NO_PHOTO_URL);
    };
  });
}
export function HANDLE_PICTURE({ personId, avatar }) {
  let result = NO_PHOTO_URL;
  if (window.companyId && personId && avatar) {
    result = `https://cloud.eabsen.com/files/photos/profile/${window.companyId}/${personId}/${avatar}/thumb_c_small.jpg`;
  }
  return result;
}
export function HANDLE_PICTURE_2({ personId }) {
  let result = NO_PHOTO_URL;
  if (window.companyId && personId) {
    result = `https://cloud.eabsen.com/files/photos/profile/${window.companyId}/${personId}/thumb_c_small.jpg`;
  }
  return result;
}

export const ACTIVITY_STATUS = {
  NEED_VERIFY: 20,
  REJECT: 40, // closed=true
  REJECT_BY_SYSTEM: 45,
  ACCEPT: 50, // closed=true
  UPDATE_ACTIVITY: 60,
};

export function IS_VALID_ACTIVITY_STATUS(statusCode) {
  return [20, 40, 50, 60].indexOf(statusCode) > -1;
}

export const JOB_ASSIGNMENT_STATUS = {
  OPEN: 0,
  NEED_REVISION: 10,
  NEED_VERIFY: 20,
  ON_PROGRESS: 30,
  REJECT: 40,
  REJECT_BY_SYSTEM: 45,
  ACCEPT: 50,
  UPDATE_TASK: 60,
};

export function IS_VALID_JOB_ASSIGNMENT_STATUS(statusCode) {
  return [0, 10, 20, 30, 40, 50, 60].indexOf(statusCode) > -1;
}

export function IS_VALID_ROOT_MOBILE(mobile) {
  return (
    ["+6285295733074", "+628114309026", "+6281527724625"].indexOf(mobile) > -1
  );
}

export function IS_VALID_USER_ROOT(userId) {
  return ["96", "99"].indexOf(userId) > -1;
}

export function IS_VALID_USER_ADMIN(userId) {
  return ["94", "95", "96", "97", "98", "99"].indexOf(userId) > -1;
}

export function IS_VALID_BKD_MOBILE(mobile) {
  return ["+6285256899099"].indexOf(mobile) > -1;
}
