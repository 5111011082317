<script>
  export let personId = "";
  export let alt = "";
  export let className = "";

  const NO_PHOTO_URL = `/images/nophoto.png`;
  let imgUrl = `https://cloud.eabsen.com/files/photos/profile/${window.companyId}/${personId}/thumb_c_small.jpg`;
</script>

<img
  src={imgUrl}
  {alt}
  class={`${className}`}
  onerror={`this.onerror=null;this.src='${NO_PHOTO_URL}';`} />
