<script>
  import { Link } from "svelte-routing";

  import { ACTIVITY_STATUS } from "../../../helpers/constants";

  import { StatusLabel, Img } from "../../../components/common";

  export let filterName;
  export let data = {};

  const currentDateStr = data.startDate.toString().substring(0, 8);
  const startDateMoment = window.moment(data.startDate, "YYYYMMDDHHmm");
  const endDateMoment = window.moment(data.endDate, "YYYYMMDDHHmm");

  const startTimeStr = startDateMoment.format("HH:mm");
  const endTimeStr = endDateMoment.format("HH:mm");
  const durationStr = endDateMoment.from(startDateMoment, true);
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-startDate;
  }
  .supervisor-name {
    color: #009688;
  }
  .icon-label-text {
    width: 10px;
  }
</style>

<li class="collection-item p-h-14 p-v-14">
  <Link
    to={`/my-activity/by-date/${currentDateStr}/filter/${filterName}/detail/${data.id}`}
    getProps={() => ({ class: 'cursor-pointer' })}>
    {#if data.status === ACTIVITY_STATUS.REJECT || data.status === ACTIVITY_STATUS.REJECT_BY_SYSTEM}
      <div class="person-box">
        <div class="full-width m-l-14 ">
          <div class="font-14 grey-text">
            <i>
              Aktivitas {startTimeStr}-{endTimeStr}
              <span class="red-text">
                {data.status === ACTIVITY_STATUS.REJECT_BY_SYSTEM ? 'Expired' : 'Ditolak'}
              </span>
              oleh {data.status === ACTIVITY_STATUS.REJECT_BY_SYSTEM ? 'System' : data.updatedByName}
            </i>
          </div>
        </div>
      </div>
    {:else}
      <div class="person-box">
        <div>
          <div class="">
            <Img
              className="circle img-width"
              alt={`Melapor ke ${data.supervisorPersonName}`}
              personId={data.supervisorPersonId} />
          </div>
        </div>
        <div class="full-width m-l-14 ">
          <div class="font-10 grey-text">
            <div>
              <i class="fa fa-clock-o icon-label-text" />
              {startTimeStr}-{endTimeStr} (
              <span>
                <i class="fa fa-clock-o" />
                {durationStr}
              </span>
              )
            </div>
          </div>
          <div class="font-10 supervisor-name">
            <i class="fa fa-sitemap icon-label-text" />
            {`Melapor ke ${data.supervisorPersonName}`}
          </div>
          <div class="font-10 grey-text">
            <i class="fa fa-circle icon-label-text" />
            {data.activityTypePoint} x {data.activityTypeMultiplier}
            <span class="grey-text">
              {data.activityTypeUnit ? `(${data.activityTypeUnit})` : ''}
            </span>
            = {data.activityTypePointTotal} poin
          </div>
          <div class="font-14 black-text">{data.activityTypeName}</div>
          <div class="font-12 grey-text">{data.desc}</div>
        </div>
        <div>
          <div>
            <StatusLabel type="activity" status={data.status} />
          </div>
        </div>
      </div>
    {/if}
  </Link>
</li>
