import fetchApi from "./fetchApi";

// GET
export function restGetActivityTypeAllCache() {
  const path = `/activity-type/list-all-cache/${window.companyId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
