import { writable, get } from "svelte/store";
import { navigate } from "svelte-routing";

import { fbAuth } from "../../api/firebase";
import { restPostAuthChangeMyCompany } from "../../api/auth";

import { successNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

// FORMDATA
export let inputCompanyId = writable("");

export async function changeMyCompany() {
  error.set("");
  let companyIdInput = get(inputCompanyId);

  if (!companyIdInput) {
    const errMsg = "Kode undangan tidak boleh kosong";
    error.set(errMsg);
    return;
  }

  companyIdInput = companyIdInput.trim();

  if (companyIdInput.length < 3) {
    const errMsg = "Kode undangan terlalu pendek";
    error.set(errMsg);
    return;
  }

  loading.set(true);

  try {
    await restPostAuthChangeMyCompany({
      companyId: companyIdInput
    });

    inputCompanyId.set("");
    successNotif("Berhasil");
    const currentUser = fbAuth.currentUser;

    if (currentUser) {
      if (currentUser) {
        setTimeout(() => {
          fbAuth.currentUser.getIdToken(true);
        }, 5000);
        setTimeout(() => {
          navigate("/");
          if (get(loading)) {
            loading.set(false);
          }

          window.location.reload();
        }, 6000);
      }
      return;
    }
  } catch (err) {
    error.set(err);
  }

  if (get(loading)) {
    loading.set(false);
  }
}
