<script>
  import { onMount } from "svelte";

  import {
    loading,
    selectedPerson,
    questionCategories,
    questionListData,
    questionPercentageData,
  } from "../../stores/personRating/historyRatingDetail";

  import { Link, navigate } from "svelte-routing";
  import { HeaderNav } from "../../components/common";
  import { PersonBoxTop, QuestionCategoryItem } from "./components";

  export let location;
  location;

  export let ratingPersonId;
  export let getData;

</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 01px !important;
    margin-right: 0px !important;
        width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Sudah Dinilai"
  backURL={`/person-rating/history`}
  onRefresh={getData} />

<div class="root-container section no-pad-bot">
  <div class="container">
    {#if $selectedPerson && $selectedPerson.personId}
      <PersonBoxTop data={$selectedPerson} />
    {/if}
    {#if $questionCategories && $questionCategories.length > 0}
      <div class="card-panel p-h-0 p-t-0 p-b-12">
        <ul class="collection p-0">
          {#each $questionCategories as categoryId}
            
              <QuestionCategoryItem
                data={{ id: categoryId, title: $questionListData[categoryId].title, percentage: $questionPercentageData[categoryId] }}
                {ratingPersonId}
                detailURL={`/person-rating/history/categories/${ratingPersonId}/questions/${categoryId}`} />
            
          {/each}
        </ul>
        <div class="m-10">
          <Link to={`/person-rating/history`}>
            <button class="btn btn-small grey full-width">Kembali</button>
          </Link>
        </div>
      </div>
    {/if}
  </div>
</div>
