<script>
  import { data as myPersonData } from "../../../stores/person/myPerson";

  import { JOB_ASSIGNMENT_STATUS } from "../../../helpers/constants";

  import { StatusText, Img } from "../../../components/common";

  export let loading;
  export let data;

  const isShowLabel =
    data.statusUpdate === JOB_ASSIGNMENT_STATUS.NEED_VERIFY ||
    data.statusUpdate === JOB_ASSIGNMENT_STATUS.ACCEPT ||
    data.statusUpdate === JOB_ASSIGNMENT_STATUS.NEED_REVISION ||
    data.statusUpdate === JOB_ASSIGNMENT_STATUS.REJECT ||
    data.statusUpdate === JOB_ASSIGNMENT_STATUS.UPDATE_TASK;
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
  .inline-block {
    display: inline-block;
  }
  .speech-bubble {
    position: relative;
    background: #eeeeee;
    border-radius: 0.4em;
    border-top-left-radius: 0px;
    padding: 8px;
    font-size: 14px;
    margin-left: -8px;
    white-space: pre-line;
  }

  .speech-bubble:after {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-right-color: #eeeeee;
    border-left: 0;
    border-top: 0;
    margin-top: -3.5px;
    margin-left: -7px;
  }

  .meta-right {
    float: right;
    display: block;
    width: auto;
  }
  .speech-bubble-right {
    position: relative;
    background: #eeeeee;
    border-radius: 0.4em;
    border-top-right-radius: 0px;
    padding: 8px;
    font-size: 14px;
    margin-right: -8px;
    text-align: right;
    margin-top: 20px;
    white-space: pre-line;
  }

  .speech-bubble-right:after {
    content: "";
    position: absolute;
    right: 0;
    top: 4px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-left-color: #eeeeee;
    border-right: 0;
    border-top: 0;
    margin-top: -3.5px;
    margin-right: -7px;
  }
  .collection-item {
    border-bottom: none;
  }
</style>

{#if $myPersonData.id !== data.createdBy}
  <li class="collection-item grey lighten-5 p-v-4 p-h-14">
    <div class="person-box">
      <div>
        {#if !loading}
          <Img
            className="circle img-width"
            alt="dari"
            personId={data.createdBy} />
        {/if}
      </div>
      <div class="full-width m-l-14">
        <div class="font-10 grey-text inline-block">
          <i class="fa fa-clock-o" />
          {window.moment(data.createdAt).format('DD MMM YYYY HH:mm')}
        </div>
        {#if data.progress && data.progress > 0}
          <div class="font-10 grey-text inline-block">
            ,
            <i class="fa fa-signal" />
            {data.progress}%
          </div>
        {/if}
        {#if isShowLabel}
          <div class="inline-block">
            ,
            <StatusText type="jobAssignment" status={data.statusUpdate} />
          </div>
        {/if}
        {#if data.oldDesc && data.newDesc}
          <div class="speech-bubble grey-text font-12">
            <i>"{data.oldDesc}" diubah menjadi "{data.newDesc}"</i>
          </div>
        {:else if data.statusUpdate === JOB_ASSIGNMENT_STATUS.ACCEPT}
          <div class="speech-bubble font-12">
            <i class="grey-text">"tugas telah diterima"</i>
            {data.comment}
          </div>
        {:else if data.statusUpdate === JOB_ASSIGNMENT_STATUS.REJECT || data.statusUpdate === JOB_ASSIGNMENT_STATUS.NEED_REVISION}
          <div class="speech-bubble font-12">
            <i class="grey-text">{data.comment}</i>
          </div>
        {:else if data.comment}
          <div class="speech-bubble">{data.comment}</div>
        {/if}
      </div>

    </div>
  </li>
{:else}
  <li class="collection-item grey lighten-5 p-v-4 p-h-14">
    <div class="person-box">
      <div class="full-width m-r-14">
        <div class="meta-right">

          {#if isShowLabel}
            <div class="inline-block">
              <StatusText type="jobAssignment" status={data.statusUpdate} />
              ,
            </div>
          {/if}
          <div class="font-10 grey-text inline-block">
            <i class="fa fa-clock-o" />
            {window.moment(data.createdAt).format('DD MMM YYYY HH:mm')}
          </div>
        </div>
        {#if data.oldDesc && data.newDesc}
          <div class="speech-bubble-right grey-text font-12">
            <i>"{data.oldDesc}" diubah menjadi "{data.newDesc}"</i>
          </div>
        {:else if data.statusUpdate === JOB_ASSIGNMENT_STATUS.ACCEPT}
          <div class="speech-bubble-right font-12">
            <i class="grey-text">"aktivitas telah diterima"</i>
            {data.comment}
          </div>
        {:else if data.statusUpdate === JOB_ASSIGNMENT_STATUS.REJECT || data.statusUpdate === JOB_ASSIGNMENT_STATUS.NEED_REVISION}
          <div class="speech-bubble-right font-12">
            <i class="grey-text">{data.comment}</i>
          </div>
        {:else if data.comment}
          <div class="speech-bubble-right">{data.comment}</div>
        {/if}

      </div>
      <div>
        {#if !loading}
          <Img
            className="circle img-width"
            alt="dari"
            personId={ data.createdBy}
           />
        {/if}
      </div>
    </div>
  </li>
{/if}
