import { tick } from "svelte";
import { writable } from "svelte/store";
import { navigate } from "svelte-routing";

import { restPostActivityPersonDelete } from "../../api/activityPerson";

import { errorNotif, warningNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

export let showModal = writable(false);

export async function postActivityPersonDelete({ data, date, filterName }) {
  error.set("");
  loading.set(true);

  try {
    await restPostActivityPersonDelete({
      activityId: data.id
    });
    const currentDateLocal = date;
    warningNotif(`Berhasil hapus aktivitas ${data.activityTypeName}`);
    showModal.set(false);
    await tick();
    navigate(`/my-activity/by-date/${currentDateLocal}/filter/${filterName}`);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
