<script>
  import { fly } from "svelte/transition";
  import { selectedData } from "../../../stores/news/newsFeed";

  export let onClose;
  export let isBackdrop = true;

  function clickClose() {
    onClose();
  }
</script>

<style>
  .btn-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
</style>

<div
  class="modal-frame"
  on:click={e => {
    if (e.target.classList.contains('modal-frame')) {
      if (isBackdrop) {
        onClose();
      }
    }
  }}>
  <div transition:fly={{ y: 200 }} class="modal-container">
    <div class="card">
      <div>
        <div class="card-content">
          {#if $selectedData.updatedAt}
            <span class="card-title">{$selectedData.title}</span>
            <div class="font-10">
              <i>
                {window
                  .moment($selectedData.updatedAt)
                  .format('DD MMM YYYY HH:mm')}
              </i>
            </div>
            <div class="m-t-10">
              {@html $selectedData.content}
            </div>
          {/if}
        </div>
        <div class="card-action">
          <div class="btn-flex">
            <span />
            <button
              on:click={clickClose}
              type="button"
              class="btn grey white-text">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
