<script>
  import { onMount } from "svelte";

  import {
    loading,
    data,
    getTaskAssignmentDetail
  } from "../../stores/myTaskAssignment/taskDetail";

  import { HeaderNav, StatusLabel } from "../../components/common";
  import {
    DetailHead,
    DetailCommentItem,
    DetailCommentAction
  } from "./components";

  export let location;
  location;

  export let supervisorId;
  export let filterName;
  export let taskId;

  function getData() {
    getTaskAssignmentDetail({
      taskAssignmentId: taskId
    });
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Penugasan Dari Pimpinan"
  backURL={`/my-task-assignment/supervisor/${supervisorId}/task/${filterName}`}
  onRefresh={() => {
    getData();
  }} />

{#if !$loading && $data && $data.id}
  <div class="root-container section no-pad-bot">
    <div class="container">
      <div class="top-fixed">
        <DetailHead detailData={$data} />
      </div>
      {#if $data.comments}
        <ul class="collection m-0 p-0 m-b-16">
          {#each $data.comments as comment}
            <DetailCommentItem loading={false} data={comment} />
          {/each}
        </ul>
      {/if}

      <div class="bottom-fixed">
        <DetailCommentAction {taskId} detailData={$data} />
      </div>
    </div>
  </div>
{/if}
