<script>
  import { Link } from "svelte-routing";
  import { Star } from "../../../components/common";

  export let data = {};
  export let ratingPersonId;
  export let detailURL = `/person-rating/need-rating/${ratingPersonId}/category/${data.id}`;
</script>

<li class="collection-item p-l-10 p-r-10">
  <Link to={detailURL}>
    <div class="red-text text-darken-4">
      <i class="fa fa-question-circle" />
      {data.title}
    </div>
    <div>
      <Star value={Math.ceil(data.percentage / 10)} fontClass="font-28" />
      <span class="font-20 black-text">{Math.ceil(data.percentage)} %</span>
    </div>
  </Link>
</li>
