<script>
  import { Link } from "svelte-routing";
  import { Star } from "../../../components/common";

  export let data = {};
</script>

<li class="collection-item p-l-10 p-r-10">

  <div>{data.text}</div>
  <div>
    <Star value={Math.ceil(data.value / 10)} fontClass="font-28" />
    <span class="font-20">{Math.ceil(data.value)} %</span>
  </div>

</li>
