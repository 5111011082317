import { tick } from "svelte";
import { writable, get } from "svelte/store";
import { navigate } from "svelte-routing";

import { fbAuth } from "../../api/firebase";
import {
  restGetAuthCSRFToken,
  restPostAuthSessionFbLogin,
} from "../../api/auth";

import { errorNotif } from "../../helpers/notification";

import {
  getCompanyDetailCache,
  initialState as initMyCompany,
} from "../company/myCompany";
import { initialState as initMyPerson } from "../person/myPerson";
import { initialState as initMyJobs } from "../org/myJobs";
import { initialState as initMySupervisors } from "../org/mySupervisors";
import { initialState as initMySubordinates } from "../org/mySubordinates";

export let loading = writable(true);
export let error = writable("");

export let isDoneInitAuth = writable(false);
export let user = writable(null);

export function initCheckAuth() {
  loading.set(true);

  const currentAuthPathUrl = window.location.pathname;

  if (
    ["/404", "/login", "/otp", "/verify-otp", "/"].indexOf(currentAuthPathUrl) <
    0
  ) {
    window.onAuthPathname = currentAuthPathUrl;
  }

  fbAuth.onAuthStateChanged(onAuthStateChanged);
}

function onAuthStateChanged(fbUser) {
  if (!fbUser) {
    // User is signed out.
    window.companyId = null;
    window.myPersonId = null;
    window.authToken = null;
    logout();
    return;
  }

  return sessionLoginWithFirebase();
}

async function sessionLoginWithFirebase() {
  const currentUserFbLocal = fbAuth.currentUser;
  if (!currentUserFbLocal || !currentUserFbLocal.uid) {
    logout();
    return;
  }

  let idToken;
  try {
    idToken = await currentUserFbLocal.getIdToken();
    window.authToken = idToken;
  } catch (err) {
    errorNotif(err.message);

    logout();
    return;
  }

  let csrfToken;
  try {
    const respCsrf = await restGetAuthCSRFToken();
    csrfToken = respCsrf.csrfToken;
  } catch (err) {
    errorNotif(err);
    if (err.startsWith("Internet offline")) {
      setTimeout(() => {
        sessionLoginWithFirebase();
      }, 10000);
      return;
    }
    logout();
    return;
  }

  let respAuthSession;

  try {
    respAuthSession = await restPostAuthSessionFbLogin({
      idToken,
      csrfToken,
    });
  } catch (err) {
    errorNotif(err);
    logout();
    return;
  }

  const claims = respAuthSession.data;

  const nextUser = {
    ...claims,
    displayName:
      currentUserFbLocal.displayName || currentUserFbLocal.name || null,
    picture: currentUserFbLocal.photoURL || currentUserFbLocal.picture || null,
    mobile: currentUserFbLocal.phoneNumber || claims.phone_number || null,
  };
  //clean claims
  delete nextUser.aud;
  delete nextUser.auth_time;
  delete nextUser.firebase;
  delete nextUser.exp;
  delete nextUser.iat;
  delete nextUser.iss;
  delete nextUser.firebase;
  delete nextUser.sub;
  delete nextUser.user_id;

  nextUser.displayMobile = nextUser.phone_number;
  if (nextUser.phone_number) {
    const displayMobile = nextUser.phone_number.replace("+628", "08");
    nextUser.displayMobile = displayMobile;
  }

  //window global
  window.myUser = { ...nextUser };
  window.companyId = nextUser.companyId || null;
  window.myPersonId = nextUser.personId || null;

  const nextPathName = window.onAuthPathname ? window.onAuthPathname : "/";
  navigate(nextPathName);
  user.set(nextUser);

  if (!get(isDoneInitAuth)) {
    isDoneInitAuth.set(true);
  }

  if (get(loading)) {
    loading.set(false);
  }

  nextGlobalSequence();
}

function nextGlobalSequence() {
  // Mulai Masuk Auth Layout
  getCompanyDetailCache();
}

export async function logout() {
  window.onAuthPathname = null;
  if (!get(loading)) {
    loading.set(true);
  }

  try {
    await fbAuth.signOut();
    if (get(user) !== null) {
      user.set(null);
    }
    navigate("/login");
    await tick();
    resetGlobalStateToInitial();
  } catch (err) {
    console.warn(err);
    if (err.message) {
      errorNotif(err.message);
      error.set(err.message);
    }
  }

  user.set(null);
  window.myUser = null;
  window.companyId = null;
  window.myPersonId = null;

  if (!get(isDoneInitAuth)) {
    isDoneInitAuth.set(true);
  }

  if (get(loading)) {
    loading.set(false);
  }
}

function resetGlobalStateToInitial() {
  initMyCompany();
  initMyPerson();
  initMyJobs();
  initMySupervisors();
  initMySubordinates();
}
