import { writable } from "svelte/store";

import { restGetActivityPersonDetail } from "../../api/activityPerson";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

export let data = writable({});

export async function getActivityPersonDetail({ activityId }) {
  error.set("");
  loading.set(true);
  data.set({});

  // SET DATES
  try {
    const resp = await restGetActivityPersonDetail({
      activityId
    });
    if (resp && resp.data) {
      data.set(resp.data);
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }

  loading.set(false);
}
