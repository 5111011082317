import { writable, get } from "svelte/store";

import { restGetOrgCache } from "../../api/org";

import { data as myPersonData } from "../person/myPerson";

// import { warningNotif } from "../../helpers/notification";

export let loading = writable(true);
export let error = writable("");

export let data = writable({});

export async function getOrgCache() {
  loading.set(true);
  const myPerson = get(myPersonData);
  try {
    const resp = await restGetOrgCache({
      companyId: window.companyId,
      orgId: myPerson.mainOrgId
    });
    if (resp && resp.data) {
      resp.data.ownerId = resp.data.personId;
      resp.data.ownerName = resp.data.personName;
      resp.data.ownerNo = resp.data.personNo;
      resp.data.ownerMobile = resp.data.personMobile;
      data.set(resp.data);
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
  }

  loading.set(false);
}
