<script>
  import { onMount } from "svelte";

  import { loading, getMainOrg } from "../../stores/org/myOrgStructure";
  import { HeaderNav } from "../../components/common";
  import { MainOrg } from "./components";

  onMount(() => {
    getMainOrg();
  });
</script>

<style>
  .card-panel {
    margin-top: -12px;
    margin-left: -18px;
    margin-right: -18px;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerName="Organisasi"
  backURL="/"
  onRefresh={() => {
    getMainOrg();
  }} />
<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="card-panel p-l-20 p-t-20">
      <MainOrg />
    </div>
  </div>
</div>
