<script>
  import { Router, Route } from "svelte-routing";

  import Page404 from "../common/Page404.svelte";

  import MySubList from "./MySubList.svelte";
  import ActivityList from "./ActivityList.svelte";
  import ActivityDetail from "./ActivityDetail.svelte";
</script>

<Router>
  <Route
    path="/person/:personId/activity/:activityId"
    component={ActivityDetail} />
  <Route path="/person/:personId" component={ActivityList} />
  <Route path="/" component={MySubList} />
  <Route component={Page404} />
</Router>
