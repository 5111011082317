import fetchApi from "./fetchApi";

// GET
export function restGetAuthCSRFToken() {
  const path = `/auth/csrfToken`;
  return fetchApi(path);
}

// POST
export function restPostAuthSessionFbLogin({ idToken, csrfToken }) {
  const path = `/auth/sessionFbLogin`;
  return fetchApi(path, {
    method: "POST",
    body: { idToken, csrfToken }
  });
}

export function restPostAuthChangeName({ name, displayMobile }) {
  const path = `/auth/changeName`;
  return fetchApi(path, {
    method: "POST",
    body: { name, displayMobile }
  });
}

export function restPostAuthChangeMyCompany({ companyId }) {
  const path = `/auth/changeMyCompany`;
  return fetchApi(path, {
    method: "POST",
    body: { companyId }
  });
}

export function restPostAuthChangeMyPerson({ personNo }) {
  const path = `/auth/changeMyPerson`;
  return fetchApi(path, {
    method: "POST",
    body: { personNo }
  });
}

export function restPostAuthChangePassword({ password }) {
  const path = `/auth/changePassword`;
  return fetchApi(path, {
    method: "POST",
    body: { password }
  });
}
