<script>
  import { onMount } from "svelte";
  import { Router, Route } from "svelte-routing";

  import {
    loading,
    getRatingPersonDetail
  } from "../../stores/personRating/ratingDetail";

  import ByCategory from "./ByCategory.svelte";
  import ByQuestion from "./ByQuestion.svelte";
  import ByQuestionNext from "./ByQuestionNext.svelte";

  import Page404 from "../common/Page404.svelte";

  export let location;
  export let wildcardName;

  location;
  wildcardName;

  export let ratingPersonId;

  function getData() {
    getRatingPersonDetail({
      ratingPersonId: ratingPersonId
    });
  }

  onMount(() => {
    getData();
  });
</script>

{#if $loading}
  <div class="loader-block" />
{:else}
  <Router>
    <Route path="/" component={ByCategory} {ratingPersonId} {getData} />
    <Route
      path="/questions/:categoryId"
      component={ByQuestion}
      {ratingPersonId}
      {getData} />
     <Route
      path="/questions-next/:categoryId"
      component={ByQuestionNext}
      />
    <Route component={Page404} />
  </Router>
{/if}
