import { writable } from "svelte/store";

import { restGetEabsenHistoryListCache } from "../../api/eabsen";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

export let listData = writable([]);

// DATE CONFIG

export async function getMyEabsenHistory() {
  error.set("");
  loading.set(true);
  listData.set([]);

  try {
    const resp = await restGetEabsenHistoryListCache({
      personId: window.myPersonId,
    });
    if (resp && resp.data) {
      listData.set(resp.data);
    }
    loading.set(false);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
