<script>
  import { fly } from "svelte/transition";
  export let onClose;
  export let onSubmit;
  export let isBackdrop = true;
  export let title = "Konfirmasi";
  export let msg = "Yakin?";
  export let yesStyle = "teal white-text";
  export let yesText = "Ya";
  export let noStyle = "grey white-text";
  export let noText = "Tidak";

  let error = "";

  function clickClose() {
    let error = "";
    onClose();
  }
  function clickOk() {
    let error = "";
    onSubmit();
  }

  function onError(err) {
    error = err;
  }
</script>

<style>
  .btn-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
</style>

<div
  class="modal-frame"
  on:click={e => {
    if (e.target.classList.contains('modal-frame')) {
      if (isBackdrop) {
        onClose();
      }
    }
  }}>
  <div transition:fly={{ y: 200 }} class="modal-container">
    <div class="card">
      <div class="card-content">
        <div class="card-title">{title}</div>
        <p>{msg}</p>
        <slot />
        <div class="red-text">{error}</div>
      </div>

      <div class="card-action">
        <div class="btn-flex">
          <button on:click={clickClose} type="button" class="btn {noStyle}">
            {noText}
          </button>
          <button on:click={clickOk} type="button" class={`btn ${yesStyle}`}>
            {yesText}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
