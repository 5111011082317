import { writable } from "svelte/store";
import { navigate } from "svelte-routing";

import { restPostTaskAssignmentDelete } from "../../api/taskAssignment";
import { errorNotif, warningNotif } from "../../helpers/notification";

export let error = writable("");
export let loading = writable(false);
export let showModal = writable(false);

export async function postTaskAssignmentDelete({
  taskAssignmentId,
  detailData,
  filterName
}) {
  error.set("");
  loading.set(true);

  const oldDetailData = { ...detailData };
  try {
    await restPostTaskAssignmentDelete({
      taskAssignmentId
    });
    showModal.set(false);
    loading.set(false);

    warningNotif(`Berhasil hapus tugas ${oldDetailData.desc}`);
    setTimeout(() => {
      navigate(
        `/task-assignment/subordinate/${oldDetailData.personId}/task/${filterName}`
      );
    }, 500);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
