<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";

  const todayMoment = window.moment();

  onMount(() => {
    navigate(
      `/my-activity/by-date/${todayMoment.format("YYYYMMDD")}/filter/all`
    );
  });
</script>
