import { writable } from "svelte/store";

import { restGetActivityPersonByDateListCache } from "../../api/activityPerson";

import { errorNotif } from "../../helpers/notification";

import { serverTime, currentDate, setDatesNav } from "./myActivityDates";

export let loading = writable(false);
export let error = writable("");

export let listData = writable([]);
export const dataStatus = writable({
  "20": 0,
  "40": 0,
  "50": 0,
  "60": 0,
  total: 0
});

// DATE CONFIG

export async function getActivityPersonByDateListCache({ dateString, next }) {
  error.set("");
  loading.set(true);
  listData.set([]);

  // SET DATES
  serverTime.set(0);
  currentDate.set(parseInt(`${dateString}`, 10));
  setDatesNav();
  try {
    const resp = await restGetActivityPersonByDateListCache({
      personId: window.myPersonId,
      date: dateString
    });
    transformAndSet(resp, next);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}

function transformAndSet(resp, next) {
  let totalStatus = 0;

  if (resp.data && resp.data.status) {
    const statusKeys = Object.keys(resp.data.status);
    statusKeys.forEach(key => {
      totalStatus += resp.data.status[key];
    });
  }
  resp.data.status.total = totalStatus;

  listData.set(resp.data.activities);
  dataStatus.set(resp.data.status);

  // SET DATES
  serverTime.set(resp.serverTime);
  currentDate.set(resp.filter.date);
  setDatesNav();
  if (next) {
    next();
  }
  loading.set(false);
}
