<script>
  import { onMount } from "svelte";

  let time = new Date();

  // these automatically update when `time`
  // changes, because of the `$:` prefix
  $: hours = time.getHours();
  $: minutes = time.getMinutes();
  $: seconds = time.getSeconds();

  onMount(() => {
    const interval = setInterval(() => {
      time = new Date();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
</script>

<style>
  .main {
    color: #b71c1c;
  }
  .time-val {
    font-size: 20px;
  }
</style>

<div class="main">
  <div class="time-val">
    <span>
      <i class="icon fa fa-clock-o" />
      &nbsp;
    </span>
    <span>{hours}</span>
    :
    <span>{minutes}</span>
    :
    <span>{seconds}</span>
  </div>

</div>
