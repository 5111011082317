import { writable } from "svelte/store";

import { restGetPersonMonthlyHistoryDetail } from "../../api/personMonthlyHistory";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(true);
export let error = writable("");
export let data = writable({});

export let showModalAtt = writable(false);
export let showModalActivity = writable(false);

export async function getPersonMonthlyHistoryDetail({ selectedMonth }) {
  error.set("");
  loading.set(true);
  data.set({});
  try {
    const resp = await restGetPersonMonthlyHistoryDetail({
      selectedMonth
    });
    loading.set(false);

    data.set(resp.data);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
