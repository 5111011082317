import { writable, get } from "svelte/store";
import { navigate } from "svelte-routing";

import { fbAuth } from "../../api/firebase";
import { restPostAuthChangeMyPerson } from "../../api/auth";

import { successNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

// FORMDATA
export let inputPersonNo = writable("");

export async function changeMyPerson() {
  error.set("");
  // let personTypeInput = get(personType);
  let personNoInput = get(inputPersonNo);

  // if (!personTypeInput) {
  //   const errMsg = 'Tipe pegawai tidak boleh kosong';
  //   error.set(errMsg);
  //   return;
  // }

  if (!personNoInput) {
    const errMsg = "Nomor tidak boleh kosong";
    error.set(errMsg);
    return;
  }

  personNoInput = personNoInput.trim();
  inputPersonNo.set(personNoInput);

  if (personNoInput.length < 3) {
    const errMsg = "terlalu pendek";
    error.set(errMsg);
    return;
  }

  if (personNoInput.length !== 18) {
    const errMsg = "No Induk harus 18 angka";
    error.set(errMsg);
    return;
  }

  loading.set(true);

  try {
    await restPostAuthChangeMyPerson({
      personNo: personNoInput
    });

    inputPersonNo.set("");
    successNotif("Berhasil");
    const currentUser = fbAuth.currentUser;

    if (currentUser) {
      if (currentUser) {
        await fbAuth.currentUser.getIdToken(true);

        navigate("/");
        setTimeout(() => {
          window.location.reload();
        }, 6000);
      }
      return;
    }
  } catch (err) {
    error.set(err);
  }

  if (get(loading)) {
    loading.set(false);
  }
}
