<script>
  import { onMount, tick } from "svelte";
  import { Link, navigate } from "svelte-routing";

  import {
    loading,
    selectedPerson,
    questionCategories,
    questionListData,
    saveAnswer
  } from "../../stores/personRating/ratingDetail";

  import { HeaderNav } from "../../components/common";
  import { PersonBoxTop, QuestionInputItem } from "./components";

  export let location;
  location;

  export let ratingPersonId;
  export let categoryId;
  export let getData;

  let allQuestionAnswered = false;
  let avarage = 0;

  let questionForm = {};
  let questionFormKeys = [];
  let selectedCategory = $questionListData[categoryId];
  let selectedIndex = $questionCategories.indexOf(categoryId);

  function loadQuestionForm() {
    if (selectedCategory) {
      questionForm = selectedCategory.questions;
      questionFormKeys = Object.keys(questionForm);
      checkQuestionAnswered();
    }
  }

  function checkQuestionAnswered() {
    allQuestionAnswered = true;
    let total = 0;
    let each = 0;
    avarage = 0;
    questionFormKeys.forEach(key => {
      if (questionForm[key].value === 0) {
        allQuestionAnswered = false;
      } else {
        total += questionForm[key].value;
        each++;
      }
    });

    if (each > 0) {
      avarage = Math.ceil(total / each);
    }
  }

  onMount(() => {
    loadQuestionForm();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 01px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName={selectedCategory && selectedCategory.title}
  backURL={`/person-rating/categories/${ratingPersonId}`}
  onRefresh={getData} />
<div class="root-container section no-pad-bot">
  <div class="container">
    {#if $selectedPerson && $selectedPerson.personId}
      <PersonBoxTop data={$selectedPerson} />
    {/if}
    {#if questionFormKeys && questionFormKeys.length > 0}
      <div class="card-panel p-h-0 p-t-0 p-b-12">
        <ul class="collection p-0">
          <li class="collection-item p-l-10 p-r-10 center-align">
            <b>
              {selectedCategory.title} {avarage}
              <i class="fa fa-percent" />
            </b>
          </li>
          {#each questionFormKeys as key}
            <QuestionInputItem
              data={questionForm[key]}
              onChange={v => {
                checkQuestionAnswered();
                questionForm[key] = v;
              }} />
          {/each}
        </ul>
        <div class="m-10">
          <button
            on:click={() => {
              saveAnswer({
                isNext: true,
                ratingPersonId,
                categoryId,
                questionForm,
                selectedIndex,
                selectedCategory,
                nextFunc: getData
              });
            }}
            type="button"
            class="btn btn-small blue full-width">
            {#if selectedIndex + 1 < $questionCategories.length}
              Selanjutnya
            {:else}Selesai{/if}
          </button>
        </div>
        <div class="m-10">

          <button
            on:click={() => {
              saveAnswer({
                isNext: false,
                ratingPersonId,
                categoryId,
                questionForm,
                selectedIndex,
                selectedCategory,
                nextFunc: getData
              });
            }}
            class="btn btn-small grey full-width">
            Kembali
          </button>

        </div>
      </div>
    {/if}
  </div>
</div>
