<script>
  import {
    allOrgData,
    childExpand,
    childHide
  } from "../../../stores/org/myOrgStructure";

  import OrgPerson from "./OrgPerson.svelte";
  import OrgChildList from "./OrgChildList.svelte";
</script>

{#if $allOrgData && $allOrgData.id}
  <ul class="tree">
    <li>
      {#if $allOrgData.hasChild}
        <!-- CLICKABLE AREA -->
        <span
          on:click={() => {
            if ($allOrgData.childExpand) {
              return childHide($allOrgData.clientPath, $allOrgData.id);
            }
            if (!$allOrgData.childExpand) {
              return childExpand($allOrgData.clientPath, $allOrgData.id);
            }
          }}
          class="cursor-pointer button-expand button-parent">
          &nbsp;&nbsp;
        </span>
        <!-- CLICKABLE AREA -->
      {/if}

      <div
        class={`${$allOrgData.childExpand ? 'tree-icon active' : 'tree-icon'} ${!$allOrgData.hasChild ? 'no-icon' : ''}`} />

      <label class="tree_label ">
        <OrgPerson data={$allOrgData} />
      </label>
      {#if $allOrgData.children && Object.keys($allOrgData.children).length > 0}
        <OrgChildList children={$allOrgData.children} />
      {/if}
    </li>
  </ul>
{/if}
