<script>
  import { Link } from "svelte-routing";
  import moment, {
    momentDateMonth,
    momentCreatedAt,
    momentUpdatedAt
  } from "../../../helpers/moment";
  import { StatusLabel, Img } from "../../../components/common";

  const currentDate = moment().format("YYYYMMDD");
  export let task;
  export let filterName;

  export let supervisorId;
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
</style>

<li class="collection-item p-h-14 p-v-14">
  <Link
    to={`/my-task-assignment/supervisor/${supervisorId}/task/${filterName}/detail/${task.id}`}>
    <div class="person-box">
      <div class="font-12">
        <Img
          className="circle img-width"
          alt="dari"
          personId={task.createdBy} />
      </div>
      <div class="full-width m-l-14">
        <div class="font-12 black-text">{task.desc}</div>
        {#if task.updatedAt || task.createdAt}
          <div class="font-10 grey-text">
            <i class="fa fa-clock-o" />
            {moment(task.createdAt).format('DD MMM YYYY HH:mm')}
            {#if task.updatedAt}
              - {moment(task.updatedAt).format('DD MMM YYYY HH:mm')}
            {/if}
          </div>
        {/if}
        {#if task.deadline}
          <div class="font-10 red-text text-darken-4">
            <i class="fa fa-calendar" />
            {momentDateMonth(task.deadline)}
          </div>
          <div class="font-10 red-text text-darken-4">
            {#if Number(currentDate) <= Number(task.deadline)}
              Tenggat waktu {moment(task.deadline, 'YYYYMMDD').fromNow()}
            {:else}Sudah melewati tenggat waktu{/if}
          </div>
        {/if}
        <div class="font-10 grey-text">
          Progress: {task.progress ? task.progress : 0}%
        </div>
      </div>
      <div>
        <div>
          <StatusLabel type="jobAssignment" status={task.status} />
        </div>
      </div>
    </div>

  </Link>
</li>
