<script>
  import { onMount } from "svelte";

  import {
    loading,
    showModal,
    selectedPerson,
    questionCategories,
    questionListData,
    questionPercentageData,
    saveSummary
  } from "../../stores/personRating/ratingDetail";

  import { Link, navigate } from "svelte-routing";
  import { HeaderNav, Footer } from "../../components/common";
  import { ModalConfirm } from "../../components/modals";
  import { PersonBoxTop, QuestionCategoryItem } from "./components";

  export let location;
  location;

  export let ratingPersonId;
  export let getData;
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 01px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

{#if $showModal}
  <ModalConfirm
    onClose={() => {
      showModal.set(false);
    }}
    onSubmit={() => {
      saveSummary({ ratingPersonId });
    }}
    title="Konfirmasi"
    msg="">
    <p>
      hasil akhir adalah
      <br />
      <b class="font-18">
        {$selectedPerson.totalPercentage}
        <i class="fa fa-percent" />
      </b>
      <br />
      <span class="red-text">
        <b>"DATA TIDAK DAPAT DIUBAH KEMBALI"</b>
      </span>
      <br />
      Yakin selesai menilai?
    </p>
  </ModalConfirm>
{/if}

<HeaderNav
  headerSize="s"
  headerName="Penilaian Perilaku"
  backURL={`/person-rating`}
  onRefresh={getData} />

<div class="root-container section no-pad-bot">
  <div class="container">
    {#if $selectedPerson && $selectedPerson.personId}
      <PersonBoxTop data={$selectedPerson} />
    {/if}
    {#if $questionCategories && $questionCategories.length > 0}
      <div class="card-panel p-h-0 p-t-0 p-b-12">
        <ul class="collection p-0">
          {#each $questionCategories as categoryId}
            <QuestionCategoryItem
              data={{ id: categoryId, title: $questionListData[categoryId].title, percentage: $questionPercentageData[categoryId] }}
              {ratingPersonId}
              detailURL={`/person-rating/categories/${ratingPersonId}/questions/${categoryId}`} />
          {/each}
        </ul>
        <div class="m-10">
          <button
            on:click={() => {
              showModal.set(true);
            }}
            type="button"
            class="btn btn-small blue full-width">
            Selesai Penilaian
          </button>
        </div>
        <div class="m-10">
          <Link to={`/person-rating`}>
            <button class="btn btn-small grey full-width">Kembali</button>
          </Link>
        </div>
      </div>
    {/if}
  </div>
</div>
