import { writable, get } from "svelte/store";
import { navigate } from "svelte-routing";

import { restPostTaskAssignmentComment } from "../../api/taskAssignment";

import { errorNotif, successNotif } from "../../helpers/notification";

import { getTaskAssignmentDetail } from "./taskDetail";

export let error = writable("");
export let loading = writable(false);
export let showModal = writable(false);

// Formdata
export let inputReason = writable("");

export async function rejectTask({ taskAssignmentId, detailData, filterName }) {
  const formData = {
    action: "supervisorReject",
    reason: get(inputReason)
  };

  error.set("");
  loading.set(true);
  try {
    await restPostTaskAssignmentComment({
      taskAssignmentId,
      formData
    });
    loading.set(false);
    inputReason.set("");
    showModal.set(false);
    successNotif(`Berhasil tolak tugas`);
    getTaskAssignmentDetail({ taskAssignmentId });
    setTimeout(() => {
      navigate(
        `/task-assignment/subordinate/${detailData.personId}/task/${filterName}`
      );
    }, 500);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
