import fetchApi from "./fetchApi";

// GET
export function restGetListThreeMonth({ currentMonth }) {
  const path = `/person-monthly-history/list-three-month/${currentMonth}`;
  return fetchApi(path, {
    method: "GET"
  });
}

export function restGetPersonMonthlyHistoryDetail({ selectedMonth }) {
  const path = `/person-monthly-history/detail/${selectedMonth}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
