import { writable, get } from "svelte/store";
import { fbAuth } from "../../api/firebase";

import { loading as loadingAuth } from "./myUser";

export let loading = writable(false);
export let error = writable("");

export let inputMobile = writable("");
export let inputPassword = writable("");

export async function login() {
  let mobile = get(inputMobile);
  let password = get(inputPassword);
  error.set("");

  if (!mobile) {
    return error.set("No Handphone minimal 10 angka");
  }
  if (!password) {
    return error.set("password minimal 6 karakter");
  }
  mobile = mobile.trim();
  password = password.trim();

  if (password.length < 6) {
    return error.set("password minimal 6 karakter");
  }
  if (mobile.length < 10) {
    return error.set("No Handphone minimal 10 angka");
  }

  let emailPhone = `${mobile}`;
  if (emailPhone.startsWith("08")) {
    emailPhone = emailPhone.replace("08", "+628");
  }
  emailPhone = `m${emailPhone.replace("+", "")}@eabsen.com`;
  if (!get(loading)) {
    loading.set(true);
  }
  if (!get(loadingAuth)) {
    loadingAuth.set(true);
  }
  try {
    await fbAuth.signInWithEmailAndPassword(emailPhone, password);
    if (get(loading)) {
      loading.set(false);
    }
  } catch (err) {
    if (get(loading)) {
      loading.set(false);
    }
    if (get(loadingAuth)) {
      loadingAuth.set(false);
    }

    let errMsg = err.message;
    if (err.code === "auth/user-not-found") {
      errMsg = "Nomor hp belum terdaftar ";
    }

    error.set(errMsg);
  }
}
