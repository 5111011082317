<script>
  import { onMount } from "svelte";

  import { formatCurrency } from "../../helpers/number.js";
  import {
    loading,
    data,
    showModalAtt,
    showModalActivity,
    getPersonMonthlyHistoryDetail
  } from "../../stores/personMonthlyHistory/detail";

  import { HeaderNav, Footer } from "../../components/common";
  import { ModalAtt, ModalActivity } from "./modals";

  export let selectedMonth;

  function getData() {
    getPersonMonthlyHistoryDetail({
      selectedMonth
    });
  }

  function countpercentage(a, t, maxP) {
    if (a < 1) {
      return 0;
    }
    const total = a / t;
    const percentage = (total * maxP).toFixed(2);
    const percentageSplit = `${percentage}`.split(".");
    if (percentageSplit[1] === "00") {
      return percentageSplit[0];
    }
    return percentage;
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px;
  }
  .container {
    margin: 0px;
    width: 100% !important;
  }

  .point-list {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
  }
  .point-item {
    display: block;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 14px;
  }

  @media only screen and (min-width: 360px) {
    .point-item {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      border-bottom: 1px solid #e0e0e0;
      padding: 14px;
    }
  }
  .point-item:last-child {
    border-bottom: none;
  }
  .m-b-100 {
    margin-bottom: 100px !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<ModalAtt />
<ModalActivity />

<HeaderNav
  headerSize="s"
  headerName="Detail Pencapaian"
  backURL={`/person-monthly-history`}
  onRefresh={getData} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="point-list">

      <div class="point-item">
        <div>Detail Pencapaian</div>

        <div>
          <b>
            {selectedMonth ? window
                  .moment(selectedMonth, 'YYYYMM')
                  .format('MMMM YYYY ') : ''}
          </b>
        </div>
      </div>
      <div class="point-item">
        <div>Nama</div>

        <div>{$data.personName || ''}</div>
      </div>
      <div class="point-item">
        <div>No Induk</div>

        <div>{$data.personNo || ''}</div>
      </div>
    </div>

    {#if $data.componentAmount && $data.componentMoney && $data.componentPercentage}
      <div class="point-list m-t-16 m-b-100">
        <div class="point-item">
          <div>
            <b>Komponen</b>
          </div>

          <div>
            <b>Capaian</b>
            / Target
          </div>
        </div>

        <div class="point-item">
          <div>Serapan Anggaran</div>

          <div>
            <div class="right-align">
              <b>{formatCurrency($data.componentAmount.mainOrgCredit)}</b>
              / {formatCurrency($data.componentMoney.mainOrgCredit)}
            </div>
            <div class="right-align">
              <b>
                {countpercentage($data.componentAmount.mainOrgCredit, $data.componentMoney.mainOrgCredit, $data.componentPercentage.mainOrgCredit)}%
              </b>
              / {$data.componentPercentage.mainOrgCredit}%
            </div>
          </div>

        </div>

        <div class="point-item">
          <div
            on:click={() => {
              showModalAtt.set(true);
            }}
            class="blue-text cursor-pointer">
            Disiplin
          </div>

          <div>
            <div class="right-align">
              <b>{formatCurrency($data.componentAmount.att)}</b>
              / {formatCurrency($data.componentMoney.att)}
            </div>
            <div class="right-align">
              <b>
                {countpercentage($data.componentAmount.att, $data.componentMoney.att, $data.componentPercentage.att)}%
              </b>
              / {$data.componentPercentage.att}%
            </div>
          </div>
        </div>

        <div class="point-item">
          <div
            on:click={() => {
              showModalActivity.set(true);
            }}
            class="blue-text cursor-pointer">
            Poin Aktivitas
          </div>

          <div>
            <div class="right-align">
              <b>{formatCurrency($data.componentAmount.point)}</b>
              / {formatCurrency($data.componentMoney.point)}
            </div>
            <div class="right-align">
              <b>
                {countpercentage($data.componentAmount.point, $data.componentMoney.point, $data.componentPercentage.point)}%
              </b>
              / {$data.componentPercentage.point}%
            </div>
          </div>
        </div>

        <div class="point-item">
          <div>Penilaian Perilaku</div>

          <div>
            <div class="right-align">
              <b>{formatCurrency($data.componentAmount.rating)}</b>
              / {formatCurrency($data.componentMoney.rating)}
            </div>
            <div class="right-align">
              <b>
                {countpercentage($data.componentAmount.rating, $data.componentMoney.rating, $data.componentPercentage.rating)}%
              </b>
              / {$data.componentPercentage.rating}%
            </div>
          </div>

        </div>

        <div class="point-item">
          <div>Penugasan Atasan</div>

          <div>
            <div class="right-align">
              <b>{formatCurrency($data.componentAmount.supervisorTask)}</b>
              / {formatCurrency($data.componentMoney.supervisorTask)}
            </div>
            <div class="right-align">
              <b>
                {countpercentage($data.componentAmount.supervisorTask, $data.componentMoney.supervisorTask, $data.componentPercentage.supervisorTask)}%
              </b>
              / {$data.componentPercentage.supervisorTask}%
            </div>
          </div>
        </div>

        <div class="point-item">
          <div>Indikator Kinerja</div>

          <div>
            <div class="right-align">
              <b>{formatCurrency($data.componentAmount.program)}</b>
              / {formatCurrency($data.componentMoney.program)}
            </div>
            <div class="right-align">
              <b>
                {countpercentage($data.componentAmount.mainOrgCredit, $data.componentMoney.mainOrgCredit, $data.componentPercentage.mainOrgCredit)}%
              </b>
              / {$data.componentPercentage.mainOrgCredit}%
            </div>
          </div>
        </div>

        <div class="point-item">
          <div>

            <b>TOTAL</b>

          </div>

          <div>
            <div class="right-align">
              <b>
                {formatCurrency($data.benefitAmount)}/{formatCurrency($data.personBenefitTotal)}
              </b>
            </div>
            <div class="right-align">
              <b>
                {countpercentage($data.benefitAmount, $data.personBenefitTotal, 100)}%
              </b>
              / {100}%
            </div>
          </div>
        </div>

      </div>
    {/if}

  </div>

</div>
