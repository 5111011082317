import { tick } from "svelte";
import { navigate } from "svelte-routing";

import { writable } from "svelte/store";

import { restPostActivityPersonUpdateStatus } from "../../api/activityPerson";
import { ACTIVITY_STATUS } from "../../helpers/constants";

import { warningNotif, errorNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

export let showModal = writable(false);

export async function postReject({ personId, activityId, reason, onError }) {
  error.set("");
  if (!reason) {
    onError("alasan harus diisi");
    return;
  }
  loading.set(true);

  const formData = {
    activityId,
    reason,
    status: ACTIVITY_STATUS.REJECT
  };
  // SET DATES
  try {
    await restPostActivityPersonUpdateStatus({
      formData
    });
    warningNotif(`Berhasil tolak aktivitas dengan alasan "${reason}"`);
    showModal.set(false);
    await tick();
    navigate(`/my-sub-activity/person/${personId}`);
  } catch (err) {
    console.warn(err);
    error.set(err);
    onError(err);
    errorNotif(err);
  }

  loading.set(false);
}
