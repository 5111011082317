import { writable } from "svelte/store";

import { restGetCompanyDetailCache } from "../../api/company";

import { warningNotif } from "../../helpers/notification";

import { getPersonCache } from "../person/myPerson";

export let loading = writable(true);
export let error = writable("");

export let data = writable({});

export function initialState() {
  loading.set(true);
  error.set("");
  data.set({});
}

export async function getCompanyDetailCache() {
  loading.set(true);
  try {
    const resp = await restGetCompanyDetailCache({
      companyId: window.companyId
    });
    if (resp && resp.data) {
      data.set(resp.data);
    }
    nextGlobalSequence();
  } catch (err) {
    console.warn(err);
    error.set(err);
    // warningNotif("Kode undangan tidak ditemukan");
  }

  loading.set(false);
}

function nextGlobalSequence() {
  // Mulai Masuk Auth Layout
  getPersonCache();
}
