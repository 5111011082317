import { writable } from "svelte/store";
import { restGetNewsListCache } from "../../api/news";

export const loading = writable(false);
export const error = writable("");

export const data = writable([]);
export const selectedData = writable({});

export function initialState() {
  loading.set(false);
  error.set("");
  data.set([]);
  selectedData.set({});
}

export async function getNewsList(newQueryParams) {
  error.set("");
  loading.set(true);
  try {
    const resp = await restGetNewsListCache(newQueryParams);
    loading.set(false);

    const newData = [];
    if (resp && resp.data && resp.data.length > 0) {
      resp.data.forEach(d => {
        if (d.title) {
          newData.push(d);
        }
      });
    }
    data.set(newData);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
  }
}
