import { writable, get } from "svelte/store";

import {
  restGetOrgStructureCache,
  restGetOrgStructureChildListCache
} from "../../api/org";

import { data as myPersonData } from "../person/myPerson";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(true);
export let error = writable("");

export let mainOrgData = writable({});
export let allOrgData = writable({});

export async function getMainOrg() {
  loading.set(true);
  allOrgData.set({});
  const myPerson = get(myPersonData);
  try {
    const resp = await restGetOrgStructureCache({
      companyId: window.companyId,
      orgId: myPerson.mainOrgId
    });
    if (resp && resp.data) {
      resp.data.clientPath = [];
      resp.data.children = {};

      resp.data.ownerId = resp.data.personId;
      resp.data.ownerName = resp.data.personName;
      resp.data.ownerNo = resp.data.personNo;
      resp.data.ownerMobile = resp.data.personMobile;
      mainOrgData.set(resp.data);
      resp.data.hasChild = false;
      if (resp.data.childCount && resp.data.childCount > 0) {
        resp.data.hasChild = true;
        resp.data.childLoad = false;
        resp.data.childExpand = false;
      }
      allOrgData.set(resp.data);
      // Directly load first child
      childExpand(resp.data.clientPath, resp.data.id);
    }
  } catch (err) {
    console.warn(err);
    errorNotif(err);
    error.set(err);
  }

  loading.set(false);
}

export function childExpand(clientPath, selectedId) {
  const curAllOrgData = get(allOrgData);
  let selectedOrg = {};
  if (clientPath.length < 1) {
    selectedOrg = curAllOrgData;
  } else {
    selectedOrg = findSelectedOrg(
      { ...curAllOrgData },
      [...clientPath],
      selectedId,
      0
    );
  }

  if (!selectedOrg.childLoad) {
    return childLoad(curAllOrgData, selectedOrg);
  } else {
    selectedOrg.childExpand = true;
    allOrgData.set(curAllOrgData);
  }
}

export function childHide(clientPath, selectedId) {
  const curAllOrgData = get(allOrgData);
  let selectedOrg = {};
  if (clientPath.length < 1) {
    selectedOrg = curAllOrgData;
  } else {
    selectedOrg = findSelectedOrg(
      { ...curAllOrgData },
      [...clientPath],
      selectedId,
      0
    );
  }
  selectedOrg.childExpand = false;
  allOrgData.set(curAllOrgData);
}

async function childLoad(curAllOrgData, selectedOrg) {
  loading.set(true);
  try {
    const resp = await restGetOrgStructureChildListCache({
      companyId: window.companyId,
      parentId: selectedOrg.id
    });

    selectedOrg.childLoad = true;
    selectedOrg.childExpand = true;

    if (resp && resp.data) {
      resp.data.forEach(obj => {
        obj.ownerId = obj.personId;
        obj.ownerName = obj.personName;
        obj.ownerNo = obj.personNo;
        obj.ownerMobile = obj.personMobile;

        obj.hasChild = false;
        if (obj.childCount && obj.childCount > 0) {
          obj.children = {};
          obj.hasChild = true;
          obj.childLoad = false;
          obj.childExpand = false;
        }
        obj.clientPath = [...selectedOrg.clientPath, selectedOrg.id];

        // Set client path from mainOrgId
        selectedOrg.children[obj.id] = obj;
      });
    }

    allOrgData.set({});
    allOrgData.set(curAllOrgData);
  } catch (err) {
    console.warn(err);
    errorNotif(err);
    error.set(err);
  }

  loading.set(false);
}

function findSelectedOrg(pointer, clientPath, selectedId, idx) {
  let endResult = {};
  if (pointer.children && pointer.children[selectedId]) {
    endResult = pointer.children[selectedId];
    return endResult;
  } else {
    idx = idx + 1;
    pointer = pointer.children[clientPath[idx]];
    if (pointer && pointer.id) {
      findSelectedOrg(pointer, clientPath, selectedId, idx);
    } else {
      return endResult;
    }
  }
}
