<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";
  import { data as myPersonData } from "../../stores/person/myPerson";
  import { HeaderNav, TipsBox } from "../../components/common";
  import { Img } from "../../components/common";

  import Clock from "./components/Clock.svelte";
  import {
    loading,
    dataEabsen,
    getCurrentEabsen,
    eabsenCheckIn,
    eabsenCheckOut
  } from "../../stores/eabsen/myEabsenByDate.js";

  export let location;
  location;

  let longitude;
  let latitude;

  function getLocation() {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(showPosition);
    }
  }

  function showPosition(position) {
    latitude = position.coords.latitude;
    longitude = position.coords.longitude;
    // console.log(`https://www.google.co.id/maps/@${latitude},${longitude},18z`);
  }

  function getData() {
    getLocation();
    getCurrentEabsen();
  }

  function checkIn() {
    eabsenCheckIn({ latitude, longitude });
  }

  function checkOut() {
    eabsenCheckOut({ latitude, longitude });
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px;
  }
  .container {
    margin: 0px auto;
    width: 93% !important;
  }
  .profile-sum {
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .basic {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    font-size: 12px;
  }
  .info {
    margin-left: 16px;
    font-size: 12px;
    width: 80%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
  }
  .photo-container {
    width: 20%;
    min-height: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .btn-group {
    padding-top: 14px;
    width: 100%;
  }
  .history {
    font-size: 18px;
    color: #2196f3;
    margin-top: 40px;
  }

  .warning-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="eAbsensi Online"
  backURL={`/home`}
  onRefresh={() => {
    getData();
  }} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class={`card-panel white-text m-0 p-v-10 p-h-0 red`}>
      <div class="container">
        <div class="warning-content">
          <div class="full-width">
            <a
              href="https://telegram.me/bkdsulut_bot"
              target="_blank"
              class="white-text">
              Silahkan Join bot Telegram untuk notifikasi otomatis dan informasi
              kepegawaian, klik di sini
            </a>
          </div>
        </div>
      </div>
    </div>
    {#if !$loading && $dataEabsen}
      <div class="profile-sum">
        <div class="basic card-panel">
          <div class="photo-container">
            <Img
              className="home-avatar circle"
              personId={$myPersonData.id}
              alt={$myPersonData.name} />
          </div>
          <div class="info">
            <div class="field">
              <i class="label fa fa-user" />
              <span class="name">{$myPersonData.name}</span>
            </div>
            <div class="field">
              <i class="label fa fa-id-card" />
              <span class="no">{$myPersonData.no}</span>
            </div>
            <div class="field">
              <i class="label fa fa fa-calendar" />
              <span class="date">
                {window.moment().format('dddd, DD/MMMM/YYYY')}
              </span>
            </div>
            <div class="field checkIn">
              <i class="label fa fa-clock-o" />
              <span class="">Absensi Mulai Kerja</span>
            </div>
            <div class="field checkIn-data">
              <b class="">
                {$dataEabsen.checkInTime ? window
                      .moment($dataEabsen.checkInTime, 'HHmm')
                      .format('HH:mm') : 'BELUM'}
              </b>
              &nbsp;
              {#if $dataEabsen.checkInLoc}
                <a
                  target="_blank"
                  href={`https://maps.google.com?q=${$dataEabsen.checkInLoc.latitude},${$dataEabsen.checkInLoc.longitude}&z=15`}>
                  <i class="fa fa fa-map-marker" />
                  <span>LOKASI</span>
                </a>
              {/if}
            </div>
            <div class="field checkOut">
              <i class="label fa fa-clock-o" />
              <span class="">Absensi Selesai Kerja</span>
            </div>
            <div class="field checkOut-data">
              <b class="">
                {$dataEabsen.checkOutTime ? window
                      .moment($dataEabsen.checkOutTime, 'HHmm')
                      .format('HH:mm') : 'BELUM'}
              </b>
              &nbsp;
              {#if $dataEabsen.checkOutLoc}
                <a
                  target="_blank"
                  href={`https://maps.google.com?q=${$dataEabsen.checkOutLoc.latitude},${$dataEabsen.checkOutLoc.longitude}&z=15`}>
                  <i class="fa fa fa-map-marker" />
                  <span>LOKASI</span>
                </a>
              {/if}
            </div>
          </div>

        </div>
      </div>
      <div class="main-container">
        <Clock />
        <div class="btn-group">
          <button
            on:click={checkIn}
            type="button"
            class="btn full-width m-b-20">
            <i class="label fa fa-clock-o" />
            Absensi Mulai Kerja
          </button>
          <br />
          <br />
          <br />
          <button
            on:click={checkOut}
            type="button"
            class="btn blue full-width m-b-20">
            <i class="label fa fa-clock-o" />
            Absensi Selesai Kerja
          </button>
        </div>
        <div class="history">
          <Link to="/eabsen/history" getProps={() => ({ class: 'blue-text' })}>
            <div>Riwayat Absensi</div>
          </Link>
        </div>
      </div>
    {:else}
      <div class="error">.</div>
    {/if}

  </div>
</div>
