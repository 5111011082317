<script>
  export let fontClass = "";
  export let value = 0;
  export let color = "teal";
  export let onClickStar = value => {
    console.log("onClickStar", value);
  };
  const handleClick = input => {
    value = input;
    onClickStar(input);
  };
  const allStar = new Array(10);
  $: activeStar = Math.floor(value);
</script>

{#each allStar as star, index}
  {#if index + 1 <= activeStar}
    <i
      class={`fa fa-star ${color}-text cursor-pointer ${fontClass}`}
      on:click={() => {
        handleClick(index + 1);
      }} />
  {:else}
    <i
      class={`fa fa-star-o ${color}-text cursor-pointer ${fontClass}`}
      on:click={() => {
        handleClick(index + 1);
      }} />
  {/if}
{/each}
