import { writable } from "svelte/store";

import { restGetRatingPersonDetail } from "../../api/ratingPerson";

import { errorNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");

export let data = writable({});
export let selectedPerson = writable({});
export let questionCategories = writable([]);
export let questionListData = writable({});
export let questionPercentageData = writable({});

export async function getRatingPersonDetail({ ratingPersonId, next }) {
  error.set("");
  loading.set(true);
  data.set([]);
  try {
    const resp = await restGetRatingPersonDetail({
      ratingPersonId: ratingPersonId
    });

    transformAndSetData(resp, next);
  } catch (err) {
    console.warn(err);
    error.set(err);
    errorNotif(err);
    loading.set(false);
  }
}

function transformAndSetData(resp, next) {
  const newQuestionCategories = [];

  const newQuestionPercentageData =
    resp.data && resp.data.percentages ? resp.data.percentages : {};

  Object.keys(newQuestionPercentageData).forEach(c => {
    newQuestionCategories.push(c);
  });

  const newQuestionListData = resp.data && resp.data.data ? resp.data.data : {};

  let newSelectedPerson = {};
  if (resp.data.personId) {
    newSelectedPerson = {
      closed: resp.data.closed,
      personId: resp.data.personId,
      personName: resp.data.personName,
      personJobName: resp.data.personJobName,
      raterPersonName: resp.data.raterPersonName,
      month: resp.data.month,
      raterType: resp.data.raterType,
      totalPercentage: resp.data.totalPercentage
    };
  }

  questionCategories.set(newQuestionCategories);
  questionPercentageData.set(newQuestionPercentageData);
  questionListData.set(newQuestionListData);
  selectedPerson.set(newSelectedPerson);
  data.set(resp.data);

  loading.set(false);
  if (next) {
    next();
  }
}
