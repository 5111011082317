import { writable, get } from "svelte/store";
import { restPostAuthChangePassword } from "../../api/auth";

import { successNotif } from "../../helpers/notification";

import { logout } from "./myUser";
import { changeMobileNo } from "./otpForm";

export let loading = writable(false);
export let error = writable("");

export let inputPassword = writable("");
export let inputRePassword = writable("");

export async function setNewPassword() {
  error.set("");
  let passwordInput = get(inputPassword);
  let rePasswordInput = get(inputRePassword);

  if (!passwordInput) {
    const errMsg = "Password tidak boleh kosong";
    error.set(errMsg);
    return;
  }

  if (!rePasswordInput) {
    const errMsg = "Ulangi password tidak boleh kosong";
    error.set(errMsg);
    return;
  }
  passwordInput = passwordInput.trim();
  inputPassword.set(passwordInput);
  rePasswordInput = rePasswordInput.trim();
  inputRePassword.set(rePasswordInput);

  if (passwordInput.length < 6) {
    const errMsg = "Password baru minimal 6 karakter";
    error.set(errMsg);
    return;
  }
  if (passwordInput !== rePasswordInput) {
    const errMsg = "Password baru dan ulangi password baru harus sama";
    error.set(errMsg);
    return;
  }

  loading.set(true);

  try {
    await restPostAuthChangePassword({
      password: passwordInput
    });
    inputPassword.set("");
    inputRePassword.set("");

    loading.set(false);

    successNotif(
      "Password baru berhasil dipasangkan, silahkan login kembali dengan password baru"
    );
    logout();
    // Clear OTP FORM
    changeMobileNo();
  } catch (err) {
    error.set(err);
    if (get(loading)) {
      loading.set(false);
    }
  }
}
