import fetchApi from "./fetchApi";

// GET
export function restGetConfigDetail(configDetailId) {
  const path = `/config/detail/${window.companyId}/${configDetailId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
