<script>
  export let data;
</script>

<style>
  .activity-box {
    display: flex;
    align-content: flex-start;
    margin-top: -14px !important;
    margin-left: -14px;
    margin-right: -14px;
  }
</style>

<div class="activity-box">
  <div class="center-align font-16 full-width m-0 p-v-4">
    <div class="font-12">{data.personName}</div>
    {window
      .moment(data.startDate, 'YYYYMMDDHHmm')
      .format('dddd, DD MMMM YYYY HH:mm')}
  </div>
</div>
