import { writable, get } from "svelte/store";
import { fbAuth, newFirebaseRecaptchaVerifier } from "../../api/firebase";

const OTP_DURATION = 90;
let intervalOtpCounter;

export const loading = writable(false);
export const error = writable("");

export const otpCounter = writable(OTP_DURATION);

export const isReqOtpCode = writable(false);
export const isOtpSentConfirmed = writable(false);

// FORMDATA
export const inputMobile = writable("");
export const inputOtp = writable("");

export function resetOtpCounter() {
  if (intervalOtpCounter) {
    clearInterval(intervalOtpCounter);
    intervalOtpCounter = null;
  }
  otpCounter.set(OTP_DURATION);
  intervalOtpCounter = setInterval(() => {
    otpCounter.update(currentVal => {
      if (currentVal > 0) {
        return currentVal - 1;
      }
      if (intervalOtpCounter) {
        clearInterval(intervalOtpCounter);
        intervalOtpCounter = null;
      }
      return currentVal;
    });
  }, 1000);
}

export async function requestOtp() {
  error.set("");
  inputOtp.set("");
  let mobileInput = get(inputMobile);
  if (!mobileInput) {
    const errMsg = "No Handphone minimal 10 angka";
    error.set(errMsg);
    return;
  }
  mobileInput = mobileInput.trim();
  inputMobile.set(mobileInput);

  if (mobileInput.length < 10) {
    const errMsg = "No Handphone minimal 10 angka";
    error.set(errMsg);
    return;
  }

  const mobileDisplay = mobileInput;
  if (mobileInput.startsWith("08")) {
    mobileInput = mobileInput.replace("08", "+628");
  }

  if (window.recaptchaVerifier) {
    window.recaptchaVerifier.clear();
    document.getElementById("recaptchaContainer").innerHTML =
      '<div id="recaptchaWidget"></div>';
  }

  window.recaptchaVerifier = newFirebaseRecaptchaVerifier();
  if (!get(loading)) {
    loading.set(true);
  }
  try {
    const confirmationResult = await fbAuth.signInWithPhoneNumber(
      mobileInput,
      window.recaptchaVerifier
    );
    window.phoneConfirmationResult = confirmationResult;
    isReqOtpCode.set(true);
    resetOtpCounter();
    if (get(loading)) {
      loading.set(false);
    }
  } catch (err) {
    if (get(loading)) {
      loading.set(false);
    }
    window.phoneConfirmationResult = null;
    let errMsg = err.message ? err.message : err.code;
    if (err.code === "auth/too-many-requests") {
      errMsg = `Terlalu banyak request kirim sms ke ${mobileDisplay}, mohon untuk menunggu 60 menit, dan coba kembali`;
    }

    if (err.code === "auth/invalid-phone-number") {
      errMsg = `No handphone format salah`;
    }

    error.set(errMsg);
    return;
  }
}

export async function verifyOtp() {
  error.set("");
  let otpInput = get(inputOtp);

  if (!window.phoneConfirmationResult) {
    changeMobileNo();
    return;
  }

  if (!otpInput) {
    const errMsg = "Kode sms harus 6 angka";
    error.set(errMsg);
    return;
  }
  otpInput = String(otpInput).trim();

  if (otpInput.length !== 6) {
    const errMsg = "Kode sms harus 6 angka";
    error.set(errMsg);
    return;
  }

  if (get(loading)) {
    return;
  }

  loading.set(true);

  try {
    await window.phoneConfirmationResult.confirm(otpInput);
    isOtpSentConfirmed.set(true);
    loading.set(false);
  } catch (err) {
    let errMsg = err.message ? err.message : err.code;
    if (err.code === "auth/invalid-verification-code") {
      errMsg = "Kode verifikasi salah, masukan kode yang benar";
    }
    error.set(errMsg);
    if (
      err.code !== "auth/invalid-verification-code" &&
      err.code !== "auth/missing-verification-code"
    ) {
      window.phoneConfirmationResult = null;
    }
    if (get(loading)) {
      loading.set(false);
    }
    isOtpSentConfirmed.set(false);
  }
}
export async function changeMobileNo() {
  error.set("");
  loading.set(false);

  inputOtp.set("");
  isOtpSentConfirmed.set(false);
}
