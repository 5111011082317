import { writable } from "svelte/store";

import {
  restGetCurrentEabsen,
  restPostCreateCheckIn,
  restPostCreateCheckOut,
} from "../../api/eabsen";

import { errorNotif, successNotif } from "../../helpers/notification";

export let loading = writable(false);
export let error = writable("");
export let dataEabsen = writable(null);

export async function getCurrentEabsen() {
  error.set("");
  loading.set(true);
  dataEabsen.set(null);

  // SET DATES
  try {
    const resp = await restGetCurrentEabsen({
      personId: window.myPersonId,
    });

    if (resp && resp.data) {
      dataEabsen.set(resp.data);
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }

  loading.set(false);
}

export async function eabsenCheckIn({ latitude, longitude }) {
  error.set("");
  loading.set(true);

  // SET DATES
  try {
    const resp = await restPostCreateCheckIn({ latitude, longitude });

    if (resp && resp.data) {
      dataEabsen.set(resp.data);
      successNotif("Berhasil absensi mulai kerja");
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }

  loading.set(false);
}

export async function eabsenCheckOut({ latitude, longitude }) {
  error.set("");
  loading.set(true);

  // SET DATES
  try {
    const resp = await restPostCreateCheckOut({ latitude, longitude });

    if (resp && resp.data) {
      dataEabsen.set(resp.data);
      successNotif("Berhasil absensi selesai kerja");
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }

  loading.set(false);
}
