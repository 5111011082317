<script>
  import { Router, Route } from "svelte-routing";
  import Page404 from "../common/Page404.svelte";

  import MyActivityCreate from "./MyActivityCreate.svelte";
  import SelectSupervisor from "./SelectSupervisor.svelte";
  import SearchActivity from "./SearchActivity.svelte";

  export let location;
  export let wildcardName;
  location;
  wildcardName;

  export let date;
  export let filterName;
  export let getData;
</script>

<Router>
  <Route
    path="/select-supervisor"
    {date}
    {filterName}
    component={SelectSupervisor} />
  <Route
    path="/search-activity"
    {date}
    {filterName}
    component={SearchActivity} />
  <Route path="/" {date} {filterName} {getData} component={MyActivityCreate} />
  <Route component={Page404} />
</Router>
