<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  import {
    loading,
    listData,
    getRatingPersonListByRater
  } from "../../stores/personRating/historyNeedRatingList";

  import { HeaderNav } from "../../components/common";
  import { HistoryPersonNeedRatingItem } from "./components";

  onMount(() => {
    getRatingPersonListByRater();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  /* .container {
    margin-left: 01px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  } */
  .tabs-container {
    margin-left: -1px;
    border-radius: 2px;
    background-color: #fff;
    transition: -webkit-box-shadow 0.25s;
    transition: box-shadow 0.25s;
    transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
  .flex-row {
    display: flex;
  }
  .flex-col {
    width: 100%;
  }
  .tabs-container > .flex-row > .flex-col {
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px;
  }
  .tabs-container > .flex-row > .flex-col.active {
    background-color: #f44336;
    color: #fff;
    position: relative;
  }
  .tabs-container > .flex-row > .flex-col.active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #f44336;
    border-bottom: 0;
    margin-left: -8px;
    margin-bottom: -8px;
  }
  .tabs-container > .flex-row > .flex-col:first-child {
    border-left: none;
  }
  .tabs-container > .flex-row > .flex-col:last-child {
    border-right: none;
  }
  .tab-content {
    padding: 10px;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Penilaian Perilaku"
  backURL={`/home`}
  onRefresh={() => {
    getRatingPersonListByRater();
  }} />

<div class="root-container section no-pad-bot">
  <div class="collection p-0 m-t-0">
    <div class="tabs-container">
      <div class="flex-row m-b-0 p-0 ">
        <div class="flex-col cursor-pointer ">
          <Link to="/person-rating">
            <div class="center-align black-text">
              <i class="fa fa-star-o font-30" />
            </div>
            <div class="center-align black-text">Menunggu Penilaian</div>
          </Link>
        </div>
        <div class="flex-col cursor-pointer active">

          <div class="center-align">
            <i class="fa fa-star font-30" />
          </div>
          <div class="center-align">Sudah Dinilai</div>

        </div>
      </div>
      <div class="tab-content p-h-0 p-b-0">
        <ul class="collection p-0 m-t-0">

          {#if $listData && $listData.length > 0}
            {#each $listData as data}
              <HistoryPersonNeedRatingItem
                {data}
                detailURL={`/person-rating/history/categories/${data.id}`} />
            {/each}
          {/if}

        </ul>
      </div>
    </div>
  </div>
</div>
