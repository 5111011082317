import AWN from "awesome-notifications";
// Set global options
const globalOptions = {
  position: "top-right"
};
// Initialize instance of AWN
const notifier = new AWN(globalOptions);
//usage https://f3oall.github.io/awesome-notifications/docs/how-to-use.html
export function successNotif(msg) {
  return notifier.success(msg);
}
export function infoNotif(msg) {
  return notifier.info(msg);
}
export function warningNotif(msg) {
  return notifier.warning(msg);
}
export function errorNotif(msg) {
  return notifier.alert(msg);
}

export function alertNotif({ title, msg }) {
  const notifier = new AWN();
  notifier.confirm(msg, () => {}, false, {
    labels: {
      confirm: title,
      confirmOk: "Ya",
      confirmCancel: "Tidak"
    },
    icons: {
      enabled: false
    }
  });
}

export function confirmNotif({ title, msg, onOK, noText, yesText, yesColor }) {
  const notifier = new AWN();
  notifier.modal(`
      <div class="awn-popup-title" style="text-align: center;">${title}</div>
      <div class="awn-popup-content" style="text-align: center;">
        ${msg}
      </div>
      <div class="awn-buttons awn-buttons-2">
        <button 
          class="awn-btn awn-btn-success grey" 
          id="awn-modal-cancel"
        >
          ${noText ? noText : "Cancel"}
        </button>
        <button 
          class="awn-btn awn-btn-cancel ${yesColor ? yesColor : "blue"}" 
          id="awn-modal-ok"
        >
          ${yesText ? yesText : "Ya"}
        </button>
      </div>
  `);
  const awnElm = window.document.querySelector("#awn-popup-wrapper");
  const btnOk = window.document.querySelector("#awn-modal-ok");
  const btnCancel = window.document.querySelector("#awn-modal-cancel");
  btnOk.addEventListener("click", onClickYes);
  btnCancel.addEventListener("click", onClickNo);
  function onClickYes() {
    awnElm.classList.add("awn-hiding");
    btnOk.removeEventListener("click", onClickYes);
    onOK();
  }
  function onClickNo() {
    btnCancel.removeEventListener("click", onClickNo);
    awnElm.classList.add("awn-hiding");
  }
}

export function confirmWithComment({
  title,
  msg,
  inputFieldName,
  yesText,
  yesColor,
  noText,
  onOK
}) {
  const notifier = new AWN();
  notifier.modal(`
      <div class="awn-popup-title" style="text-align: center;">${title}</div>
      <div class="awn-popup-content" style="text-align: center;">
        ${msg}
      </div>
      <div class="awn-popup-title">
        ${inputFieldName} <br />
        <input type="text" id="awn-popup-input" value="" />
      </div>
      <div class="awn-buttons awn-buttons-2">
        <button 
          class="awn-btn awn-btn-success grey" 
          id="awn-modal-cancel"
        >
          ${noText ? noText : "Cancel"}
        </button>
        <button 
          class="awn-btn awn-btn-cancel ${yesColor ? yesColor : "blue"}" 
          id="awn-modal-ok"
        >
          ${yesText ? yesText : "Ya"}
        </button>
      </div>
  `);
  const awnElm = window.document.querySelector("#awn-popup-wrapper");
  const btnOk = window.document.querySelector("#awn-modal-ok");
  const btnCancel = window.document.querySelector("#awn-modal-cancel");
  const inputElm = window.document.querySelector("#awn-popup-input");
  btnOk.addEventListener("click", onClickYes);
  btnCancel.addEventListener("click", onClickNo);
  function onClickYes() {
    awnElm.classList.add("awn-hiding");
    btnOk.removeEventListener("click", onClickYes);
    onOK(inputElm.value);
  }
  function onClickNo() {
    btnCancel.removeEventListener("click", onClickNo);
    awnElm.classList.add("awn-hiding");
  }
}

export default notifier;
