import { writable } from "svelte/store";

import { restGetSubordinatesPersonCache } from "../../api/org";

// import { warningNotif } from "../../helpers/notification";

export let loading = writable(true);
export let error = writable("");

export let data = writable([]);

export function initialState() {
  loading.set(true);
  error.set("");
  data.set([]);
}

export async function getSubordinatesPersonCache() {
  loading.set(true);
  try {
    const resp = await restGetSubordinatesPersonCache({
      companyId: window.companyId,
      personId: window.myPersonId
    });
    if (resp && resp.data) {
      const transformData = [];
      resp.data.subordinates.forEach(e => {
        const newData = { ...e };
        newData.ownerId = e.personId;
        newData.ownerName = e.personName;
        newData.ownerNo = e.personNo;
        newData.ownerMobile = e.personMobile;
        transformData.push(newData);
      });

      data.set(transformData);
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
  }

  loading.set(false);
}
