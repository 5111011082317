<script>
  import { Img } from "../../../components/common";

  export let data = {};
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
  .black-text {
    color: rgba(0, 0, 0, 0.87) !important;
  }
</style>

<div class="person-box">
  <div>
    <Img
      personId={data.ownerId}
      alt={data.ownerName ? data.ownerName : '[Tidak ada pegawai]'}
      className="img-width circle" />
  </div>
  <div class="m-l-14">
    <span class="title red-text text-darken-4">
      <i class="fa fa-briefcase" />
      {data.jobName ? data.jobName : '[Tidak ada jabatan]'}
    </span>
    <div class="font-12 black-text">
      {#if data.mainOrgName}
        <div>
          <i class="fa fa-sitemap" />
          {data.mainOrgName}
        </div>
      {/if}
      <div class="black-text">
        <i class="fa fa-user" />
        {data.ownerName ? data.ownerName : '[Tidak ada pegawai]'}
      </div>
      <div class="black-text">
        <i class="fa fa-address-card-o" />
        {data.ownerNo ? data.ownerNo : '[Tidak ada pegawai]'}
      </div>
    </div>

  </div>
</div>
