<script>
  import { onMount } from "svelte";
  import { Router, Route } from "svelte-routing";

  import {
    getOrgCache,
    loading as loadingMyMainOrg
  } from "../stores/org/myMainOrg";
  import {
    getOrgJobPersonCache,
    loading as loadingMyJobs
  } from "../stores/org/myJobs";
  import {
    getSupervisorsPersonCache,
    loading as loadingMySupervisors
  } from "../stores/org/mySupervisors";
  import {
    getSubordinatesPersonCache,
    loading as loadingMySubordinates
  } from "../stores/org/mySubordinates";

  import { getCurrentPointPersonCache } from "../stores/personPoint/myCurrentPoint";

  import { getNewsList } from "../stores/news/newsFeed";

  import { Footer } from "../components/common";
  import Page404 from "../pages/common/Page404.svelte";

  import Home from "../pages/home/Home.svelte";

  import RouteMyActivityRoot from "../pages/myActivity/RouteMyActivityRoot.svelte";
  import RouteMySubActivity from "../pages/mySubActivity/RouteMySubActivity.svelte";
  import RoutePersonRating from "../pages/personRating/RoutePersonRating.svelte";
  import OrgStructure from "../pages/orgStructure/OrgStructure.svelte";
  import RouteMyTaskAssignment from "../pages/myTaskAssignment/RouteMyTaskAssignment.svelte";
  import RouteTaskAssignmentSub from "../pages/taskAssignmentSub/RouteTaskAssignmentSub.svelte";
  import RouterPersonMonthlyHistory from "../pages/personMonthlyHistory/RouterPersonMonthlyHistory.svelte";
  import PhoneBook from "../pages/phoneBook/PhoneBook.svelte";
  import RouteUser from "../pages/user/RouteUser.svelte";
  import RouteEabsen from "../pages/eabsen/RouteEabsen.svelte";

  onMount(() => {
    getOrgCache();
    getOrgJobPersonCache();
    getSupervisorsPersonCache();
    getSubordinatesPersonCache();
    getNewsList();

    getCurrentPointPersonCache();
  });
</script>

{#if $loadingMyMainOrg || $loadingMyJobs || $loadingMySupervisors || $loadingMySubordinates}
  <div class="loader-block" />
{:else}
  <Router>
    <Route
      path={`/my-activity/*wildcardName`}
      component={RouteMyActivityRoot} />
    <Route
      path={`/my-sub-activity/*wildcardName`}
      component={RouteMySubActivity} />
    <Route
      path={`/person-rating/*wildcardName`}
      component={RoutePersonRating} />
    <Route path={`/org`} component={OrgStructure} />
    <Route
      path={`/my-task-assignment/*wildcardName`}
      component={RouteMyTaskAssignment} />
    <Route
      <Route
      path={`/task-assignment/*wildcardName`}
      component={RouteTaskAssignmentSub} />
    <Route
      path={`/person-monthly-history/*wildcardName`}
      component={RouterPersonMonthlyHistory} />
    <Route path={`/eabsen/*wildcardName`} component={RouteEabsen} />
    <Route path={`/phone-book`} component={PhoneBook} />
    <Route path={`/user/*wildcardName`} component={RouteUser} />
    <Route path={`/home`} component={Home} />
    <Route path={`/`} component={Home} />
    <Route component={Page404} />
  </Router>
{/if}

<Footer />
