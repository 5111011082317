<script>
  import { onMount } from "svelte";

  import {
    loading,
    listData,
    getActivityPersonNeedVerifyListByPersonCache
  } from "../../stores/mySubActivity/activityList";

  import { HeaderNav } from "../../components/common";
  import { ActivityItem } from "./components";

  export let location;
  location;

  export let personId;

  function getData() {
    getActivityPersonNeedVerifyListByPersonCache({
      personId
    });
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Aktivitas Perlu Verifikasi"
  backURL={`/my-sub-activity`}
  onRefresh={() => {
    getData();
  }} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="card-panel p-0 m-t-0">
      <ul class="collection p-0 m-t-0">
        {#if !$loading && $listData && $listData.length > 0}
          {#each $listData as data}
            <ActivityItem {data} />
          {/each}
        {:else if !$loading && $listData && $listData.length < 1}
          <li class="collection-item p-h-14 p-v-14">
            <div class="font-16 center-align">
              Tidak ada aktivitas yang perlu diverifikasi
              <button
                on:click={() => {
                  getData();
                }}
                class="btn blue">
                Refresh
                <i class="fa fa-refresh" />
              </button>
            </div>
          </li>
        {/if}
      </ul>
    </div>
  </div>
</div>
