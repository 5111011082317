import { writable, get } from "svelte/store";

import { restPostTaskAssignmentComment } from "../../api/taskAssignment";

import { errorNotif, successNotif } from "../../helpers/notification";

import { getTaskAssignmentDetail } from "./taskDetail";

export let error = writable("");
export let loading = writable(false);
export let showModal = writable(false);

// Formdata
export let inputDesc = writable("");

export async function updateTask({ taskAssignmentId, oldDesc }) {
  const formData = {
    action: "supervisorEdit",
    oldDesc,
    newDesc: get(inputDesc)
  };

  error.set("");
  loading.set(true);
  try {
    await restPostTaskAssignmentComment({
      taskAssignmentId,
      formData
    });
    loading.set(false);
    inputDesc.set("");
    showModal.set(false);
    successNotif(`Berhasil rubah tugas ${formData.newDesc}`);
    getTaskAssignmentDetail({ taskAssignmentId });
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
