import { writable } from "svelte/store";
import { restGetCurrentPointPersonCache } from "../../api/point";

const initConst = {
  activityPoint: 0,
  activityPointBonus: 0,
  monthDisplay: window.moment().format("MMM YYYY")
};

export let loading = writable(false);
export let error = writable("");

export const data = writable(Object.assign({}, initConst));

export function getCurrentPointPersonCacheWithTimeout() {
  loading.set(true);
  window.setTimeout(() => {
    getCurrentPointPersonCache();
  }, 5000);
}

export async function getCurrentPointPersonCache() {
  loading.set(true);
  try {
    const resp = await restGetCurrentPointPersonCache({
      companyId: window.companyId,
      personId: window.myPersonId
    });
    if (resp && resp.data) {
      resp.data.monthDisplay = window.moment().format("MMM YYYY");
      resp.data.lastUpdateDisplay = null;
      if (resp.data.month) {
        resp.data.monthDisplay = window
          .moment(`${resp.data.month}`, "YYYYMM")
          .format("MMM YYYY");
      }
      if (resp.data.lastCalcAt) {
        resp.data.lastUpdateDisplay = window
          .moment(resp.data.lastCalcAt)
          .format("DD MMM YYYY HH:mm");
      }
      data.set(resp.data);
    }
  } catch (err) {
    console.warn(err);
    error.set(err);
    // warningNotif("Pegawai tidak tidak ditemukan");
  }

  loading.set(false);
}
