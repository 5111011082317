import fetchApi from "./fetchApi";

// GET
export function restGetTaskAssignmentListPersonBySupervisor({ supervisorId }) {
  const path = `/taskAssignment/list-person/by-supervisor/${supervisorId}`;
  return fetchApi(path, {
    method: "GET"
  });
}
export function restGetTaskAssignmentListSupervisorByPersonId({ personId }) {
  const path = `/taskAssignment/list-supervisor/by-person/${personId}`;
  return fetchApi(path, {
    method: "GET"
  });
}
export function restGetTaskAssignmentListTaskByPerson({
  personId,
  supervisorId
}) {
  const path = `/taskAssignment/list-task/by-person/${personId}/supervisor/${supervisorId}`;
  return fetchApi(path, {
    method: "GET"
  });
}
export function restGetTaskAssignmentDetail({ taskAssignmentId }) {
  const path = `/taskAssignment/detail/${taskAssignmentId}`;
  return fetchApi(path, {
    method: "GET"
  });
}

// POST
export function restPostTaskAssignmentCreate({ formData }) {
  const path = `/taskAssignment/create`;
  return fetchApi(path, {
    method: "POST",
    body: formData
  });
}

export function restPostTaskAssignmentComment({ taskAssignmentId, formData }) {
  const path = `/taskAssignment/comment/${taskAssignmentId}`;
  return fetchApi(path, {
    method: "POST",
    body: formData
  });
}

export function restPostTaskAssignmentDelete({ taskAssignmentId }) {
  const path = `/taskAssignment/delete/${taskAssignmentId}`;
  return fetchApi(path, {
    method: "POST"
  });
}
