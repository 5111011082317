<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";
  import { data as myPersonData } from "../../stores/person/myPerson";
  import { HeaderNav, TipsBox } from "../../components/common";
  import { Img } from "../../components/common";

  import {
    loading,
    listData,
    getMyEabsenHistory
  } from "../../stores/eabsen/myEabsenHistory.js";

  export let location;
  location;

  function getData() {
    getMyEabsenHistory();
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px;
  }
  .container {
    margin: 0px auto;
    width: 93% !important;
  }
  .profile-sum {
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .basic {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    font-size: 12px;
  }
  .info {
    margin-top: 20px;
    margin-left: 16px;
    font-size: 12px;
    width: 80%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
  }
  .photo-container {
    width: 20%;
    min-height: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Riwayat Absensi"
  backURL={`/eabsen`}
  onRefresh={() => {
    getData();
  }} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <div class="profile-sum">
      <div class="basic card-panel">
        <div class="photo-container">
          <Img
            className="home-avatar circle"
            personId={$myPersonData.id}
            alt={$myPersonData.name} />
        </div>
        <div class="info">
          <div class="field">
            <i class="label fa fa-user" />
            <span class="name">{$myPersonData.name}</span>
          </div>
          <div class="field">
            <i class="label fa fa-id-card" />
            <span class="no">{$myPersonData.no}</span>
          </div>
          <div class="field">
            <i class="label fa fa fa-calendar" />
            <span>
              <b>RIWAYAT ABSENSI</b>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="data">
      {#each $listData as data}
        <div class="basic card-panel">
          <div class="data-items">
            <div class="field">
              <i class="label fa fa-calendar" />
              <span class="">
                {window
                  .moment(String(data.date), 'YYYYMMDD')
                  .format('dddd, DD/MMMM/YYYY')}
              </span>
            </div>
            <div class="field">
              <i class="label fa fa-user" />
              <span class="name">{data.createdByName}</span>
            </div>
            <div class="field checkIn">
              <i class="label fa fa-clock-o" />
              <span class="">Absensi Mulai Kerja</span>
            </div>
            <div class="field checkIn-data">
              <b class="">
                {data.checkInTime ? window
                      .moment(data.checkInTime, 'HHmm')
                      .format('HH:mm') : 'BELUM'}
              </b>
              &nbsp;
              {#if data.checkInLoc}
                <a
                  target="_blank"
                  href={`https://maps.google.com?q=${data.checkInLoc.latitude},${data.checkInLoc.longitude}&z=15`}>
                  <i class="fa fa fa-map-marker" />
                  <span>LOKASI</span>
                </a>
              {/if}
            </div>
            <div class="field checkOut">
              <i class="label fa fa-clock-o" />
              <span class="">Absensi Selesai Kerja</span>
            </div>
            <div class="field checkOut-data">
              <b class="">
                {data.checkOutTime ? window
                      .moment(data.checkOutTime, 'HHmm')
                      .format('HH:mm') : 'BELUM'}
              </b>
              &nbsp;
              {#if data.checkOutLoc}
                <a
                  target="_blank"
                  href={`https://maps.google.com?q=${data.checkOutLoc.latitude},${data.checkOutLoc.longitude}&z=15`}>
                  <i class="fa fa fa-map-marker" />
                  <span>LOKASI</span>
                </a>
              {/if}
            </div>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>
