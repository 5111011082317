<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import {
    showModal,
    loading,
    error,
    inputDesc,
    updateTask
  } from "../../../stores/taskAssignmentSub/editForm";

  export let detailData = {};

  let isBackdrop = true;

  function clickClose() {
    error.set("");
    showModal.set(false);
  }
  function clickOk() {
    updateTask({
      taskAssignmentId: detailData.id,
      oldDesc: detailData.desc
    });
  }
  onMount(() => {
    const descInput = document.querySelector("#descInput");
    if (descInput) {
      descInput.focus();
    }
  });
</script>

<style>
  .btn-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  label {
    margin-left: -10px;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div
  class="modal-frame"
  on:click={e => {
    if (e.target.classList.contains('modal-frame')) {
      if (isBackdrop) {
        clickClose();
      }
    }
  }}>
  <div transition:fly={{ y: 200 }} class="modal-container">
    <div class="card">
      <form on:submit|preventDefault={clickOk}>
        <div class="card-content">
          <span class="card-title">Ubah Tugas</span>
          <div class="input-field col s12">
            <textarea
              value={$inputDesc}
              on:change={e => {
                inputDesc.set(e.target.value);
              }}
              id="descInput"
              class="materialize-textarea" />
            <label class="active" for="descInput">Keterangan</label>
          </div>
          <div class="red-text">{$error}</div>
        </div>
        <div class="card-action">
          <div class="btn-flex">
            <button
              on:click={clickClose}
              type="button"
              class="btn grey white-text">
              Cancel
              <i class="fa fa-times" />
            </button>
            <button type="submit" class="btn teal white-text">
              Simpan
              <i class="fa fa-floppy-o" />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
