<script>
  import {
    ACTIVITY_STATUS,
    JOB_ASSIGNMENT_STATUS
  } from "../../helpers/constants";
  export let type;
  export let status;
  export let className = "";
</script>

<style>
  .badge {
    min-width: 90px;
    height: 22px;
  }
</style>

{#if type === 'activity'}
  {#if status === ACTIVITY_STATUS.NEED_VERIFY}
    <span class={`badge cyan white-text ${className}`}>Belum Acc</span>
  {:else if status === ACTIVITY_STATUS.REJECT}
    <span class={`badge red white-text ${className}`}>Ditolak</span>
  {:else if status === ACTIVITY_STATUS.REJECT_BY_SYSTEM}
    <span class={`badge red white-text ${className}`}>Expired</span>
  {:else if status === ACTIVITY_STATUS.ACCEPT}
    <span class={`badge blue white-text ${className}`}>Diterima</span>
  {:else if status === ACTIVITY_STATUS.UPDATE_ACTIVITY}
    <span class={`badge teal white-text ${className}`}>Merubah</span>
  {:else}
    <span class={`badge grey white-text ${className}`}>Menunggu</span>
  {/if}
{/if}

{#if type === 'jobAssignment'}
  {#if status === JOB_ASSIGNMENT_STATUS.OPEN}
    <span class={`badge grey darken-2 white-text ${className}`}>
      Belum Mulai
    </span>
  {:else if status === JOB_ASSIGNMENT_STATUS.NEED_REVISION}
    <span class={`badge orange white-text ${className}`}>Revisi</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.ON_PROGRESS}
    <span class={`badge grey white-text ${className}`}>In Progress</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.REJECT}
    <span class={`badge red white-text ${className}`}>Ditolak</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.REJECT_BY_SYSTEM}
    <span class={`badge red white-text ${className}`}>Expired</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.NEED_VERIFY}
    <span class={`badge cyan white-text ${className}`}>Verifikasi</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.ACCEPT}
    <span class={`badge blue white-text ${className}`}>Selesai</span>
  {:else if status === JOB_ASSIGNMENT_STATUS.UPDATE_TASK}
    <span class={`badge teal white-text ${className}`}>Perubahan</span>
  {:else}
    <span class={`badge grey darken-4 white-text ${className}`}>Loading</span>
  {/if}
{/if}
