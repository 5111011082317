<script>
  import { Link } from "svelte-routing";
  import { JOB_ASSIGNMENT_STATUS } from "../../../helpers/constants";
  import { loading, counts } from "../../../stores/taskAssignmentSub/taskList";

  export let subordinateId;
  export let filterName;
</script>

<style>
  .status-box {
    display: flex;
    justify-content: space-between;
  }
</style>
{#if !$loading}
<div class="status-box">
  <div class="center-align">
    <Link to={`/task-assignment/subordinate/${subordinateId}/task/all`}>
      <div class={`${filterName === 'all' ? 'teal-text' : 'grey-text'}`}>
        <div class="font-16">
          <i class="fa fa-list" />
        </div>
        <div class="font-10">Semua</div>
      </div>
    </Link>
  </div>
  <div class="center-align">
    <Link to={`/task-assignment/subordinate/${subordinateId}/task/need-verify`}>
      <div
        class={`${filterName === 'need-verify' ? 'teal-text' : 'grey-text'}`}>
        <div class="font-16">
          {$counts[JOB_ASSIGNMENT_STATUS.NEED_VERIFY] ? $counts[JOB_ASSIGNMENT_STATUS.NEED_VERIFY] : 0}
          <i class="fa fa-question-circle" />
        </div>
        <div class="font-10">
          Menunggu
          <br />
          Verifikasi
        </div>
      </div>
    </Link>
  </div>
  <div class="center-align">
    <Link to={`/task-assignment/subordinate/${subordinateId}/task/open`}>
      <div class={` ${filterName === 'open' ? 'teal-text' : 'grey-text'}`}>
        <div class="font-16">
          {$counts && $counts[JOB_ASSIGNMENT_STATUS.OPEN] ? $counts[JOB_ASSIGNMENT_STATUS.OPEN] : 0}
          <i class="fa fa-envelope" />
        </div>
        <div class="font-10">Belum Mulai</div>
      </div>
    </Link>
  </div>
  <div class="center-align ">
    <Link to={`/task-assignment/subordinate/${subordinateId}/task/progress`}>
      <div class={`${filterName === 'progress' ? 'teal-text' : 'grey-text'}`}>
        <div class="font-16">
          {$counts[JOB_ASSIGNMENT_STATUS.ON_PROGRESS] ? $counts[JOB_ASSIGNMENT_STATUS.ON_PROGRESS] : 0}
          <i class="fa fa-hourglass-half" />
        </div>
        <div class="font-10">
          Sedang
          <br />
          Berlangsung
        </div>
      </div>
    </Link>
  </div>
  <div class="center-align ">
    <Link to={`/task-assignment/subordinate/${subordinateId}/task/revision`}>
      <div class={`${filterName === 'revision' ? 'teal-text' : 'grey-text'}`}>
        <div class="font-16">
          {$counts[JOB_ASSIGNMENT_STATUS.NEED_REVISION] ? $counts[JOB_ASSIGNMENT_STATUS.NEED_REVISION] : 0}
          <i class="fa fa-thumbs-down" />
        </div>
        <div class="font-10">
          Perlu
          <br />
          Direvisi
        </div>
      </div>
    </Link>
  </div>
</div>
{/if}