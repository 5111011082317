<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  import { data as mySupervisorsData } from "../../stores/org/mySupervisors";
  import {
    loading,
    listData,
    listNeedVerify,
    listOpen,
    listProgress,
    listRevision,
    getTaskAssignmentListTaskByPerson
  } from "../../stores/myTaskAssignment/taskList";

  import { HeaderNav } from "../../components/common";
  import { PersonBoxTop, AllJobFilter, TaskItem } from "./components";

  export let location;
  location;

  export let supervisorId;
  export let filterName;

  let selectedSupervisors = {};
  let displayData = [];

  $: {
    if (filterName === "all" || !filterName) {
      displayData = $listData;
    }
    if (filterName === "need-verify") {
      displayData = $listNeedVerify;
    }
    if (filterName === "open") {
      displayData = $listOpen;
    }
    if (filterName === "progress") {
      displayData = $listProgress;
    }
    if (filterName === "revision") {
      displayData = $listRevision;
    }
  }

  function loadSupervisorLocal() {
    if ($mySupervisorsData && $mySupervisorsData.length > 0) {
      selectedSupervisors = $mySupervisorsData.find(
        s => s.ownerId === supervisorId
      );
    }

  }
  function getData() {
    getTaskAssignmentListTaskByPerson({
      supervisorId
    });
  }
  onMount(() => {
    loadSupervisorLocal();
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Penugasan Dari Pimpinan"
  backURL={`/my-task-assignment`}
  onRefresh={() => {
    getData();
  }} />

<div class="root-container section no-pad-bot">
  <div class="container">
    {#if selectedSupervisors && selectedSupervisors.ownerId}
      <PersonBoxTop data={selectedSupervisors}>
        <AllJobFilter {filterName} {supervisorId} />
      </PersonBoxTop>
    {/if}
    <ul class="collection p-0">
      {#each displayData as task}
        <TaskItem {task} {filterName} {supervisorId} />
      {/each}
      {#if !$loading && displayData.length < 1}
        <li class="collection-item p-v-14 p-h-14">
          <div class="font-16 center-align">Tidak ada tugas</div>
        </li>
      {/if}
    </ul>
  </div>
</div>
