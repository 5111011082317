<script>
  import { fly } from "svelte/transition";
  import { formatNumber } from "../../../helpers/number.js";
  import {
    data,
    showModalActivity
  } from "../../../stores/personMonthlyHistory/detail";
  export let isBackdrop = true;
</script>

<style>
  .modal-container {
    margin-left: 1%;
    margin-right: 1%;
  }
  .btn-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .point-list {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #e0e0e0;
    border-radius: 2px;
    margin-left: -24px;
    margin-right: -24px;
  }
  .point-item {
    display: block;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 14px;
  }

  @media only screen and (min-width: 360px) {
    .point-item {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      border-bottom: 1px solid #e0e0e0;
      padding: 14px;
    }
  }
  .point-item:last-child {
    border-bottom: none;
  }
</style>

{#if $showModalActivity && $data && $data.point}

  <div
    class="modal-frame"
    on:click={e => {
      if (e.target.classList.contains('modal-frame')) {
        if (isBackdrop) {
          showModalActivity.set(false);
        }
      }
    }}>
    <div transition:fly={{ y: 200 }} class="modal-container">
      <div class="card">

        <div class="card-content">
          <div class="card-title">
            Detail Poin Aktivitas
            <b>{$data.pointPercentage}%</b>
          </div>
          <div class="point-list m-t-16 m-b-100">
            <div class="point-item">
              <div>Poin Langsung</div>

              <div>{formatNumber($data.point.direct)}</div>
            </div>
            <div class="point-item">
              <div>Poin Bawahan</div>

              <div>{formatNumber($data.point.downline)}</div>
            </div>
            <div class="point-item">
              <div>
                <b>Poin TOTAL</b>
              </div>

              <div>
                <b>{formatNumber($data.point.total)}</b>
              </div>
            </div>
            <div class="point-item">
              <div>
                <b>Target POIN</b>
              </div>

              <div>
                <b>{formatNumber($data.point.target)}</b>
              </div>
            </div>

          </div>
        </div>
        <div class="card-action">
          <div class="btn-flex">
            <div />
            <button
              on:click={() => {
                showModalActivity.set(false);
              }}
              type="button"
              class="btn grey white-text">
              Close
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
{/if}
