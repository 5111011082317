<script>
  import { Router, Route } from "svelte-routing";
  import Page404 from "../common/Page404.svelte";

  import Home from "./Home.svelte";
  import History from "./History.svelte";

  export let location;
  export let wildcardName;

  location;
  wildcardName;
</script>

<Router>
  <Route path="/" component={Home} />
  <Route path="/history" component={History} />

  <Route component={Page404} />
</Router>
