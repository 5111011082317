<script>
  import { onMount } from "svelte";
  import {
    loading,
    listData,
    getTaskAssignmentListSupervisorByPersonId
  } from "../../stores/myTaskAssignment/supervisorList";

  import { HeaderNav } from "../../components/common";
  import { SupervisorItem } from "./components";

  function getData() {
    getTaskAssignmentListSupervisorByPersonId();
  }

  onMount(() => {
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Penugasan Dari Pimpinan"
  backURL={`/home`}
  onRefresh={() => {
    getData();
  }} />

<div class="root-container section no-pad-bot">
  <div class="container">
    <ul class="collection p-0 m-t-0">
      {#if $listData && $listData.length > 0}
        {#each $listData as data}
          {#if data.supervisorPersonId !== window.myPersonId}
            <SupervisorItem {data} />
          {/if}
        {/each}
      {:else if !$loading && $listData && $listData.length < 1}
        <li class="collection-item p-h-14 p-v-14">
          <div class="font-16 center-align">Tidak ada penugasan</div>
        </li>
      {/if}
    </ul>
  </div>
</div>
