<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  import { data as mySubordinates } from "../../stores/org/mySubordinates";
  import {
    loading,
    listData,
    listNeedVerify,
    listOpen,
    listProgress,
    listRevision,
    getTaskAssignmentListTaskByPerson
  } from "../../stores/taskAssignmentSub/taskList";

  import { HeaderNav } from "../../components/common";
  import { PersonBoxTop, AllJobFilter, TaskItem } from "./components";

  export let location;
  location;

  export let subordinateId;
  export let filterName;

  let selectedSubordinate = {};
  let displayData = [];

  $: {
    if (filterName === "all" || !filterName) {
      displayData = $listData;
    }
    if (filterName === "need-verify") {
      displayData = $listNeedVerify;
    }
    if (filterName === "open") {
      displayData = $listOpen;
    }
    if (filterName === "progress") {
      displayData = $listProgress;
    }
    if (filterName === "revision") {
      displayData = $listRevision;
    }
  }

  function loadSubordinateLocal() {
    if ($mySubordinates && $mySubordinates.length > 0) {
      selectedSubordinate = $mySubordinates.find(
        s => s.ownerId === subordinateId
      );
    }

  }
  function getData() {
    getTaskAssignmentListTaskByPerson({
      subordinateId
    });
  }
  onMount(() => {
    loadSubordinateLocal();
    getData();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<HeaderNav
  headerSize="s"
  headerName="Penugasan Ke Bawahan"
  backURL={`/task-assignment`}
  onRefresh={() => {
    getData();
  }} />

<div class="root-container section no-pad-bot">
  <div class="container">
    {#if selectedSubordinate && selectedSubordinate.ownerId}
      <PersonBoxTop data={selectedSubordinate}>
        <AllJobFilter {filterName} {subordinateId} />
      </PersonBoxTop>
    {/if}
    <ul class="collection p-0">
      {#each displayData as task}
        <TaskItem {task} {filterName} {subordinateId} />
      {/each}
      {#if !$loading && displayData.length < 1}
        <li class="collection-item p-v-14 p-h-14">
          <div class="font-16 center-align">Tidak ada tugas</div>
        </li>
      {/if}
    </ul>
  </div>
</div>

<div class="fixed-action-btn">
  <Link
    to={`/task-assignment/subordinate/${subordinateId}/task/${filterName}/create`}
    getProps={() => ({ class: 'btn-floating btn-large red' })}>
    <i class="large material-icons">add</i>
  </Link>
</div>