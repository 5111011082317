<script>
  import { onDestroy, onMount } from "svelte";
  import { Link, navigate } from "svelte-routing";
  import {
    inputMobile,
    inputOtp,
    verifyOtp,
    otpCounter,
    error,
    requestOtp,
    changeMobileNo
  } from "../../stores/auth/otpForm.js";

  onMount(() => {
    inputOtp.set("");
  });

  onDestroy(() => {
    error.set("");
  });
</script>

<style>
  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    line-height: 36px;
  }
  .login-box {
    width: 300px;
  }
  button {
    display: block;
    width: 100%;
  }
</style>

<div class="center-container">
  <h3 class="center-align">
    <span class="font-30">VERIFIKASI KODE SMS</span>
  </h3>

  <div class="login-box">
    <div class="row">
      <form on:submit|preventDefault={verifyOtp} class="col s12">
        <div>
          Kode verifikasi SMS akan dikirim ke nomor {$inputMobile} dalam waktu 5
          menit
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input
              autocomplete="off"
              id="inputOtp"
              type="tel"
              class="validate"
              bind:value={$inputOtp} />
            <label class="active" for="inputOtp">
              Masukkan 6 angka kode sms
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div>
              <div class="red-text m-b-4">{$error}</div>
              <button type="submit" class="btn-small red darken-4 btn-block">
                Verifikasi Kode
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="m-t-16">
              {#if $otpCounter > 0}
                <span class="helper-text">
                  Kirim ulang kode sms dalam
                  <span class="red-text">{$otpCounter}</span>
                </span>
              {:else}
                <div>
                  <div>
                    <smal>Tidak menerima sms?</smal>
                  </div>
                  <div
                    on:click={requestOtp}
                    class="red-text text-darken-4 btn-action-text">
                    Kirim Ulang kode sms ke {$inputMobile}
                  </div>
                </div>
                <div class="m-t-16">
                  <div>
                    <smal>Salah no hp?</smal>
                  </div>
                  <div
                    on:click={changeMobileNo}
                    class="red-text text-darken-4 btn-action-text">
                    Ganti No HP
                  </div>
                </div>
              {/if}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m-t-20">
            <Link
              to={`/login`}
              getProps={() => ({ class: 'right red-text text-darken-4' })}>
              Kembali ke Login
            </Link>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
