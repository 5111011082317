<script>
  import { Link, navigate } from "svelte-routing";
  import {
    serverTime,
    maxPrevDate,
    datesNav,
  } from "../../../stores/myActivity/myActivityDates.js";


  function isSunday(date) {
    return window.moment(date).weekday() === 6;
  }

  function isHolidayClass(isHoliday) {
    if (!isHoliday) {
      return "";
    }
    return "red white-text";
  }

  function onChangeDate(e) {
    const nextDateString = moment(e.target.value, "YYYY-MM-DD").format(
      "YYYYMMDD"
    );
    
    navigate(`/my-activity/by-date/${nextDateString}/filter/all`);
  }

  function getLinkNextPrev(date, disable) {
    let nextDate = window.moment(date).format("YYYYMMDD");
    if (disable) {
      nextDate = window.moment($datesNav.current.date).format("YYYYMMDD");
    }

    return `/my-activity/by-date/${nextDate}/filter/all`;
  }
</script>

<style>
  :global(a.nav-dates) {
    color: inherit;
    width: 100%;
    display: block;
  }
  .calendar-select {
    display: inline-block;
    position: relative;
  }
  input {
    position: absolute;
    opacity: 0;
  }
  input::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  input:hover + button {
    color: inherit !important;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
  }
  .calendar-btn {
    color: inherit !important;
    background-color: transparent !important;
    border: none !important;
  }

  .dates-box {
    display: flex;
    justify-content: space-between;
  }
  .date-item {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .z-depth-1 {
    border: 2px solid #009688;
  }
</style>

<div class="card-panel dates-box p-0 m-t-0 m-b-2">
  <Link
    to={getLinkNextPrev($datesNav.prev.date, $datesNav.prev.disable)}
    getProps={() => {
      return { class: 'nav-dates' };
    }}>
    <div
      class={`date-item ${isHolidayClass(isSunday($datesNav.prev.date))} ${$datesNav.prev.disable ? 'grey cursor-block' : ''}`}>
      {window.moment($datesNav.prev.date).format('dddd')}
      {#if isSunday($datesNav.prev.date)}
        <i class="fa fa-info-circle" title="Hari minggu" />
      {/if}
      <br />
      {window.moment($datesNav.prev.date).format('DD MMMM YY')}
    </div>
  </Link>
  <div
    class={`date-item z-depth-1 ${isHolidayClass(isSunday($datesNav.current.date))}  ${$datesNav.current.disable ? 'grey' : ''}`}>
    {window.moment($datesNav.current.date).format('dddd')}
    {#if isSunday($datesNav.current.date)}
      <i class="fa fa-info-circle" title="Hari minggu" />
    {/if}
    <div class="calendar-select">
      <input
        id="datesBarInput"
        type="date"
        on:change={onChangeDate}
        min={window.moment($maxPrevDate, 'YYYYMMDD').format('YYYY-MM-DD')}
        max={window.moment($serverTime).format('YYYY-MM-DD')}
        value={window.moment($datesNav.current.date).format('YYYY-MM-DD')} />
      <button class="calendar-btn">
        <i class="fa fa-calendar" />
      </button>
    </div>
    <br />
    {window.moment($datesNav.current.date).format('DD MMMM YY')}
  </div>
  <Link
    to={getLinkNextPrev($datesNav.next.date, $datesNav.next.disable)}
    getProps={() => {
      return { class: 'nav-dates' };
    }}>
    <div
      class={`date-item  ${isHolidayClass(isSunday($datesNav.next.date))} ${$datesNav.next.disable ? 'grey cursor-block' : ''}`}>
      {window.moment($datesNav.next.date).format('dddd')}
      {#if isSunday($datesNav.next.date)}
        <i class="fa fa-info-circle" title="Hari minggu" />
      {/if}
      <br />
      {window.moment($datesNav.next.date).format('DD MMMM YY')}
    </div>
  </Link>
</div>
