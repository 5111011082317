import { writable } from "svelte/store";

import { restGetTaskAssignmentListTaskByPerson } from "../../api/taskAssignment";

import { errorNotif } from "../../helpers/notification";
import { JOB_ASSIGNMENT_STATUS } from "../../helpers/constants";

export let loading = writable(false);
export let error = writable("");

export let listData = writable([]);

export let counts = writable({});
export let listNeedVerify = writable([]);
export let listOpen = writable([]);
export let listProgress = writable([]);
export let listRevision = writable([]);

export async function getTaskAssignmentListTaskByPerson({ supervisorId }) {
  error.set("");
  loading.set(true);
  listData.set([]);
  try {
    const resp = await restGetTaskAssignmentListTaskByPerson({
      personId: window.myPersonId,
      supervisorId
    });
    transformAndSetData(resp);
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}

function transformAndSetData(resp) {
  counts.set(resp.counts || 0);
  listData.set(resp.data || []);
  listNeedVerify.set(
    resp.data.filter(d => d.status === JOB_ASSIGNMENT_STATUS.NEED_VERIFY) || []
  );
  listOpen.set(
    resp.data.filter(d => d.status === JOB_ASSIGNMENT_STATUS.OPEN) || []
  );
  listProgress.set(
    resp.data.filter(d => d.status === JOB_ASSIGNMENT_STATUS.ON_PROGRESS) || []
  );
  listRevision.set(
    resp.data.filter(d => d.status === JOB_ASSIGNMENT_STATUS.NEED_REVISION) ||
      []
  );
  loading.set(false);
}
