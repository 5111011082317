<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import {
    showModal,
    loading,
    error,
    inputReason,
    acceptTask
  } from "../../../stores/taskAssignmentSub/acceptForm";
  export let detailData = {};
  export let filterName;

  let isBackdrop = true;

  function clickClose() {
    error.set("");
    showModal.set(false);
  }
  function clickOk() {
    acceptTask({
      taskAssignmentId: detailData.id,
      detailData,
      filterName
    });
  }
  onMount(() => {
    const descInput = document.querySelector("#descInput");
    if (descInput) {
      descInput.focus();
    }
  });
</script>

<style>
  .btn-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div
  class="modal-frame"
  on:click={e => {
    if (e.target.classList.contains('modal-frame')) {
      if (isBackdrop) {
        clickClose();
      }
    }
  }}>
  <div transition:fly={{ y: 200 }} class="modal-container">
    <div class="card">
      <form on:submit|preventDefault={clickOk}>
        <div class="card-content">
          <span class="card-title">Terima</span>
          <p>Yakin terima laporan tugas?</p>
          <div class="red-text">{$error}</div>
        </div>
        <div class="card-action">
          <div class="btn-flex">
            <button
              on:click={clickClose}
              type="button"
              class="btn grey white-text">
              Cancel
              <i class="fa fa-times" />
            </button>
            <button type="submit" class="btn blue white-text">
              Terima
              <i class="fa fa-floppy-o" />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
