<script>
  import { Img } from "../../../components/common";

  export let data = {};
</script>

<style>
  .person-box {
    display: flex;
    align-content: flex-start;
  }
</style>

<div class="p-v-4 p-h-14 p-v-14">
  <div class="person-box">
    <div>
      <Img
        className="circle img-width"
        personId={data.ownerId}
        alt={data.ownerName} />
    </div>
    <div class="full-width m-l-14">
      <div class="m-t-2 ">
        <i class="fa fa-user" />
        {data.ownerName}
      </div>
      <div class="m-t-2 ">
        <i class="fa fa-address-card-o" />
        {data.ownerNo}
      </div>
    </div>
  </div>
  <slot />
</div>
