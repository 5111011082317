<script>
  import { onMount } from "svelte";

  import {
    error,
    loading,
    inputComment,
    postTaskAssignmentComment
  } from "../../../stores/taskAssignmentSub/commentForm";

  export let taskId;
  export let detailData;

  onMount(() => {
    const commentInput = document.querySelector("#comment");
    if (commentInput) {
      commentInput.focus();
    }
  });
</script>

<style>
  .card-panel {
    min-height: 103px;
  }
  .action-box {
    display: flex;
    align-content: flex-start;
  }
  .red-text {
    margin-top: -20px;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}

<div class="card-panel p-0 p-h-14">
  <form
    class="action-box"
    on:submit|preventDefault={() => {
      if (detailData.closed) {
        return;
      }
      postTaskAssignmentComment({ taskAssignmentId: taskId });
    }}>
    <div class="input-field full-width">
      <input
        value={$inputComment}
        on:change={e => {
          inputComment.set(e.target.value);
        }}
        id="comment"
        min="0"
        max="100"
        class="materialize-textarea" />
      <label class="active" for="comment">Komentar</label>
    </div>
    <div class="m-l-14">
      <button type="submit" class="btn teal white-text full-width m-t-30">
        <i class="fa fa-commenting" />
      </button>
    </div>
  </form>
  <div class="red-text">{$error}</div>
</div>
