import { writable, get } from "svelte/store";

import { restPostTaskAssignmentComment } from "../../api/taskAssignment";

import { errorNotif } from "../../helpers/notification";

import { getTaskAssignmentDetail } from "./taskDetail";

export let error = writable("");
export let loading = writable(false);

// Formdata
export let inputComment = writable("");

export async function postTaskAssignmentComment({ taskAssignmentId }) {
  const formData = {
    action: "subordinateComment",
    comment: get(inputComment)
  };
  error.set("");
  loading.set(true);
  try {
    await restPostTaskAssignmentComment({
      taskAssignmentId,
      formData
    });
    loading.set(false);
    inputComment.set("");
    getTaskAssignmentDetail({ taskAssignmentId });
  } catch (err) {
    loading.set(false);
    console.warn(err);
    error.set(err);
    errorNotif(err);
  }
}
