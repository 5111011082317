<script>
  import { onMount } from "svelte";

  import { data as mySubordinates } from "../../stores/org/mySubordinates";
  import {
    error,
    loading,
    postTaskAssignmentCreate,
    inputDeadline,
    inputDesc
  } from "../../stores/taskAssignmentSub/createForm";

  import { HeaderNav, Footer } from "../../components/common";
  import { PersonBoxTop } from "./components";

  export let location;
  location;

  export let subordinateId;
  export let filterName;

  let selectedSubordinate = {};

  const currentDate = window
    .moment()
    .add(1, "day")
    .format("YYYYMMDD");

  function onChangeDate(e) {
    inputDeadline.set(
      window.moment(e.target.value, "YYYY-MM-DD").format("YYYYMMDD")
    );
  }

  function loadSubordinateLocal() {
    if ($mySubordinates && $mySubordinates.length > 0) {
      selectedSubordinate = $mySubordinates.find(
        s => s.ownerId === subordinateId
      );
    }
  }
  onMount(() => {
    loadSubordinateLocal();
  });
</script>

<style>
  .root-container {
    padding-top: 0px !important;
  }
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 99.8% !important;
  }
  .calendar-select {
    display: inline-block;
    position: relative;
  }
  #datesBarInput {
    position: absolute;
    opacity: 0;
  }
  #datesBarInput::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  #datesBarInput:hover + button {
    color: inherit !important;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
  }
  .calendar-btn {
    color: inherit !important;
    background-color: transparent !important;
    border: none !important;
  }
</style>

{#if $loading}
  <div class="loader-block" />
{/if}
<HeaderNav
  headerSize="s"
  headerName="Penugasan Baru"
  backURL={`/task-assignment/subordinate/${subordinateId}/task/${filterName}`} />

<div class="root-container section no-pad-bot">
  <div class="container">
    {#if selectedSubordinate && selectedSubordinate.ownerId}
      <PersonBoxTop data={selectedSubordinate} />
    {/if}
    <div class="card-panel p-h-14 p-v-10">
      <form
        on:submit|preventDefault={() => {
          postTaskAssignmentCreate({
            supervisorId: window.myPersonId,
            subordinateId
          });
        }}>
        <div class="row m-b-0">
          <div class="input-field col s12">
            <div class="calendar-select p-t-8">
              <input
                id="datesBarInput"
                type="date"
                on:change={onChangeDate}
                min={window
                  .moment(currentDate, 'YYYYMMDD')
                  .format('YYYY-MM-DD')}
                max={window
                  .moment(currentDate, 'YYYYMMDD')
                  .add(1, 'years')
                  .format('YYYY-MM-DD')}
                value={window
                  .moment($inputDeadline, 'YYYYMMDD')
                  .format('YYYY-MM-DD')} />
              <button type="button" class="calendar-btn">
                <i class="fa fa-calendar" />
              </button>
              {window.moment($inputDeadline, 'YYYYMMDD').format('D MMMM YY')}
            </div>
            <label class="active" for="deadline">Tenggat Waktu</label>
          </div>
        </div>
        <div class="row m-b-0">
          <div class="input-field col s12">
            <textarea
              value={$inputDesc}
              on:change={e => {
                inputDesc.set(e.target.value);
              }}
              id="desc"
              class="materialize-textarea" />
            <label class="active" for="desc">Keterangan</label>
          </div>
        </div>
        <div class="row m-b-0">
          <div class="col s12">
            <div class="red-text m-b-4">{$error}</div>
            <button type="submit" class="btn full-width m-b-20">
              Beri Tugas
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
