<script>
  import { fly } from "svelte/transition";
  import { data as myJobsData } from "../../../stores/org/myJobs";

  export let onClose;
  export let isBackdrop = true;
  function clickClose() {
    onClose();
  }
</script>

<style>
  .btn-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .badge {
    float: left;
    margin-left: 0px;
  }
</style>

<div
  class="modal-frame"
  on:click={e => {
    if (e.target.classList.contains('modal-frame')) {
      if (isBackdrop) {
        onClose();
      }
    }
  }}>
  <div transition:fly={{ y: 200 }} class="modal-container">
    <div class="card">
      <div>
        <div class="card-content">
          <span class="card-title">Daftar Jabatan</span>
          <ul>
            {#if $myJobsData && $myJobsData.length}
              {#each $myJobsData as myJob}
                <li class="m-b-14">

                  <div class="font-14">
                    <i class="label fa fa-briefcase" />
                    {myJob.jobName ? myJob.jobName : ''}
                  </div>

                  <div class="font-12 grey-text">
                    <i class="label fa fa-building" />
                    {myJob.mainOrgName ? myJob.mainOrgName : ''}
                  </div>
                  {#if myJob.personJobSecondary}
                    <div>
                      <span class="badge red white-text">
                        jabatan sementara
                      </span>
                    </div>
                  {/if}
                </li>
              {/each}
            {:else}
              <li class="m-b-14">
                <div class="font-14">Tidak ada Jabatan</div>
              </li>
            {/if}
          </ul>
        </div>
        <div class="card-action">
          <div class="btn-flex">
            <span />
            <button
              on:click={clickClose}
              type="button"
              class="btn grey white-text">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
