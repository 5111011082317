<script>
  import { Router, Route } from "svelte-routing";
  import {
    loading,
    listData,
    getActivityPersonByDateListCache
  } from "../../stores/myActivity/myActivityList";

  import { HeaderNav } from "../../components/common";

  import Page404 from "../common/Page404.svelte";

  import MyActivityList from "./MyActivityList.svelte";
  import MyActivityDetail from "./MyActivityDetail.svelte";
  import RouteMyActivityCreate from "./RouteMyActivityCreate.svelte";

  export let location;
  export let wildcardName;

  location;
  wildcardName;

  export let date;
  export let filterName;

  let lastDate;
  let lastFilterName;
  let filteredListData = [];
  let filterCode = 20;

  $: {
    if (lastDate !== date) {
      lastDate = date;
      lastFilterName = filterName;
      getData();
    }
  }
  $: {
    if (lastFilterName !== filterName && $listData && $listData.length > 0) {
      lastFilterName = filterName;
      setFilteredData();
    }
  }

  function getData() {
    filteredListData = [];
    getActivityPersonByDateListCache({
      dateString: date,
      next: setFilteredData
    });
  }

  function setFilteredData() {
    filteredListData = [];
    //
    if (lastFilterName !== "all") {
      if (lastFilterName === "need-verify") {
        filterCode = 20;
      }
      if (lastFilterName === "accept") {
        filterCode = 50;
      }
      if (lastFilterName === "reject") {
        filterCode = 40;
      }
      filteredListData = $listData.filter(o => {
        return o.status === filterCode;
      });
    } else {
      filteredListData = $listData;
    }
  }
</script>

{#if $loading}
  <div class="loader-block" />
  <!-- TEMP HEADER FOR DISPLAY ONLY -->
  <HeaderNav
    headerSize="s"
    headerName="My Aktivitas"
    backURL={`/home`}
    onRefresh={() => {
      getData();
    }} />
{:else}
  <Router>
    <Route
      path="/detail/:activityId"
      component={MyActivityDetail}
      {date}
      {filterName} />
    <Route
      path="/create/*wildcardName"
      component={RouteMyActivityCreate}
      {date}
      {filterName}
      {getData} />
    <Route
      path="/"
      component={MyActivityList}
      {date}
      {filterName}
      {getData}
      {filteredListData} />
    <Route component={Page404} />
  </Router>
{/if}
